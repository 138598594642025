import Box from "@mui/material/Box";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useMemo, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { filter, find, isEmpty } from "lodash";
import FileComponent, { IFile } from "../applications/AddApi/FileComponent";
import { fetchStatus, removeFiles, fetchFiles, readFile, uploadFiles, convertFiles } from '../../utils/conversions';
import { axiosInstance } from "../../services/axios";
import useFetch from "../../hooks/http/useFetch";
// import { fi } from "date-fns/locale";

interface IDefinitionForm extends RouteComponentProps {
  apiId?: string;
  callback?: (data: any) => void;
}

interface IDefinitionFormValues {
  type: "url" | "file" | "postman" | "har";
  data: string | IFile[];
}

const types = [
  { id: "url", name: "OpenAPI Definition Public URL", apiTypes: ["API_KIND_OPENAPI"] },
  { id: "file", name: "OpenAPI Definition File Upload" , apiTypes: ["API_KIND_OPENAPI"]},
  { id: "url", name: "GraphQL Definition Public URL", apiTypes: ["API_KIND_GRAPHQL"] },
  { id: "file", name: "GraphQL Definition File Upload" , apiTypes: ["API_KIND_GRAPHQL"]},
  { id: "postman", name: "Postman Collection File Upload", apiTypes: ["API_KIND_OPENAPI"] },
  { id: "har", name: "HTTP Archive (HAR) File Upload", apiTypes: ["API_KIND_OPENAPI"] },
];

const getTypes = (apiType: "API_KIND_OPENAPI" | "API_KIND_GRAPHQL") => {
  console.log({types :filter(types , o => o?.apiTypes?.indexOf(apiType)>-1)})
  return  filter(types , o => o?.apiTypes?.indexOf(apiType)>-1)
}

const RELOAD_INTERVAL = 5000;

const DefinitionForm = ({ callback, apiId }: IDefinitionForm) => {
  const [errors, setErrors] = useState<any>(null);
  const [formValues, setFormValues] = useState<IDefinitionFormValues>({
    type: "url",
    data: "",
  });
  const { data: apiData } = useFetch(`/apis/${apiId}`);
  const [savingState, setSavingState] = useState<
    "NOT_SAVING" | "SAVING" | "GENERATING_MAP" | "CONVERTING" | "TRAVERSING_APIS"
  >("NOT_SAVING");

  const typeOptions  = useMemo(()=> getTypes(apiData?.type) || [], [apiData])

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const doFetchStatus = async (type: string, checkFiles = false) => {
    try {
      const {status} = await fetchStatus(type);
      if (status) {
          if (status === 'pending') {
            if (checkFiles) {
              const filesResponse = await fetchFiles(type);
              if (filesResponse.length) {
                return;
            }
            }
          await timeout(RELOAD_INTERVAL);
          await doFetchStatus(type);
          } 
          if ((status === 'converted') || (status === 'ok')) {
            return;
          }
          if (status === 'errored') {
            setSavingState("NOT_SAVING");
          }
      }
    } catch (error: any) {
        console.log(error);
    }
  }

  const doConvertFiles = async (type: string) => {
    try {
        await convertFiles(type);
    } catch (error: any) {
        console.log(error);
    }
  }

  const convertFile = async (type: string, event: any) => {
    setSavingState("SAVING");   
    try {
        await uploadFiles(type, event);
        await timeout(RELOAD_INTERVAL);
        await doFetchStatus(type, true);
        setSavingState("CONVERTING");  
        await doConvertFiles(type);
        await timeout(RELOAD_INTERVAL);
        await doFetchStatus(type);
        const converted = await readFile(type);
        //@ts-ignore
        return converted;
    } catch (error) {
        console.log(error);
        setSavingState("NOT_SAVING"); 
    }
  }

  const handleSubmit = async (event: any = null) => {
    try {
      let postData: any = {};

      switch (formValues.type) {
        case "url":
          postData["definitionUrl"] = formValues.data;
        break;
        case "file":
          //@ts-ignore
          postData["definition"] = formValues.data?.[0]?.uri.split(",")[1];
        break;
        case "postman":
          const postmanDefinition = await convertFile('postman', event)
          postData["definition"] = postmanDefinition;
          removeFiles('postman', false); // no need to await
        break;
        case "har":
          const harDefinition = await convertFile('har', event)
          postData["definition"] = harDefinition;
          removeFiles('har', false);
        break;
        default:
        break;
      }

      setSavingState("SAVING");
      const response = await axiosInstance.post(
        `/apis/${apiId}/revisions/`,
        postData
      );
      setTimeout(() => {
        setSavingState("GENERATING_MAP");
        setTimeout(() => {
          setSavingState("TRAVERSING_APIS");
          setTimeout(() => {
            setSavingState("NOT_SAVING");
            if (callback) {
              callback(response?.data);
            }
          }, 4000);
        }, 3000);
      }, 1000);
    } catch (error: any) {
      setErrors([error?.response?.data?.message]);
      setSavingState("NOT_SAVING");
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid item xs={12} sx={{ pb: 1 }}>
        <Typography
          color="textPrimary"
          sx={{ mb: 1, textAlign: "left" }}
          variant="h6"
        >
          Type
        </Typography>
        <Autocomplete
          getOptionLabel={(option): any => 
            //@ts-ignore
            option?.name}
          options={typeOptions}
          value={find(typeOptions, { id: formValues.type })}
          sx={{ maxWidth: "50%", textAlign: "left" }}
          disableClearable
          size="medium"
          onChange={(e, value) => {
            setFormValues({
              ...formValues,
              //@ts-ignore
              type: value?.id as "url" | "file",
              //@ts-ignore
              data: value?.id === "url" ? "" : [],
            });
          }}
          renderInput={(params): JSX.Element => {
            return (
              <TextField
                autoComplete="off"
                name="type"
                variant="outlined"
                {...params}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ pb: 1 }}>
        <Typography
          color="textPrimary"
          sx={{ mb: 1, textAlign: "left" }}
          variant="h6"
        >
          Definition
        </Typography>
        {formValues.type === "url" && (
          <TextField
            autoComplete="off"
            sx={{ maxWidth: "50%", textAlign: "left" }}
            size="medium"
            fullWidth
            placeholder="https://api.com/url"
            value={formValues.data}
            onChange={({ target }: any) => {
              setFormValues({ ...formValues, data: target.value });
            }}
            required
            variant="outlined"
          />
        )}
        {formValues.type === "file" && (
          <FileComponent
            files={(formValues.data as IFile[]) || []}
            onChangeHandler={(files: any) =>
              setFormValues({ ...formValues, data: files })
            }
          />
        )}
        {["postman", "har"].includes(formValues.type) && (
          <Box sx={{ textAlign: "left", mb: 1 }}>
            <Button variant="contained"
              disabled={
                savingState !== "NOT_SAVING"
              }
              onClick={() => {
                //@ts-ignore
                document.getElementById("conversionFileUploadField").click();
            }}>
                Upload File
                <input
                  id="conversionFileUploadField"
                  style={{ display: "none" }}
                  type="file"
                  multiple={false}
                  onChange={(e) => handleSubmit(e)}
                />
            </Button>
        </Box>
        )}
      </Grid>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={handleSubmit}
        disabled={
          savingState !== "NOT_SAVING" ||
          (formValues.type === "url" &&
            (formValues.data as string)?.trim() === "") ||
          (["file", "postman", "har"].includes(formValues.type) && isEmpty(formValues.data))
        }
      >
        {savingState === "NOT_SAVING" && "Save"}
        {savingState === "SAVING" && "Saving..."}
        {savingState === "GENERATING_MAP" && "Generating Map..."}
        {savingState === "TRAVERSING_APIS" && "Traversing APIs..."}
        {savingState === "CONVERTING" && "Converting..."}
      </Button>
      {!isEmpty(errors) && (
        <Box sx={{ mt: 2 }}>
          {errors.map((error: any, idx: number) => (
            <Alert severity="error" key={idx} sx={{ width: "fit-content" }}>
              <div>{error}</div>
            </Alert>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withRouter(DefinitionForm);
