import { Close } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import { find, isEmpty, orderBy } from "lodash";
import { FC, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { axiosInstance } from "../../../services/axios";
import { DataCard } from "../../dashboard";
import OperationModal from "../OperationsModal";
import ResultData from "./ResultData";

import useFetchOperations from "../../../hooks/process/useFetchOperations";
import { IncidentData } from "../../analysis/ViewFindings/incidentComponents/IncidentView";
import ModalWrapper from "../../common/modal/wrapper";
import TableModal from "../../common/table/modal";
import ResultNewView from "./ResultNewView";
var format = require("string-template");

const OperationColumn: FC<any> = ({ operations, operationId }) => {
  const [operationData, setOperationData] = useState(null as any);

  useEffect(() => {
    try {
      setOperationData({
        ...find(operations, { operationID: operationId }),
        operationID: operationId,
      });
    } catch (error) {
      setOperationData({ operationID: operationId });
    }
  }, [operations, operationId]);

  if (!operationData) {
    return <></>;
  }
  return (
    <div>
      <Typography
        variant="overline"
        sx={{ fontSize: "12px" }}
        color="text.secondary"
      >
        {operationData?.["resource"]}
      </Typography>
      <Typography variant="h6">{operationData?.["operationID"]}</Typography>
      <Typography
        variant="overline"
        sx={{ fontSize: "12px" }}
        color="text.secondary"
      >
        {operationData?.["method"]} &nbsp;
      </Typography>
      <Typography variant="caption" sx={{ fontSize: "12px" }}>
        {operationData?.["path"]}
      </Typography>
    </div>
  );
};

const ResultView: FC<any> = ({ history, resultId, match, location, api }) => {
  const [selectedFindingId, setSelectedFindingId] = useState(
    null as string | null
  );
  const [result, setResult] = useState({} as any);
  const [incidentId, setIncidentId] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [isFetchingFault, setIsFetchingFault] = useState(false);
  const [selectedFaultGroup, setSelectedFaultGroup] = useState("");
  const [failedOperationsTableData, setFailedOperationsTableData] =
    useState<any>({});
  const [errorsTableData, setErrorsTableData] = useState<any>({});
  const [operationsTableData, setOperationsTableData] = useState<any>({});
  const [openOperationModal, setOpenOperationModal] = useState(false);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [revId, setRevId] = useState(null);
  const { operations } = useFetchOperations("api", api?.id);

  useEffect(() => {
    if (!isEmpty(result) && !isEmpty(operations)) {
      let newFaultAnalyzerColumns: any[] = [
        {
          name: "Operations",
          key: "operationID",
          width: "100px",
          type: "custom",
          code: (row: any) => (
            <OperationColumn
              operations={operations}
              operationId={row["operationID"]}
            />
          ),
        },
      ];
      if (!isEmpty(result?.faultAnalyzers)) {
        for (let analyzer of result?.faultAnalyzers) {
          try {
            newFaultAnalyzerColumns.push({
              name: analyzer?.displayName,
              key: analyzer?.name,
              type: "custom",
              code: (row: any) => {
                try {
                  return (
                    <Chip
                      size="small"
                      sx={{ fontSize: "12px" }}
                      label={row[analyzer?.name]}
                      style={{
                        backgroundColor:
                          row[analyzer?.name].toLowerCase() === "pass"
                            ? "#10B981"
                            : row[analyzer?.name].toLowerCase() === "none"
                              ? "#e46b48"
                              : row[analyzer?.name].toLowerCase() === "skip"
                                ? "#40ce9f"
                                : "#D14343 ",
                        color: "#fff",
                      }}
                    />
                  );
                } catch (error) { }
                return null;
              },
            });
          } catch (error) { }
        }
      }
      let newFaultAnalyzerData = [];
      let newFaultAnalyzerStatusData = [];
      if (
        !isEmpty(result?.metrics) &&
        !isEmpty(result?.metrics?.perOperation)
      ) {
        for (let operation of result?.metrics?.perOperation) {
          let object: any = { operationID: operation?.operationId };
          newFaultAnalyzerStatusData.push({
            ...object,
            failedRequests: operation.requestsFailed,
            successfulRequests: operation.requestsSuccessful,
            status: operation.requestsSuccessful > 0 ? "PASS" : "FAIL",
          });
          for (let analyzer of operation?.perAnalyzer) {
            object[analyzer?.analyzerName] = analyzer?.decision;
          }
          newFaultAnalyzerData.push(object);
        }
      }
      setOperationsTableData({
        data: orderBy(
          newFaultAnalyzerData.map((o) => ({
            ...o,
            operationId: o.operationID,
            resource: find(operations, { operationID: o.operationID })
              ?.resource,
          })),
          "resource",
          "asc"
        ),
        columns: newFaultAnalyzerColumns,
      });
    }
  }, [result, operations]);

  const fetchFinding = async (id: string) => {
    try {
      setSelectedFindingId(id);
      let response = await axiosInstance.get(
        format(
          //@ts-ignore
          `/findings/${id}`
        )
      );

      // setSteps(response?.data?.sequence);
    } catch (error: any) { }
  };

  const fetchFault = async (id: string) => {
    setIsFetchingFault(true);
    try {
      let response = await axiosInstance.get(
        format(
          //@ts-ignore
          `/faults/${id}`
        )
      );
    } catch (error: any) { }
    setIsFetchingFault(false);
  };

  const handleOpenOperationModal = (
    apiId: any,
    revId: any,
    operationId: any
  ) => {
    // setApiId(apiId);
    setRevId(revId);
    setSelectedOperationId(operationId);
    setOpenOperationModal(true);
  };

  const handleCloseOperationModal = () => {
    setSelectedOperationId(null);
    setOpenOperationModal(false);
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid xs={12}>
          <Card
            elevation={0}
            sx={{
              pl: 2,
              textAlign: "left",
            }}
          >
            <Modal
              open={openOperationModal}
              onClose={handleCloseOperationModal}
            >
              <OperationModal
                onClose={handleCloseOperationModal}
                selectedOperation={{
                  apiId: api.id!,
                  revId: revId!,
                  defaultOperationId: selectedOperationId,
                }}
              />
            </Modal>

            <ResultData
              id={resultId}
              api={api}
              history={history}
              onFaultsGroupChange={(value: string) =>
                setSelectedFaultGroup(value)
              }
              Summary={() => (
                <ModalWrapper
                  maxWidth="xl"
                  title="Summary"
                  trigger={
                    <Button variant="contained" size="small" sx={{ mr: 1 }}>
                      Summary
                    </Button>
                  }
                  child={
                    <TableModal
                      columns={operationsTableData?.columns}
                      rows={operationsTableData?.data}
                    />
                  }
                />
              )}
            />



            {!isEmpty(errorsTableData?.data) && (
              <Box sx={{ m: 2 }}>
                <Accordion elevation={12}>
                  <AccordionSummary>
                    <Typography variant="h6">Errors</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DataCard
                      name={""}
                      config={{
                        widget: "table",
                        enableContextFilter: true,
                        width: 8,
                        hideSearch: true,
                        skipJsonPath: true,
                        columns: errorsTableData?.columns,
                        mock_data: errorsTableData?.data,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            <ResultNewView api={api} operations={operations} />
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={selectedFindingId != null}
        onClose={() => setSelectedFindingId(null)}
      >
        <Box
          sx={{
            minHeight: "100%",
            overflow: "auto",
            p: 3,
          }}
        >
          <Container maxWidth="lg">
            <Paper elevation={12} sx={{ p: 3 }}>
              <Close
                className="hand"
                onClick={() => setSelectedFindingId(null)}
              />

              <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                <IncidentData
                  id={incidentId}
                  history={history}
                  findingId={selectedFindingId}
                />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default withRouter(ResultView);
