import { Alert, Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { getConfigs } from "../../configs";
import { API_SLUGS, ERRORS, ROUTES, STRINGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import { IUserStoreState, useUserStore } from "../../store/userState";
import { validateEmail } from "../../utils/validateEmail";
import VmWorkspaceOneLoginHandler from "./vmwareWorkspaceOne";
const format = require("string-template");
interface ILoginFormValues {
  email?: string;
  password?: string;
}
interface IError {
  error: boolean;
  message: string;
}
interface ILoginFormError {
  email?: IError;
  password?: IError;
}

const LoginForm: React.FC<any> = ({ location, history, onMfaEnabled }) => {
  const [formValues, setFormValues] = useState({} as ILoginFormValues);
  const search = useLocation().search;
  const error = new URLSearchParams(search).get('error');
  const [formErrorValues, setFormErrorValues] = useState({
    email: {},
    password: {},
  } as ILoginFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const { setCurrentUser, isLoggedIn } = useUserStore(
    (state) => state
  ) as IUserStoreState;
  const [loginMethod, setLoginMethod] = useState<any>(null);

  useEffect(() => {
    if (error) {
      setErrorMessage(error)
    }
    if (isLoggedIn) {
      const redirectTo = localStorage.getItem(
        STRINGS.LOCAL_STORAGE_REDIRECT_TO
      );
      if (redirectTo && redirectTo.trim() !== "") {
        localStorage.removeItem(STRINGS.LOCAL_STORAGE_REDIRECT_TO);
        history.push(redirectTo);
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [isLoggedIn, history]);

  const setError = (values: ILoginFormError) => {
    setFormErrorValues({ ...formErrorValues, ...values });
  };

  const verifyPassword = (values: ILoginFormValues) => {
    if ("password" in values) {
      if (values.password!.length < 8) {
        setError({
          password: { error: true, message: ERRORS.PASSWORD_LENGTH_SHORT },
        });
        return false;
      }
    } else {
      setError({ password: { error: true, message: ERRORS.PASSWORD_EMPTY } });
      return false;
    }
    setError({ password: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const verifyEmail = (values: ILoginFormValues) => {
    if ("email" in values) {
      if (!validateEmail(values.email!)) {
        setError({ email: { error: true, message: ERRORS.INVALID_EMAIL } });
        return false;
      }
    } else {
      setError({ email: { error: true, message: ERRORS.EMAIL_EMPTY } });
      return false;
    }

    setError({ email: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const handleChange = (value: any, type: "email" | "password") => {
    let newValues = { ...formValues, [type]: value };
    switch (type) {
      case "email":
        verifyEmail(newValues);
        break;
      case "password":
        verifyPassword(newValues);
        break;
      default:
        break;
    }
    setFormValues(newValues);
  };

  const handleSubmit = async () => {
    if (verifyEmail(formValues) && verifyPassword(formValues)) {
      setIsSubmitting(true);
      try {
        let userResponse = await axiosInstance.post(
          API_SLUGS.LOGIN,
          formValues
        );
        if (userResponse.data?.token) {
          onMfaEnabled(userResponse.data?.token);
        } else {
          setCurrentUser(userResponse.data?.data);
        }
      } catch (error: any) {
        try {
          setErrorMessage(error.response?.data?.message);
        } catch (error: any) { }
      }
      setIsSubmitting(false);
    }
  };

  const handleIDPSubmit = async () => {
    setIsSubmitting(true);
    try {
      window.open(`${getConfigs().baseApiUrl}${API_SLUGS.IDP_LOGIN}`, "_self");
    } catch (error: any) {
      setErrorMessage(error);
    }
    setIsSubmitting(false);
  };

  const handleGithubSignin = async () => {
    setIsSubmitting(true);
    try {
      window.open(
        `${getConfigs().baseApiUrl}${API_SLUGS.SIGNIN_VMONE}`,
        "_self"
      );
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
    }
    setIsSubmitting(false);
  };

  const handleNext = async () => {
    setIsSubmitting(true);
    try {
      const methodResponse = await axiosInstance.get(API_SLUGS.LOGIN_METHOD, {params: { email: formValues.email }});
      setLoginMethod(methodResponse.data);
      setErrorMessage(STRINGS.EMPTY);
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
    }
    setIsSubmitting(false);
  }

  const handleVMOneSignin = async (loginUrl: string) => {
    setIsSubmitting(true);
    try {
      window.open(
        `${getConfigs().baseApiUrl}${loginUrl}`,
        "_self"
      );
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
    }
    setIsSubmitting(false);
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <TextField
        autoFocus
        fullWidth
        label={STRINGS.EMAIL_ADDRESS}
        margin="normal"
        name="email"
        error={formErrorValues.email?.error}
        helperText={formErrorValues.email?.message}
        type="email"
        disabled={loginMethod}
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "email");
        }}
        inputProps={{ "data-testid": "email" }}
      />
      {loginMethod?.loginType === "basic" && <TextField
        fullWidth
        label={STRINGS.PASSWORD}
        margin="normal"
        name="password"
        error={formErrorValues.password?.error}
        helperText={formErrorValues.password?.message}
        type="password"
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "password");
        }}
        inputProps={{ "data-testid": "password" }}
      />}

      {successMessage && successMessage?.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage?.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>

        {
          loginMethod === null &&
          <Button
            color="primary"
            fullWidth

            size="large"
            disabled={isSubmitting || !formValues?.email || formErrorValues.email?.error}
            variant="contained"
            data-testid="login-button"
            onClick={handleNext}
          >
            Next
          </Button>

        }

        {
          loginMethod?.loginType === "basic" &&
          <Button
            color="primary"
            fullWidth
            type="submit"
            size="large"
            disabled={isSubmitting}
            variant="contained"
            data-testid="login-button"
          >
            {STRINGS.LOGIN}
          </Button>


        }

        {/* <GithubLogin githubHandler={handleGithubSignin}/>
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    // disabled={isSubmitting}
                    variant="contained"
                    onClick={handleIDPSubmit}
                    data-testid="login-sso-button"
                    sx={{ mt: 3 }}
                >
                    {STRINGS.IDP_LOGIN}
                </Button> */}
        {loginMethod?.loginType === "sso" &&
          <VmWorkspaceOneLoginHandler loginHandler={()=>handleVMOneSignin(loginMethod?.loginUrl)} />
        }
      </Box>
    </form>
  );
};

export default withRouter(LoginForm);
