import {
  Box, Button, Card,
  CardContent, Chip, Container, Grid, Typography
} from "@mui/material";
import { find, findIndex, isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import Chart from "react-google-charts";
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../hooks/http/useFetch";
import { ChevronDown } from "../../icons/chevron-down";
import { ChevronUp } from "../../icons/chevron-up";
import { axiosInstance } from "../../services/axios";
import { fetchAllQueryStrings } from "../../utils/fetchAllQueryStrings";
import { fetchQueryString } from "../../utils/fetchQueryString";
import IncidentsTable from "../analysis/ViewIncidents/IncidentsTable";
import ResultsTable from "../analysis/ViewResults/ResultsTable";

import CustomTable, { ComponentFilter } from "../common/table";
import Loaders from "./Loaders";
import CategoryBar from "./widgetLibrary/categoryBar";
import SeverityTimeSeries from "./widgetLibrary/severityTimeSeries";
var jp = require("jsonpath");
var format = require("string-template");

const template = [
  // {
  //   generateWidget: () => <ApplicationEnvironmentInsights />,
  // },

  {
    generateWidget: () => <Grid item container md={12} xs={12} sx={{ p: 4 }}>
      <Grid item xs={12} >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h4"
          sx={{ pt: 3, pb: 2 }}
        >
          Overview
        </Typography>
      </Grid>

      <Grid item container xs={12} sx={{ border: "2px solid #5148e626", backgroundColor: "#5048e508", p: 3, borderRadius: "0.35em" }}>

        <CategoryBar height="250px" />
        <SeverityTimeSeries height={"270px"} />
      </Grid>


    </Grid>
  },


  {
    generateWidget: () => <Grid item md={12} xs={12} sx={{ p: 4 }}><Box sx={{ pb: 2 }}>
      <Typography variant="h6">Latest Issues <Chip
        label="View All"
        sx={{ ml: 2 }}
        variant="outlined"
        size="small"
        onClick={() => window.open("/incidents", "_blank")}

      /></Typography>
    </Box><IncidentsTable limit={10} disableInfiniteScroll disableFilters />
    </Grid>,
  },
  {
    generateWidget: () => <Grid item md={12} xs={12} sx={{ p: 4 }}><Box sx={{ pb: 2 }}>
      <Typography variant="h6">My Issues <Chip
        label="View All"
        sx={{ ml: 2 }}
        variant="outlined"
        size="small"
        onClick={() => window.open("/incidents", "_blank")}

      /></Typography>
    </Box><IncidentsTable limit={10} disableInfiniteScroll disableFilters currentUserOnly /></Grid>,
  },
  {
    generateWidget: () => <Grid item md={12} xs={12} sx={{ p: 4 }}> <Box sx={{ pb: 2 }}>
      <Typography variant="h6">Latest Results <Chip
        label="View All"
        sx={{ ml: 2 }}
        variant="outlined"
        size="small"
        onClick={() => window.open("/runs", "_blank")}

      /></Typography>
    </Box><ResultsTable limit={10} disableInfiniteScroll disableFilters />
    </Grid>,
  },
];

const Dashboard: FC<RouteComponentProps> = ({ history }) => {
  const { data: result } = useFetch("/results?limit=1")

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: '100%',
          textAlign: "left",

          maxWidth: "100%"
        }}
      >
        <Container sx={{ maxWidth: "100vw" }} >

          <Grid container rowSpacing={5}>

            {result?.results?.length === 0 && <Grid item xs={12}>
              <Card>
                <CardContent sx={{ p: 4 }}>
                  {/** If no runs then show onboarding message to start quick start */}
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    Haven't initiated a run yet?
                  </Typography>
                  <Typography color="text.secondary" variant="h5" sx={{ fontWeight: 500 }}>
                    Follow the Quick Start Guide to get started
                  </Typography>
                  <Button variant="contained" color="primary" size="large" sx={{ mt: 4 }} onClick={() => history.push("/quick-start")}>
                    Quick Start
                  </Button>
                </CardContent>
              </Card>
            </Grid>}

            {template.map((widget) => {
              return widget.generateWidget();
            })}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

const getTableData = async (
  config: any,
  totalRowsFetched = 0,
  onTableDataFetch = () => { }
) => {
  try {
    if (!isEmpty(config?.mock_data) && config?.skipJsonPath) {
      return config?.mock_data;
    }
    let response = !isEmpty(config?.mock_data)
      ? { data: config?.mock_data }
      : await axiosInstance.get(
        format(config?.data_source, {
          offset: totalRowsFetched,
          limit: config?.pagination_limit,
        })
      );
    let columnsData: any = {};
    let columnsMap: any = {};

    let totalRows = 0;
    for (let column of config?.columns) {
      try {
        columnsData[column?.key] = jp.query(
          response?.data,
          config?.data_points[
            findIndex(config?.data_points, { key: column?.key })
          ].jsonpath
        );
        columnsMap[column?.key] = column;
        totalRows = Math.max(columnsData[column?.key].length, totalRows);
      } catch (error) { }
    }

    let tableData = [];
    for (let i = 0; i < totalRows; i++) {
      let row: any = {};
      for (let key in columnsData) {
        try {
          // if (columnsMap[key]?.["type"] === "tag") {
          //   row[key] = [{ label: columnsData[key]?.[i], color: columnsMap[key]?.['colorOptions'] ? columnsMap[key]?.['colorOptions']?.[columnsData[key]?.[i]] : "black" }]
          // } else {
          row[key] = columnsData[key]?.[i];
          // }
        } catch (error) { }
      }
      tableData.push(row);
    }

    return tableData;
  } catch (error: any) {
    return [];
  }
};

const getChartData = async (config: any) => {
  try {
    let response = !isEmpty(config?.mock_data)
      ? { data: config?.mock_data }
      : await axiosInstance.get(config?.data_source);
    let dimensionsData: any = {};
    let totalRows = 0;
    for (let dimension of config?.dimensions) {
      try {
        dimensionsData[dimension.key] = jp.query(
          response?.data,
          config?.data_points[
            findIndex(config?.data_points, { key: dimension.key })
          ].jsonpath
        );

        totalRows = Math.max(dimensionsData[dimension.key].length, totalRows);
      } catch (error) { }
    }

    let chartData = [];
    let titleRow: any = [];
    for (let dimension of config?.dimensions) {
      titleRow.push(dimension.name);
    }
    chartData.push(titleRow);

    for (let i = 0; i < totalRows; i++) {
      let row: any = [];
      for (let dimension of config?.dimensions) {
        try {
          row?.push(dimensionsData[dimension.key]?.[i]);
        } catch (error) { }
      }
      chartData.push(row);
    }

    if (!isEmpty(config?.dataSequence)) {
      let dataSequenceMap: any = {};
      for (let item of config?.dataSequence?.order) {
        dataSequenceMap[item] = [];
      }

      for (let i = 1; i < chartData.length; i++) {
        dataSequenceMap[
          chartData[i]?.[config?.dataSequence?.dimensionIndex]
        ].push(chartData[i]);
      }

      let sequencedChartData: any = [];
      for (let item of config?.dataSequence?.order) {
        sequencedChartData = [...sequencedChartData, ...dataSequenceMap[item]];
      }
      chartData = [titleRow, ...sequencedChartData];
    }

    return chartData;
  } catch (error: any) {
    return [];
  }
};

const getAlertData = async (config: any) => {
  try {
    let response = !isEmpty(config?.mock_data)
      ? { data: config?.mock_data }
      : await axiosInstance.get(config?.data_source);
    let configsData: any = {};
    let totalRows = 0;
    for (let configObject of config?.configs) {
      try {
        configsData[configObject?.key] = jp.query(
          response?.data,
          config?.data_points[
            findIndex(config?.data_points, { key: configObject?.key })
          ].jsonpath
        );
        totalRows = Math.max(configsData[configObject?.key].length, totalRows);
      } catch (error) { }
    }

    let alertData = [];

    for (let i = 0; i < totalRows; i++) {
      let row: any = [];
      for (let configObject of config?.configs) {
        try {
          row?.push(configsData[configObject?.key]?.[i]);
        } catch (error) { }
      }
      alertData.push(row);
    }
    return alertData;
  } catch (error: any) {
    return [];
  }
};

interface IDataCard {
  name?: string;
  config: any;
  raised?: boolean;
}

export const DataCard: FC<IDataCard> = ({ name, config, raised = false }) => {
  const [data, setData] = useState(null as any);
  const [filteredData, setFilteredData] = useState([]);
  const [shouldShowMoreOption, setShouldShowMoreOption] = useState(true);
  const [isCollpased, setIsCollpased] = useState(false);
  const [searchFilters, setSearchFilters] = useState([
    { key: "Search String", type: "text", width: 12 },
  ] as any[]);
  const [columnHeaderFilter, setColumnHeaderFilter] = useState(
    {} as { [key: string]: any[] }
  );
  const [selectedSearchFilters, setSelectedSearchFilters] = useState({} as any);

  useEffect(() => {
    if (config?.widget === "table") {
      let urlQueries = fetchAllQueryStrings();

      for (let key in urlQueries) {
        urlQueries[key] = [urlQueries[key]];
        if (
          !find(config?.columns, (column) => {
            if (key === column?.key && column?.type !== "hidden") {
              return column;
            }
          })
        ) {
          delete urlQueries[key];
        }
      }

      setColumnHeaderFilter({ ...urlQueries });
    }
  }, []);

  useEffect(() => {
    /**
     * If the widget is table and local filter (column filter or search filter) got
     * changed and let's say the locally present records didn't have enough data then fetch more data
     */

    if (
      config?.widget === "table" &&
      shouldShowMoreOption &&
      !isEmpty(data) &&
      filteredData &&
      config?.pagination &&
      filteredData.length < config?.pagination_limit
    ) {
      getData();
    }
  }, [filteredData, shouldShowMoreOption]);

  useEffect(() => {
    if (!isEmpty(data)) {
      let newData: any = [];
      let filter = selectedSearchFilters;
      if (
        filter &&
        filter["Search String"] &&
        filter["Search String"].trim() != ""
      ) {
        for (let row of data) {
          for (let key in row) {
            try {
              if (
                row[key]
                  .toLowerCase()
                  .split(filter["Search String"].toLowerCase()).length >= 2
              ) {
                newData.push(row);
                break;
              }
            } catch (e) { }
          }
        }
      } else {
        newData = [...data];
      }

      if (config?.widget === "table" && config?.selectedRowId) {
        for (let i = 0; i < newData.length; i++) {
          newData[i].isSelected = newData[i].id === config?.selectedRowId;
        }
      }

      if (config?.widget === "table" && !isEmpty(columnHeaderFilter)) {
        let columnHeaderFilteredData = [];
        for (let row of newData) {
          let shouldPush = true;
          for (let filterKey in columnHeaderFilter) {
            if (
              findIndex(
                columnHeaderFilter[filterKey],
                (filter) => filter === row[filterKey]
              ) < 0
            ) {
              shouldPush = false;
              break;
            }
          }
          if (shouldPush) {
            columnHeaderFilteredData.push(row);
          }
        }

        newData = columnHeaderFilteredData;
      }

      setFilteredData(newData);
    }
  }, [data, config, selectedSearchFilters, columnHeaderFilter]);

  const getData = async (refetch = false) => {
    let tempConfig = { ...config };
    let currentData = refetch ? [] : [...data];
    let newData: any = !isEmpty(currentData) ? currentData : [];
    if (tempConfig?.enableContextFilter && tempConfig?.data_source) {
      tempConfig.data_source = formatUrlForContextFilter(
        tempConfig?.data_source
      );
    }

    switch (tempConfig?.widget) {
      case "table":
        let fetchedData = await getTableData(
          tempConfig,
          !isEmpty(currentData) ? currentData.length : 0
        );
        newData = [...newData, ...fetchedData];
        if (
          tempConfig?.pagination &&
          tempConfig?.pagination_limit > fetchedData.length
        ) {
          setShouldShowMoreOption(false);
        }

        break;
      case "chart":
        newData = await getChartData(tempConfig);

        break;
      case "alert":
        newData = await getAlertData(tempConfig);

        break;
      case "custom":
        newData = await getChartData(tempConfig);

        break;

      default:
        setData(tempConfig?.text);
        break;
    }
    if (config?.dataFormatter) {
      newData = config?.dataFormatter(newData);
    }
    setData(newData);
  };

  useEffect(() => {
    getData(true);
  }, [config?.mock_data, config?.data_source]);

  const handleColumnHeaderFilterChange = (key: string, value: any[] | null) => {
    if (!isEmpty(value)) {
      setColumnHeaderFilter({ ...columnHeaderFilter, [key]: value! });
    } else {
      let newFilter = { ...columnHeaderFilter };
      delete newFilter[key];
      setColumnHeaderFilter({ ...newFilter });
    }
  };

  return (
    <Card raised={raised} elevation={raised ? 12 : 0}>
      <CardContent
        sx={{
          p: 0,
          alignItems: "left",
          display: "flex",
        }}
      >
        <Box
          sx={{
            pl: raised ? 4 : 1,
            pr: raised ? 4 : 1,
            pt: 2,
            textAlign: "left",
            width: "100%",
            display: config?.render ? "flex" : "block",
            justifyContent: config?.render ? "space-between" : "start",
          }}
        >
          {config?.name ? (
            <Box sx={{ display: "block" }}>
              {config?.subHeading ? (
                <Box>
                  <Typography variant="overline" color="text.secondary">
                    {config?.subHeading}
                  </Typography>
                </Box>
              ) : null}
              <Typography
                // color="CaptionText"
                sx={{ display: "block" }}
                variant="h6"
              >
                {config?.name}
                <Box sx={{ display: "inline" }}>
                  {!config?.isCollapsible ? null : isCollpased ? (
                    <ChevronDown
                      sx={{ position: "relative", top: 6 }}
                      className="hand"
                      onClick={() => setIsCollpased(false)}
                    />
                  ) : (
                    <ChevronUp
                      sx={{ position: "relative", top: 6 }}
                      className="hand"
                      onClick={() => setIsCollpased(true)}
                    />
                  )}
                </Box>
              </Typography>
            </Box>
          ) : null}
          {config?.description ? (
            <Box sx={{ mb: 2 }}>
              <Typography color="GrayText" variant="caption">
                {config?.description}
              </Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              display: isCollpased
                ? "none"
                : config?.render
                  ? "inline"
                  : "block",
            }}
          >
            {data === null ? (
              <Loaders />
            ) : config?.widget === "custom" && config?.render ? (
              config?.render(data)
            ) : config?.widget === "chart" && data?.length === 1 ? (
              <Typography variant="overline" color="neutral.400">
                No Data Available
              </Typography>
            ) : config?.widget === "table" ? (
              [
                !isEmpty(searchFilters) &&
                  !("hideSearch" in config && config["hideSearch"]) ? (
                  <ComponentFilter
                    filters={searchFilters}
                    onChange={(values: any) => {
                      setSelectedSearchFilters(values);
                    }}
                  />
                ) : null,
                <Box
                  id="scrollableDiv"
                  sx={{ maxHeight: config.maxHeight || "30em", overflow: "auto" }}
                >
                  <InfiniteScroll
                    dataLength={!isEmpty(data) ? data?.length : 0}
                    next={config?.pagination ? getData : () => { }}
                    hasMore={shouldShowMoreOption}
                    loader={config?.pagination ? <h4>Loading...</h4> : null}
                    scrollableTarget="scrollableDiv"
                  >
                    <CustomTable
                      data={filteredData}
                      columns={config?.columns}
                      hideFilter
                      columnHeaderFilter={columnHeaderFilter}
                      handleColumnHeaderFilterChange={
                        handleColumnHeaderFilterChange
                      }
                    />
                  </InfiniteScroll>
                </Box>,
              ]
            ) : config?.widget === "chart" && config?.chart_type === "line" ? (
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={config?.options}
              />
            ) : config?.widget === "chart" && config?.chart_type === "bar" ? (
              <Chart
                chartType="BarChart"
                width="100%"
                height="400px"
                chartEvents={
                  config?.getChartEvents ? config?.getChartEvents(data) : []
                }
                data={data}
                options={{
                  ...config?.options,
                  hAxis: {
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                    ...config?.options.hAxis,
                  },
                  vAxis: {
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                    ...config?.options.vAxis,
                  },
                  backgroundColor: "transparent",
                  legend: {
                    position: "none",
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                  chartArea: {
                    top: 10,
                    right: 20,
                    width: "60%",
                    height: "85%",
                    ...config?.options.chartArea,
                  },
                  colors: ["#6E7AD8"],
                }}
              />
            ) : config?.widget === "chart" &&
              config?.chart_type === "stacked-bar" ? (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={data}
                chartEvents={
                  config?.getChartEvents ? config?.getChartEvents(data) : []
                }
                options={{
                  backgroundColor: "transparent",
                  legend: {
                    position: "bottom",
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                  chartArea: {
                    left: 20,
                    top: 10,
                    width: "100%",
                    height: "85%",
                  },
                  colors: ["#3C4693", "#4655CE", "#6E7AD8", "#9DA4DD"],
                  ...config?.options,
                  hAxis: {
                    ...config?.options.hAxis,
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                  vAxis: {
                    ...config?.options.vAxis,
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                }}
              />
            ) : config?.widget === "chart" &&
              config?.chart_type === "histogram" ? (
              <Chart
                chartType="Histogram"
                width="100%"
                height="400px"
                data={data}
                options={{
                  ...config?.options,
                  legend: {
                    ...config?.options.legend,
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                  backgroundColor: "transparent",
                }}
              />
            ) : config?.widget === "chart" && config?.chart_type === "pie" ? (
              <Chart
                chartType="PieChart"
                data={data}
                options={{
                  ...config?.options,
                  legend: {
                    ...config?.options.legend,
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                  backgroundColor: "transparent",
                }}
                width={"100%"}
                height={"400px"}
              />
            ) : config?.widget === "chart" &&
              config?.chart_type === "pie-donut" ? (
              <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data}
                chartEvents={
                  config?.getChartEvents ? config?.getChartEvents(data) : []
                }
                options={{
                  colors: ["#3C4693", "#64B6F7", "#6E7AD8", "#9DA4DD"],
                  backgroundColor: "transparent",
                  ...config?.options,
                  legend: {
                    ...config?.options.legend,
                    textStyle: {
                      color:
                        localStorage.getItem("selectedTheme") === "dark"
                          ? "#FFF"
                          : "inherit",
                    },
                  },
                }}
              />
            ) : config?.widget === "alert" ? (
              <Box sx={{}}>
                <Typography color="primary" variant="h3">
                  {data &&
                    //@ts-ignore
                    data?.length
                    ? data[0]?.[0]
                    : "N/A"}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ minHeight: "3em" }}>
                <Typography color="primary" variant="h3">
                  {config?.text}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const formatUrlForContextFilter = (url: string, groupId?: string) => {
  let dataSource = url;
  let applicationId =
    fetchQueryString("applicationId") || localStorage.getItem("applicationId");
  let apiId = fetchQueryString("apiId") || localStorage.getItem("apiId");
  let environmentId =
    fetchQueryString("environmentId") || localStorage.getItem("environmentId");
  let filterQuery = url.split("?").length > 1 ? "" : "?";
  if (applicationId) {
    filterQuery +=
      (filterQuery[filterQuery.length - 1] === "?" ? "" : "&") +
      `applicationId=${applicationId}`;
  }

  if (apiId) {
    filterQuery +=
      (filterQuery[filterQuery.length - 1] === "?" ? "" : "&") +
      `apiId=${apiId}`;
  }

  if (environmentId) {
    filterQuery +=
      (filterQuery[filterQuery.length - 1] === "?" ? "" : "&") +
      `environmentId=${environmentId}`;
  }

  if (groupId) {
    filterQuery +=
      (filterQuery[filterQuery.length - 1] === "?" ? "" : "&") +
      `groupId=${groupId}`;
  }

  if (filterQuery !== "?" && filterQuery !== "&") {
    dataSource = dataSource + filterQuery;
  }
  return dataSource;
};

export default withRouter(Dashboard);
