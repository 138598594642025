import { Box, Button, Card, Tooltip } from "@mui/material";
import { FC, useMemo } from "react";
import { withRouter } from "react-router-dom";

import { BorderColorOutlined, ContentCopy, DeleteOutline } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import MaterialReactTable from "material-react-table";
import { API_SLUGS, FACTS } from "../../constants";
import useFetch from "../../hooks/http/useFetch";
import { usePageContext } from "../../hooks/process/usePageContext";
import { Plus } from "../../icons/plus";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import ModalWrapper from "../common/modal/wrapper";
import AutomationsForm from "./AutomationsForm";

const format = require("string-template")

const AutomationsTable: FC<any> = ({
  history,
  match,


}) => {

  const { selectedGroup, selectedProject } = usePageContext();

  const { setMessage, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;
  const {
    data: rulesData,
    loading,
    fetchData,
  } = useFetch(`/rules`);

  const {
    data: applicationsData,
  } = useFetch(selectedGroup ? format(API_SLUGS.APPLICATIONS_BY_GROUP, {
    groupId: selectedGroup?.id

  }) : "");

  const applicationFacts = useMemo(() => {
    if (!applicationsData) {
      return []
    }


    let applicationOptions = []
    for (let application of applicationsData?.applications) {
      applicationOptions.push({
        key: application?.id,
        label: application?.name
      })
    }

    if (applicationOptions?.length
    ) {
      return [{
        key: "applicationId",
        label: "Project",
        type: "select",
        options: applicationOptions
      }]
    } else return []

  }, [applicationsData])


  const {
    data: environmentData,
  } = useFetch(selectedProject ? `/environments?applicationId=${selectedProject.id}` : "");

  const environmentFacts = useMemo(() => {
    if (!environmentData) {
      return []
    }


    let environmentOptions = []
    for (let environment of environmentData?.environments) {
      environmentOptions.push({
        key: environment?.id,
        label: environment?.name
      })
    }

    if (environmentOptions?.length
    ) {
      return [{
        key: "environmentId",
        label: "Environment",
        type: "select",
        options: environmentOptions
      }]
    } else return []

  }, [environmentData])

  const deleteRule = async (id: string) => {
    try {
      await axiosInstance.delete(`/rules/${id}`);
      setMessage({
        title: "Automation deleted successfully",
        type: "success",
      });
      fetchData();
    } catch (error) {
      setMessage({
        title: "Error deleting rule",

        type: "error",
      });
    }
  };

  const rules = useMemo(
    () => rulesData?.rules || [],
    [rulesData]
  );
  return (
    <Card elevation={0} sx={{ textAlign: "left" }}>

      <ModalWrapper
        maxWidth="md"
        title="Add Automation"
        trigger={
          <Button color="primary" size="small" variant="contained" sx={{ my: 2 }}>
            Add <Plus />
          </Button>
        }
        child={
          <AutomationsForm
            preventRedirect
            facts={[...FACTS]}
            commonFacts={[...applicationFacts, ...environmentFacts]}
            callback={() => {

              fetchData()
            }}
          />
        }
      />

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[

          {
            accessorKey: "name",
            header: "Name",

            size: 130,
          },
          {
            accessorKey: "id",
            header: "ID",

            size: 130,
            Cell: (params: any) => (
              <pre style={{ display: "inline" }} onClick={() => {
                copy(params?.row?.original?.id);
                clearMessage();
                setMessage({
                  title: "ID has been copied to clipboard",
                  type: "success",
                });
              }}>
                <Tooltip title="Click to copy">

                  <Box>
                    {params?.row?.original?.id}
                    <ContentCopy fontSize="inherit" sx={{ ml: 2 }} /></Box>
                </Tooltip>
              </pre>
            ),
          },

          {
            accessorKey: "actions",

            header: "Actions",
            Cell: (params: any) => (
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <ModalWrapper
                  maxWidth="md"
                  title={`Edit ${params?.row?.original?.name}`}
                  trigger={
                    <Button>
                      <BorderColorOutlined />
                    </Button>
                  }
                  child={
                    <AutomationsForm
                      updateOnly
                      facts={[...FACTS,]}
                      commonFacts={[...applicationFacts, ...environmentFacts]}

                      callback={() => fetchData()}
                      preventRedirect
                      updateUUID={params?.row?.original?.id}
                      defaultFormValues={params?.row?.original}
                    />
                  }
                />
                <DeleteOutline
                  color="error"
                  className="hand"
                  sx={{ mt: 1 }}
                  onClick={() => deleteRule(params?.row?.original?.id)}
                />

              </Box>
            ),
          },
        ]}
        data={rules}
        initialState={{
          density: "compact",
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader

        enableBottomToolbar={false}


        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: loading,
        }}
      />
    </Card>
  );
};

export default withRouter(AutomationsTable);
