import { Clear } from "@mui/icons-material";
import { Autocomplete, Box, Chip, TextField, Typography } from "@mui/material";
import { find, isEmpty } from "lodash";
import MaterialReactTable from "material-react-table";
import { useMemo, useState } from "react";
import { API_SLUGS } from "../../../../constants";
import useFetch from "../../../../hooks/http/useFetch";
const format = require("string-template");

const generateDiffCell = (params: any) => {
  let { value, diff } = params;

  // diff = 10;
  return (
    <Box sx={{ display: "flex", justifyContent: "start" }}>
      <b><Typography variant="body1">{value}</Typography></b>
      {typeof diff === "number" && !isNaN(diff) && Math.abs(diff) > 0 && (
        <Typography sx={{ ml: 1, mt: 0.2, color: diff < 0 ? "success.main" : "error.main" }} variant="body2">
          <b>{diff > 0 ? "+" : ""}
            {diff}</b>
        </Typography>
      )}
    </Box>
  );
};

export default function PerformanceTable({ result }: any) {
  const [comparingResultId, setComparingResultId] = useState(null);
  const { data } = useFetch(
    result?.id
      ? format(API_SLUGS.GET_OPERATIONS_PERFORMANCE_BY_RESULT, {
        resultId: result?.id,
      })
      : undefined
  );
  const { data: compareResultOptions } = useFetch(
    `/results?apiId=${result.apiId}&environmentId=${result.environmentId}&checkpoint=true`
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "operationId",
        header: "ID",
        Cell: (params: any) => {

          return (
            <>
              <Chip
                label={params.row?.original?.method}
                variant="outlined"
                size="small"
                color="success"
                sx={{ mr: 1 }}
              />
              {params?.row?.getValue("operationId")}
            </>
          );
        },
      },
      { accessorKey: "requestsCount", header: "Request Count", size: 30 },
      {
        accessorKey: "requestsFailed",
        header: "Failed Request Count",
        size: 30,
      },

      {
        header: "Time to first byte(ms)",
        columns: [
          {
            accessorKey: "timeToFirstByte.min",
            header: "Min",
            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("timeToFirstByte.min"),
                diff: params?.row?.original?.diff?.ttfb_min,
              });
            },
            size: 80,
          },

          {
            accessorKey: "timeToFirstByte.max",
            header: "Max",

            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("timeToFirstByte.max"),
                diff: params?.row?.original?.diff?.ttfb_max,
              });
            },
            size: 80,
          },
          {
            accessorKey: "timeToFirstByte.pct90",
            header: "Pct90",
            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("timeToFirstByte.pct90"),
                diff: params?.row?.original?.diff?.ttfb_pct90,
              });
            },
            size: 80,
          },
          {
            accessorKey: "timeToFirstByte.pct95",
            header: "Pct95",

            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("timeToFirstByte.pct95"),
                diff: params?.row?.original?.diff?.ttfb_pct95,
              });
            },
            size: 80,
          },
          {
            accessorKey: "timeToFirstByte.pct99",
            header: "Pct99",
            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("timeToFirstByte.pct99"),
                diff: params?.row?.original?.diff?.ttfb_pct99,
              });
            },
            size: 80,
          },
        ],
      },
      {
        header: "Response time(ms)",
        columns: [
          {
            accessorKey: "responseTime.min",
            header: "Min",
            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("responseTime.min"),
                diff: params?.row?.original?.diff?.rt_min,
              });
            },
            size: 80,
          },
          {
            accessorKey: "responseTime.max",
            header: "Max",

            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("responseTime.max"),
                diff: params?.row?.original?.diff?.rt_max,
              });
            },
            size: 80,
          },

          {
            accessorKey: "responseTime.pct90",
            header: "Pct90",

            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("responseTime.pct90"),
                diff: params?.row?.original?.diff?.rt_pct90,
              });
            },
            size: 80,
          },
          {
            accessorKey: "responseTime.pct95",
            header: "Pct95",

            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("responseTime.pct95"),
                diff: params?.row?.original?.diff?.rt_pct95,
              });
            },
            size: 80,
          },
          {
            accessorKey: "responseTime.pct99",
            header: "Pct99",
            Cell: (params: any) => {
              return generateDiffCell({
                value: params?.row?.getValue("responseTime.pct99"),
                diff: params?.row?.original?.diff?.rt_pct99,
              });
            },
            size: 80,
          },
        ],
      },
    ],
    []
  );

  const { data: compareData } = useFetch(
    comparingResultId
      ? format(API_SLUGS.GET_OPERATIONS_PERFORMANCE_BY_RESULT, {
        resultId: comparingResultId,
      })
      : undefined
  );

  // add compare Data in a compareData accessorKey in data
  const formattedData = useMemo(() => {
    if (!data?.operations) return null;
    if (!compareData?.operations) return data.operations;
    // create map of compareData
    const compareDataMap = compareData.operations.reduce(
      (acc: any, curr: any) => {
        acc[curr.operationId] = curr;
        return acc;
      },
      {}
    );
    return data.operations.map((operation: any) => {
      const compareOperation = compareDataMap[operation.operationId];

      const newData = {
        ...operation,
        // diff of all the values ,
        diff: {
          requestCount: operation.requestCount - compareOperation.requestCount,
          failedRequestCount:
            operation.failedRequestCount - compareOperation.failedRequestCount,
          ttfb_min:
            operation.timeToFirstByte.min -
            compareOperation.timeToFirstByte.min,
          ttfb_max:
            operation.timeToFirstByte.max -
            compareOperation.timeToFirstByte.max,

          ttfb_pct90:
            operation.timeToFirstByte.pct90 -
            compareOperation.timeToFirstByte.pct90,
          ttfb_pct95:
            operation.timeToFirstByte.pct95 -
            compareOperation.timeToFirstByte.pct95,
          ttfb_pct99:
            operation.timeToFirstByte.pct99 -
            compareOperation.timeToFirstByte.pct99,
          rt_min:
            operation.responseTime.min - compareOperation.responseTime.min,
          rt_max:
            operation.responseTime.max - compareOperation.responseTime.max,

          rt_pct90:
            operation.responseTime.pct90 - compareOperation.responseTime.pct90,
          rt_pct95:
            operation.responseTime.pct95 - compareOperation.responseTime.pct95,
          rt_pct99:
            operation.responseTime.pct99 - compareOperation.responseTime.pct99,
        },
        compareData: compareOperation,
      };

      return newData;
    });
  }, [data, compareData]);

  if (!formattedData) return null;
  return (
    <>
      {!isEmpty(compareResultOptions?.results) && !comparingResultId && (
        <Box sx={{ display: "inline", textAlign: "right" }}>
          <Typography variant="h6" sx={{ textAlign: "left", mb: 0.5 }}>
            Compare with another run {`(Checkpoints only)`}
          </Typography>
          <Autocomplete
            sx={{ width: "fit-content", minWidth: "15em" }}
            getOptionLabel={(option: any) => option.name}
            options={compareResultOptions?.results!}
            onChange={(e, value) => {
              if (value) {
                setComparingResultId(value.id);
              } else {
                setComparingResultId(null);
              }
            }}
            size="small"
            renderInput={(params): JSX.Element => {
              return (
                <TextField
                  sx={{ textAlign: "left", m: 0 }}
                  placeholder="Choose a checkpoint"
                  name="analyzers"
                  helperText="Name a run to turn it into a checkpoint"
                  variant="outlined"
                  {...params}
                />
              );
            }}
          />
        </Box>
      )}

      {!isEmpty(compareResultOptions?.results) && comparingResultId && (
        <Box sx={{ display: "inline", textAlign: "right" }}>
          <Typography variant="h6" sx={{ textAlign: "left" }}>
            <Clear
              className="hand"
              sx={{ pt: 1 }}
              onClick={() => {
                setComparingResultId(null);
              }}
            />
            Comparing with &nbsp;
            <Chip
              label={`${find(compareResultOptions?.results, { id: comparingResultId })
                ?.name
                }`}
            ></Chip>
          </Typography>
        </Box>
      )}
      <div style={{ height: 600, width: "100%" }}>
        <MaterialReactTable muiTablePaperProps={{
          elevation: 0
        }} enableGlobalFilter={false}
          // enableGrouping
          data={formattedData.map((o: any) => ({ ...o, id: o.operationId }))}
          columns={columns}
          
          initialState={{ density: "compact" }}
        // experimentalFeatures={{ columnGrouping: true }}
        // disableRowSelectionOnClick
        // columnGroupingModel={columnGroupingModel}
        />
      </div>
    </>
  );
}
