import {
  Alert,
  Box,
  Button,
  TextField,
  Checkbox,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { API_SLUGS, ERRORS, ROUTES, STRINGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import { IUserStoreState, useUserStore } from "../../store/userState";
import { validateEmail } from "../../utils/validateEmail";
import { withRouter } from "react-router-dom";

interface ISignUpFormValues {
  organizationName?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  checked?: boolean;
  password?: string;
}

interface IError {
  error: boolean;
  message: string;
}

interface ISignUpFormError {
  organizationName?: IError;
  username?: IError;
  firstName?: IError;
  lastName?: IError;
  email?: IError;
  password?: IError;
}

const SignUpForm: React.FC<RouteComponentProps> = ({ history }) => {
  const [formValues, setFormValues] = useState({} as ISignUpFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    organizationName: {},
    username: {},
    firstName: {},
    lastName: {},
    email: {},
    password: {},
  } as ISignUpFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const { isLoggedIn, setCurrentUser } = useUserStore(
    (state) => state
  ) as IUserStoreState;

  const setError = (values: ISignUpFormError) => {
    setFormErrorValues({ ...formErrorValues, ...values });
  };

  const verifyPassword = (values: ISignUpFormValues) => {
    if ("password" in values) {
      if (values.password!.length < 8) {
        setError({
          password: { error: true, message: ERRORS.PASSWORD_LENGTH_SHORT },
        });
        return false;
      }
    } else {
      setError({ password: { error: true, message: ERRORS.PASSWORD_EMPTY } });
      return false;
    }
    setError({ password: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const verifyEmail = (values: ISignUpFormValues) => {
    if ("email" in values) {
      if (!validateEmail(values.email!)) {
        setError({ email: { error: true, message: ERRORS.INVALID_EMAIL } });
        return false;
      }
    } else {
      setError({ email: { error: true, message: ERRORS.EMAIL_EMPTY } });
      return false;
    }

    setError({ email: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const verifyFirstName = (values: ISignUpFormValues) => {
    if (!("firstName" in values)) {
      setError({
        firstName: { error: true, message: ERRORS.FIRST_NAME_EMPTY },
      });
      return false;
    }

    setError({ firstName: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const verifyLastName = (values: ISignUpFormValues) => {
    if (!("lastName" in values)) {
      setError({ lastName: { error: true, message: ERRORS.LAST_NAME_EMPTY } });
      return false;
    }

    setError({ lastName: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const handleChange = (
    value: any,
    type:
      | "organizationName"
      | "username"
      | "firstName"
      | "lastName"
      | "email"
      | "password"
  ) => {
    let newValues = { ...formValues, [type]: value };
    switch (type) {
      case "email":
        verifyEmail(newValues);
        break;
      case "password":
        verifyPassword(newValues);
        break;
      case "firstName":
        verifyFirstName(newValues);
        break;
      case "lastName":
        verifyLastName(newValues);
        break;
      default:
        break;
    }
    setFormValues(newValues);
  };

  const handleSubmit = async () => {
    if (
      verifyFirstName(formValues) &&
      verifyLastName(formValues) &&
      verifyEmail(formValues) &&
      verifyPassword(formValues) &&
      formValues.checked
    ) {
      setIsSubmitting(true);
      try {
        let userResponse = await axiosInstance.post(
          API_SLUGS.SIGNUP,
          formValues
        );
        setCurrentUser(userResponse.data?.data);
        history.push(ROUTES.DASHBOARD);
      } catch (error: any) {
        try {
          setErrorMessage(error.response?.data?.message);
        } catch (error: any) {}
      }
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {/* <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}>
                <TextField
                    autoFocus
                    fullWidth
                    label={STRINGS.ORGANIZATION_NAME}
                    margin="normal"
                    name="organizationName"
                    type="text"
                    error={formErrorValues.organizationName?.error}
                    helperText={formErrorValues.organizationName?.message}
                    variant="outlined"
                    onChange={(e: any) => { handleChange(e.target.value, 'organizationName'); }}
                    inputProps={{ "data-testid": "organizationName" }}
                />

            </Box> */}
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          autoFocus
          fullWidth
          label={STRINGS.FIRST_NAME}
          margin="normal"
          name="firstName"
          type="text"
          error={formErrorValues.firstName?.error}
          helperText={formErrorValues.firstName?.message}
          variant="outlined"
          onChange={(e: any) => {
            handleChange(e.target.value, "firstName");
          }}
          inputProps={{ "data-testid": "firstName" }}
        />
        <TextField
          fullWidth
          label={STRINGS.LAST_NAME}
          margin="normal"
          name="lastName"
          type="text"
          error={formErrorValues.lastName?.error}
          helperText={formErrorValues.lastName?.message}
          variant="outlined"
          onChange={(e: any) => {
            handleChange(e.target.value, "lastName");
          }}
          inputProps={{ "data-testid": "lastName" }}
        />
      </Box>
      <TextField
        fullWidth
        label={STRINGS.EMAIL_ADDRESS}
        margin="normal"
        name="email"
        type="email"
        error={formErrorValues.email?.error}
        helperText={formErrorValues.email?.message}
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "email");
        }}
        inputProps={{ "data-testid": "email" }}
      />
      {/* <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}>
                <TextField
                    autoFocus
                    fullWidth
                    label={STRINGS.USERNAME}
                    margin="normal"
                    name="organizationName"
                    type="text"
                    error={formErrorValues.username?.error}
                    helperText={formErrorValues.username?.message}
                    variant="outlined"
                    onChange={(e: any) => { handleChange(e.target.value, 'username'); }}
                    inputProps={{ "data-testid": "username" }}
                />

            </Box> */}
      <TextField
        fullWidth
        label={STRINGS.PASSWORD}
        margin="normal"
        name="password"
        type="password"
        error={formErrorValues.password?.error}
        helperText={formErrorValues.password?.message}
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "password");
        }}
        inputProps={{ "data-testid": "password" }}
      />
      <Box sx={{ display: "flex", justifyContent: "start", textAlign: "left" }}>
        <Checkbox
          sx={{ mt: 0, height: "fit-content" }}
          onChange={({ target }) =>
            setFormValues({ ...formValues, checked: target.checked })
          }
        />
        <Typography sx={{ mt: 1 }}>
          By registering you are agreeing to the{" "}
          <a href="https://swrl.ai/privacy-policy/" target="_blank">
            privacy policy
          </a>{" "}
          and{" "}
          <a
            href="https://swrl.ai/trial-agreement/"
            target="_blank"
            rel="noreferrer"
          >
            trial agreement
          </a>
        </Typography>
      </Box>

      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          data-testid="signup-button"
        >
          {STRINGS.SIGNUP}
        </Button>
      </Box>
    </form>
  );
};

export default withRouter(SignUpForm);
