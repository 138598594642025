import {
  Alert, Autocomplete, Card, Chip, Grid,
  TextField, Tooltip, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { debounce, find, isEmpty, map, snakeCase } from "lodash";
import MiniSearch from "minisearch";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import { IOperationState, useOperationState } from "../../store/operations";
import Loaders from "../dashboard/Loaders";
import IntConstant from "./forms/IntConstant";
import IntRange from "./forms/IntRange";
import IPv4 from "./forms/IPv4";
import IPv6 from "./forms/IPv6";
import Regex from "./forms/Regex";
import StringConstant from "./forms/StringConstant";
import Uuidv4 from "./forms/Uuidv4";

import { API_SLUGS } from "../../constants";
import useFetchOperations from "../../hooks/process/useFetchOperations";
import Default from "./forms/Default";
import ResourceReference from "./forms/ResourceReference";
import { operationFunctions } from "./operationFunctions";
import { flattenIOTree } from "./treeFunctions";
var format = require("string-template");

type SupportedFields = "string" | "number" | "boolean" | "object";
export const GENERATOR_OPTIONS: any = {
  string: [
    { key: "DEFAULT", label: "IntelliFuzz" },
    { key: "RESOURCE_REFERENCE", label: "Resource Reference" },
    { key: "REGEX", label: "Regex" },
    { key: "IPV4", label: "IPv4" },
    { key: "IPV6", label: "IPv6" },
    { key: "UUIDV4", label: "UUIDv4" },
    { key: "CONST_STRING", label: "String Constant" },
  ],
  number: [
    { key: "DEFAULT", label: "IntelliFuzz" },
    { key: "RESOURCE_REFERENCE", label: "Resource Reference" },
    { key: "CONST_INT", label: "Integer" },
    { key: "INT_RANGE", label: "Integer Range" },
  ],
  boolean: [{ key: "DEFAULT", label: "IntelliFuzz" }],
  object: [{ key: "DEFAULT", label: "IntelliFuzz" }],
};

interface IOperationViewConfig {
  readOnly?: boolean;
}

interface IOperationView {
  apiId?: string;
  revId?: string;
  defaultOperationId?: string;
  view?: "ASSIGNED" | "ADD" | "BOTH";
  config?: IOperationViewConfig;
}

const OperationView: React.FC<IOperationView> = ({
  apiId,
  revId,
  defaultOperationId = undefined,
  view = "BOTH",
  config = { readOnly: false },
}) => {
  const [filteredDocuments, setFilteredDocuments] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSingleColumn, setCurrentSingleColumn] = useState<
    "ASSIGNED" | "ADD" | "BOTH"
  >(view);
  const [selectedKey, setSelectedKey] = useState("" as any);
  const {
    currentApiId,
    setCurrentApiId,
    operations,
    setOperations,
    setDdg,
    generators,
    setGenerators,
  } = useOperationState((state) => state) as IOperationState;
  const [currentOperation, setCurrentOperation] = useState<any>(null);
  const [miniSearch, setMiniSearch] = useState(
    new MiniSearch({
      fields: ["name", "operationID", "snake_name", "flattenedInputString"],
      storeFields: ["resource", "operationID", "inputs", "outputs"],
      extractField: (document, fieldName) => {
        if (fieldName === "snake_name") {
          return snakeCase(document["name"]);
        }
        // Access nested fields
        return fieldName
          .split(".")
          .reduce((doc, key) => doc && doc[key], document);
      },
    })
  );
  const { operations: processedOperations } = useFetchOperations(
    "api",
    apiId || ""
  );

  useEffect(() => {
    setCurrentApiId(apiId || null);
  }, [apiId]);

  const fetchDDG = async () => {
    try {
      let graphResponse = await axiosInstance.get(
        format(API_SLUGS.GRAPH, {
          //@ts-ignore
          apiId: apiId,
          revId: revId,
        })
      );
      setDdg(graphResponse?.data?.ddg);
    } catch (error) { }
  };

  useEffect(() => {
    if (currentApiId && currentApiId === apiId) {
      flattenOperationInputs();
      getGenerators();
      fetchDDG();
    }
  }, [currentApiId, processedOperations]);

  useEffect(() => {
    let newCurrentOperation = null;
    try {
      if (defaultOperationId) {
        newCurrentOperation = find(operations, { id: defaultOperationId });
        setCurrentOperation(newCurrentOperation);
      }
    } catch (error) { }
  }, [defaultOperationId, operations]);

  const flattenOperationInputs = async () => {
    if (!processedOperations) return;
    for (const index in processedOperations) {
      const flattenedInputs = flattenIOTree(
        processedOperations[index]["inputs"]
      );
      processedOperations[index].flattenedInputs = flattenedInputs;
      processedOperations[index].flattenedInputString = map(
        flattenedInputs,
        "parentPath"
      )
        .join(" ")
        .split("/")
        .join(" ");
    }

    setOperations(processedOperations);
    setIsLoading(false);
  };

  const getGenerators = async () => {
    let newGenerators = await operationFunctions.getAssignedGenerators(
      currentApiId!
    );
    setGenerators(newGenerators);
  };

  const debounceOnChange = useCallback(
    debounce((value) => {
      onSearchChange(value);
    }, 400),
    []
  );

  useEffect(() => { }, [currentOperation]);

  const onSearchChange = (value: any) => {
    let data = miniSearch.autoSuggest(value, { fuzzy: 2 });
    let terms: string[] = [];
    data?.map((record) => (terms = [...terms, ...record.terms]));
    let searchResults = miniSearch.search(terms.join(" "), {
      fuzzy: 2,
    });
    setFilteredDocuments(searchResults);
  };

  useEffect(() => {
    if (!isEmpty(operations)) {
      miniSearch.removeAll();
      miniSearch.addAll(operations!);
    }
  }, [operations]);

  if (currentApiId !== apiId) {
    return null;
  }

  if (isLoading) {
    return <Loaders />;
  }

  if (isEmpty(operations)) {
    return (
      <Box sx={{ pt: 4, textAlign: "center" }}>
        <Alert icon={false} severity="warning">
          No generator available
        </Alert>
      </Box>
    );
  }

  return (
    <Grid container sx={{ mt: 2 }} columnSpacing={4}>
      <Grid item xs={12}>
        {currentSingleColumn === "BOTH" ? (
          <TextField
            sx={{ width: "100%" }}
            name="apiId"
            size={"small"}
            autoComplete="off"
            variant="outlined"
            placeholder="Search for a key"
            onChange={({ target }) => {
              let value = target.value;
              debounceOnChange(value);
            }}
          />
        ) : null}

        <Box sx={{ maxHeight: "60vh", overflow: "auto" }}>
          {!isEmpty(filteredDocuments)
            ? filteredDocuments.map((record) => (
              <Card
                elevation={8}
                sx={{ textAlign: "left", mt: 2, mb: 2, p: 2 }}
              >
                <Typography
                  variant="h6"
                  className="hand"
                  sx={{ display: "inline" }}
                  onClick={() => {
                    setCurrentOperation(find(operations, { id: record.id }));
                    setSelectedKey("");
                    setFilteredDocuments([]);
                  }}
                >
                  {record.operationID}
                </Typography>
              </Card>
            ))
            : null}
        </Box>

        {!isEmpty(currentOperation) ? (
          <Box sx={{ display: !isEmpty(filteredDocuments) ? "none" : "block" }}>
            {view === "BOTH" ? (
              <Box sx={{ mb: 2, mt: 2 }}>
                <Typography
                  variant="overline"
                  sx={{ color: "text.secondary", fontSize: "12px" }}
                >
                  Operation
                </Typography>
                <Typography variant="h6">
                  {currentOperation.operationID}
                </Typography>
              </Box>
            ) : null}
            {!isEmpty(currentOperation.inputs) && (
              <TextField
                sx={{ width: "100%" }}
                name="apiId"
                size={"small"}
                autoComplete="off"
                label="Search for field"
                variant="outlined"
                value={selectedKey}
                placeholder="Search for field..."
                onChange={({ target }) => {
                  setSelectedKey(target.value);
                }}
              />
            )}

            <Box
              sx={{
                mt: 2,
                maxHeight: "60vh",
                overflow: "auto",
                p: 2,
                backgroundColor: "background.default",
                borderRadius: 2,
              }}
            >
              {!isEmpty(currentOperation.flattenedInputs) ? (
                <InputOutputGenerator
                  currentOperation={currentOperation}
                  data={currentOperation.flattenedInputs}
                  selectedKey={selectedKey}
                  config={config}
                />
              ) : (
                <Box>
                  <Typography variant="h6">
                    There are no fields in this operation
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

const InputOutputGenerator: React.FC<any> = ({
  data,
  selectedKey,
  currentOperation,
  config,
}) => {
  return (
    <Grid item container xs={12}>
      {!isEmpty(data)
        ? data?.map((input: any) => (
          <Grid item container xs={12}>
            <TypeSegregator
              config={config}
              currentOperation={currentOperation}
              data={input}
              selectedKey={selectedKey}
            />
          </Grid>
        ))
        : null}
    </Grid>
  );
};

const TypeSegregator: React.FC<any> = ({
  data,
  selectedKey,
  currentOperation,
  config,
}) => {
  return (
    <Grid item container xs={12}>
      <Generator
        fieldData={data}
        config={config}
        currentOperation={currentOperation}
        keyPath={data.parentPath}
        selectedKey={selectedKey}
      />
    </Grid>
  );
};

const Generator: React.FC<any> = ({
  fieldData,
  keyPath,
  selectedKey,
  currentOperation,
  config,
}: any) => {
  const { currentApiId, generators, setGenerators, ddg, operations } =
    useOperationState((state) => state) as IOperationState;
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const [generator, setGenerator] = useState({
    type: "DEFAULT",
    data: {},
  } as any);
  const userSetGenerator = useMemo(
    () => generators?.[currentApiId!]?.[currentOperation?.operationID]?.[keyPath],
    [generators, currentApiId, currentOperation.operationID, keyPath]
  );

  const [isNew, setIsNew] = useState(true);

  const handleDelete = async () => {
    try {
      await operationFunctions.deleteGenerator(currentApiId!, generator.id);
      setMessage({ title: "Saved", type: "success" });

      setGenerator({ type: "DEFAULT", data: {} });
      setIsNew(true);
      let newOperationObject =
        generators[currentApiId!]?.[currentOperation.operationID];
      delete newOperationObject?.[keyPath];
      setGenerators({
        ...generators,
        [currentApiId!]: {
          ...generators[currentApiId!],
          [currentOperation.operationID]: {
            ...newOperationObject,
          },
        },
      });
    } catch (error) {
      console.log({ error });
      setMessage({ title: "Something went wrong", type: "error" });
    }
  };

  const handleSubmit = async (data = {}) => {
    const currentApiObject = generators[currentApiId!] || {};
    const currentOperationObject =
      generators[currentApiId!]?.[currentOperation.operationID] || {};

    try {
      if (!generator.id) {
        let response = await operationFunctions.postGenerator(
          currentApiId!,
          currentOperation.operationID,
          { ...generator, ...data, keyPath }
        );
        setGenerator(response?.data);
        setGenerators({
          ...generators,
          [currentApiId!]: {
            ...currentApiObject,
            [currentOperation.operationID]: {
              ...currentOperationObject,
              [keyPath]: response?.data,
            },
          },
        });
        setIsNew(false);
      } else {
        let response = await operationFunctions.patchGenerator(
          currentApiId!,
          currentOperation.operationID,
          { ...generator, ...data, keyPath }
        );
        setGenerator(response?.data);
        setGenerators({
          ...generators,
          [currentApiId!]: {
            ...currentApiObject,
            [currentOperation.operationID]: {
              ...currentOperationObject,
              [keyPath]: response?.data,
            },
          },
        });
      }
      setMessage({ title: "Saved", type: "success" });
    } catch (error) {
      console.log({ errorSub: error });
      setMessage({ title: "Something went wrong", type: "error" });
    }
  };

  useEffect(() => {
    try {
      const existingGenerator =
        userSetGenerator || currentOperation.generators?.[keyPath];

      if (existingGenerator) {
        setGenerator(existingGenerator);
        setIsNew(isEmpty(userSetGenerator));
      } else {
        setGenerator({ type: "DEFAULT", data: {} });
      }
    } catch (error) {
      console.log("Error while extracting generator", { error });
    }
  }, [currentApiId, userSetGenerator, currentOperation, keyPath]);

  if (!keyPath || !keyPath.toLowerCase().includes(selectedKey.toLowerCase())) {
    return null;
  }

  return (
    <Card
      elevation={8}
      sx={{
        p: 2,
        mt: 1,
        mb: 1,
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6">{keyPath}</Typography>

        <Box>
          {generators?.[currentApiId!]?.[currentOperation.operationID]?.[
            keyPath
          ] ? (
            <Tooltip title="User has overwritten the generator.">
              <Chip sx={{ ml: 1 }} color="primary" size="small" label={"O"} />
            </Tooltip>
          ) : (
            <Tooltip title="This field is using the generator identified automatically.">
              <Chip
                sx={{ ml: 1 }}
                variant="outlined"
                color="primary"
                size="small"
                label={"A"}
              />
            </Tooltip>
          )}
          <Chip
            sx={{ ml: 1 }}
            variant="outlined"
            color="secondary"
            size="small"
            label={fieldData?.type}
          />
        </Box>
      </Box>
      {config.readOnly && <ReadOnlyGeneratorView generator={generator} />}
      {!config.readOnly && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {generator && generator?.type && fieldData?.type ? (
              <Autocomplete
                sx={{ mt: 2, mr: 2, width: "100%" }}
                getOptionLabel={(option: any) => option?.label}
                options={GENERATOR_OPTIONS[fieldData?.type as SupportedFields]}
                onChange={(e, value) =>
                  setGenerator({
                    ...generator,
                    type: value?.key,
                    updated: true,
                  })
                }
                value={find(
                  GENERATOR_OPTIONS[
                  fieldData?.type as SupportedFields
                  ] as any[],
                  { key: generator?.type }
                )}
                disableClearable={true}
                size="small"
                renderInput={(params): JSX.Element => {
                  return (
                    <TextField
                      sx={{ textAlign: "left", m: 0 }}
                      label="Type"
                      name="analyzers"
                      variant="outlined"
                      {...params}
                    />
                  );
                }}
              />
            ) : null}
            {generator?.type === "RESOURCE_REFERENCE" ? (
              <ResourceReference
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                operations={operations}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
            {generator?.type === "REGEX" ? (
              <Regex
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}

            {generator?.type === "DEFAULT" ? (
              <Default
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
            {generator?.type === "IPV4" ? (
              <IPv4
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
            {generator?.type === "IPV6" ? (
              <IPv6
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
            {generator?.type === "UUIDV4" ? (
              <Uuidv4
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
            {generator?.type === "CONST_STRING" ? (
              <StringConstant
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}

            {generator?.type === "CONST_INT" ? (
              <IntConstant
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}

            {generator?.type === "INT_RANGE" ? (
              <IntRange
                data={generator?.data}
                allowSave={generator.updated}
                onSubmit={handleSubmit}
                onChange={(data: any) =>
                  setGenerator({ ...generator, data: data })
                }
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Card>
  );
};

const ReadOnlyGeneratorView: FC<any> = ({ generator }) => {
  return (
    <Box>
      <Typography variant="h6">
        {
          find(
            [...GENERATOR_OPTIONS["string"], ...GENERATOR_OPTIONS["number"]],
            { key: generator.type }
          )?.label
        }
      </Typography>
      {Object.keys(generator?.data)?.map((key) => (
        <Chip sx={{ mr: 1 }} label={`${key} :${generator.data[key]} `} />
      ))}
    </Box>
  );
};

export default OperationView;
