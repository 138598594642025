import { ContentCopy, Download, Terminal } from "@mui/icons-material";
import { Box, Button, Dialog, IconButton, Tooltip } from "@mui/material";
import copy from "copy-to-clipboard";
import MaterialReactTable from "material-react-table";
import { FC, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../../configs";
import { API_SLUGS } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { usePageContext } from "../../../hooks/process/usePageContext";
import { PaperClip } from "../../../icons/paper-clip";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
import { parseHermitConfig } from "../../../utils/hermitConfigUtils";
import { GroupProjectBreadcrumb } from "../../analysis/ViewIncidents/IncidentsTable";
import ModalWrapper from "../../common/modal/wrapper";
import AddJobSpecificationsForm from "../AddJobSpecifications/addJobSpecificationsForm";
import { CommitConfigForm } from "../CommitConfigForm";
import { JobSpecContainer } from "../JobSpecContainer";
import { ConfigActions } from "./ConfigActions";
const format = require("string-template")

const ConfigTable: FC<any> = ({ apiId, defaultProjectID = undefined }) => {
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [configId, setConfigId] = useState("");
  const [hermitConf, setHermitConf] = useState({} as any);
  const [additionalSettings, setAdditionalSettings] = useState({} as any);
  const [expandedAction, setExpandedAction] = useState("");
  const { setMessage, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;
  const { groups, projects, changeGroup, changeProject, selectedGroup, selectedProject, groupsLoading, projectsLoading } = usePageContext();

  const configurations_data_query = new URLSearchParams(
    { ...(apiId && { apiId }), ...(defaultProjectID ? { applicationId: defaultProjectID } : selectedProject?.id ? { applicationId: selectedProject?.id } : {}) }
  ).toString();
  let data_source = API_SLUGS.RUN_CONFIGURATIONS;
  if (configurations_data_query) {
    data_source = `${API_SLUGS.RUN_CONFIGURATIONS}?${configurations_data_query}`
  }
  const { data: configurationsData, loading, fetchData } = useFetch(data_source);
  const { data: apisData } = useFetch(selectedProject?.id || defaultProjectID ? format(API_SLUGS.APIS, { applicationId: defaultProjectID || selectedProject?.id }) : null);

  const hermitConfigs = useMemo(() => {
    return configurationsData?.hermitConfigs || [];
  }
    , [configurationsData]);

  const handleConfigDialogOpen = (id: string) => {
    setConfigId(id);
    setConfigDialogOpen(true);
  };

  const handleConfigDialogClose = () => {
    setConfigDialogOpen(false);
  };

  const handleDownloadDialogOpen = (id: string) => {
    setConfigId(id);
    setDownloadDialogOpen(true);
  };

  const handleDownloadDialogClose = () => {
    setDownloadDialogOpen(false);
  };

  const handleActionDialogOpen = (id: string) => {
    setConfigId(id);
    setActionDialogOpen(true);
  };

  const handleActionDialogClose = () => {
    setActionDialogOpen(false);
  };

  const deleteConfig = async (id: string) => {
    try {
      await axiosInstance.delete(`${API_SLUGS.RUN_CONFIGURATIONS}/${id}`);
      window.location.reload();
    } catch (error: any) { }
  };

  const downloadConfig = async (id: string) => {
    try {
      const jobResponse = await axiosInstance.get(
        `${API_SLUGS.RUN_CONFIGURATIONS}/${id}`
      );
      setHermitConf(parseHermitConfig(jobResponse?.data?.config));
      setAdditionalSettings(jobResponse?.data?.additionalSettings);
      handleDownloadDialogOpen(id);
    } catch (error: any) { }
  };

  const toggleExpandedAction = (jobId: string, reloadNeeded: boolean = false) => {
    if (reloadNeeded) {
      window.location.reload();
    }
    setExpandedAction((prev: string) => {
      if (prev && prev === jobId) {
        return "";
      }
      return jobId;
    });
  };

  const formatRunCommand = (id: string) => {
    let platformString = ` --platform ${getConfigs().baseApiUrl}`;
    if (getConfigs().baseApiUrl.includes("app")) {
      platformString = "";
    }
    return `./sift run --config-id ${id}${platformString}`;
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 2, textAlign: "left" }}>
        <ModalWrapper
          maxWidth="lg"
          title="Add Configuration"
          trigger={
            <Button color="primary" size="small" variant="contained" disabled={!apiId && !apisData?.apis.length}>
              Add Configuration
            </Button>
          }
          child={<AddJobSpecificationsForm apiId={apiId || (apisData?.apis.length && apisData?.apis[0]?.id)} preventRedirect callback={
            fetchData
          }
            source={"new"}
          />}

        />
      </Box>
      <Dialog
        maxWidth="lg"
        onClose={handleConfigDialogClose}
        open={configDialogOpen}
      >
        <Box sx={{ m: 3 }}>
          <JobSpecContainer id={configId} source={"wizard"} />
        </Box>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleDownloadDialogClose}
        open={downloadDialogOpen}
      >
        <Box sx={{ m: 3 }}>
          <CommitConfigForm
            hermitConf={hermitConf}
            additionalSettings={additionalSettings}
            configId={configId}
            onSubmit={handleDownloadDialogClose}
          />
        </Box>
      </Dialog>
      <Dialog
        maxWidth="md"
        onClose={handleActionDialogClose}
        open={actionDialogOpen}
      >
        <Box sx={{ m: 3, textAlign: "center" }}>
          <Box>
            <code>{formatRunCommand(configId)}</code>
          </Box>
          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              copy(formatRunCommand(configId));
              clearMessage();
              setMessage({
                title: "CLI run command copied to clipboard",
                type: "success",
              });
              handleActionDialogClose();
            }}
          >
            Copy
          </Button>
        </Box>
      </Dialog>

      {!defaultProjectID && <Box sx={{ display: "flex", justifyContent: "end", mr: 1, mb: 1 }}>
        <GroupProjectBreadcrumb
          selectedGroup={selectedGroup}
          selectedProject={selectedProject}
          groups={groups}
          projects={projects}
          changeGroup={changeGroup}
          changeProject={changeProject}
          groupsLoading={groupsLoading}
          projectsLoading={projectsLoading}

        />

      </Box>}

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[
          {
            accessorKey: "name",
            header: "Name",

            size: 130,
            Cell: (params: any) => (
              <Box onClick={() => {
                handleConfigDialogOpen(params?.row?.original?.id);
              }}>
                {params?.row?.original?.name}
              </Box>
            ),

          },
          {
            accessorKey: "id",
            header: "ID",
            size: 130,
            Cell: (params: any) => (
              <pre
                style={{ display: "inline", cursor: "pointer" }}
                onClick={() => {
                  copy(
                    params?.row?.original?.id
                  );
                  clearMessage();
                  setMessage({
                    title: "Configuration ID copied to the clipboard",
                    type: "success",
                  });
                }}
              >
                <ContentCopy fontSize="inherit" />
                {params?.row?.original?.id.split("-")[0]}
              </pre>
            ),
          },


          {
            accessorKey: "applicationName",
            header: "Application",

            size: 130,



          },
          {
            accessorKey: "apiName",
            header: "Asset",

            size: 90,



          },

          {
            accessorKey: "actions",

            header: "Actions",
            Cell: (params: any) => (
              <>
                <Tooltip title="Download configuration file">
                  <IconButton onClick={() => downloadConfig(
                    params?.row?.original?.id
                  )}>
                    <Download />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show command to run in terminal">
                  <IconButton
                    onClick={() => handleActionDialogOpen(params?.row?.original?.id)}
                  >
                    <Terminal />
                  </IconButton>
                </Tooltip>
                <ConfigActions
                  apiId={params?.row?.original?.apiId}
                  jobId={params?.row?.original?.id}
                  toggle={toggleExpandedAction}
                  open={expandedAction === params?.row?.original?.id}
                  download={downloadConfig}
                  deleteConf={deleteConfig}
                  runCommand={handleActionDialogOpen}
                  viewCommand={handleConfigDialogOpen}
                />
              </>
            ),
          },
        ]}
        data={hermitConfigs}
        initialState={{
          density: "compact",
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader

        enableBottomToolbar={false}


        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: loading,
        }}
      />


    </>
  );
};

export default withRouter(ConfigTable);
