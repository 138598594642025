import { Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { Routes } from "./Routes";
import LayoutWrapper from "./views/layouts/LayoutWrapper";
import {
  Alert,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Box,
} from "@mui/material";
import { createTheme } from "./theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getConfigs } from "./configs";

if (getConfigs().sentryDsn?.trim() !== "") {
  Sentry.init({
    dsn: getConfigs().sentryDsn,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// import { IAppState, useAppState } from './store/appState';

function Routing() {
  return (
    <Switch>
      {Routes.map((route, index) => {
        return (
          <Route
            exact
            key={index}
            path={route.slug}
            component={withRouter(() => (
              <LayoutWrapper
                layout={route.layout}
                Component={route.component}
                requireAuth={route.requireAuth}
                isTreeAvailable={route.isTreeAvailable}
              />
            ))}
          />
        );
      })}
    </Switch>
  );
}

function App() {
  let selectedTheme = localStorage.getItem("selectedTheme") as "light" | "dark";

  // const { globalError } = useAppState(state => state) as IAppState
  const theme = createTheme({
    //@ts-ignore
    mode:
      selectedTheme === "light" || selectedTheme === "dark"
        ? selectedTheme
        : "light",
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box className="App" sx={{ backgroundColor: "background.paper" }}>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Routing />
            </Suspense>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
