import {
    Box, Container, Typography
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ResultsTable from './ResultsTable';


const Results: React.FC<RouteComponentProps> = ({ history, match }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 1 }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',

                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Runs
                            </Typography>
                        </div>


                    </Box>



                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <ResultsTable />
                    </Box>

                </Container>
            </Box>
        </>
    );
};

export default withRouter(Results);
