// ProjectDetails.js
import { BorderColorOutlined, Terminal } from '@mui/icons-material';
import { Box, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { usePageContext } from '../../hooks/process/usePageContext';
import AddApplicationForm from '../applications/AddApplication/AddApplicationForm';
import ModalWrapper from '../common/modal/wrapper';
import ApiEnvironmentInsights from '../dashboard/widgetLibrary/apiEnvironmentInsights';
import CategoryBar from '../dashboard/widgetLibrary/categoryBar';
import SeverityTimeSeries from '../dashboard/widgetLibrary/severityTimeSeries';
import EnvironmentsTable from '../management/ViewEnvironments/EnvironmentsTable';


const ProjectDetails: FC<any> = ({ project, index, allowRedirect = true }) => {
    const { selectedGroup } = usePageContext();
    return (
        <div className="project-details-block">
            {project ? (

                <Box sx={{ pl: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "start", mt: 3, mb: 1 }}>

                        <Typography className={allowRedirect ? "hand" : ""} variant="h5" sx={{ textAlign: "left", fontSize: "1.25em" }} onClick={() => window.open(`/projects/${project.id}`)}>

                            {project?.name}
                        </Typography>

                        <ModalWrapper
                            maxWidth="lg"
                            title={`${project?.name} - Edit`}
                            trigger={
                                <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"

                                    icon={<Tooltip title="Edit"><BorderColorOutlined /></Tooltip>}


                                    sx={{ border: "none", mt: 1, ml: 1 }}
                                    onClick={() => { }}
                                />
                            }
                            child={<AddApplicationForm

                                defaultFormValues={{
                                    application: project.name,
                                }}
                                preventRedirect
                                callback={() => {
                                    window.location.reload()
                                }}
                                groupId={selectedGroup?.id}
                                updateOnly
                                defaultAllowEdit={true}
                                defaultAllowDelete={true}

                                updateUUID={project.id}
                            />}
                        />
                        <ModalWrapper
                            maxWidth="lg"
                            title={`${project?.name} - Environments`}
                            trigger={
                                <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    icon={<Tooltip title="Environments"><Terminal /></Tooltip>}


                                    sx={{ border: "none", mt: 1 }}
                                    onClick={() => { }}
                                />
                            }
                            child={<EnvironmentsTable applicationId={project.id} />}
                        />
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 1.5 }}>
                        <Grid item container xs={12} sx={{ border: "2px solid #5148e626", backgroundColor: "#5048e508", borderRadius: "0.35em", p: 3, mx: 2 }}>
                            <CategoryBar projectId={project?.id} height="200px" maxDisplayCount={4} />
                            <SeverityTimeSeries projectId={project?.id} height="220px" /></Grid>

                    </Grid>
                    <ApiEnvironmentInsights
                        applicationId={project?.id}
                        project={project}

                    />
                </Box>

            ) : (
                <p>Select a project to view details.</p>
            )}
        </div>
    );
}

export default ProjectDetails;
