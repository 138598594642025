
import { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../services/axios";
var format = require("string-template");

export const useTableInfinity = (responseKey: string, url: string|undefined, tableRef: any, limit: number) => {
  
  const [rows, setRows] = useState<any[]>([]);
  
  const [isFetching, setIsFetching] = useState(false);
  const [lastFetchCount, setLastFetchCount] = useState(limit);

  useEffect(() => {
    if(limit){
      setLastFetchCount(limit);
      setRows([]);
    }
  }, [limit]);



  const fetchNextPage = useCallback(async () => {
    setIsFetching(true);
    if(url){
      try {
        const response = await axiosInstance.get(
          format(url, { offset: rows.length, limit })
        );
        setRows([...rows, ...response?.data?.[responseKey]]);
        setLastFetchCount(response?.data?.[responseKey]?.length);
      } catch (error) {}
    }
    setIsFetching(false);
  }, [rows, url, limit, responseKey]);

  

  const fetchMoreOnBottomReached = useCallback(
    async (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          lastFetchCount === limit
        ) {
          await fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, lastFetchCount, limit]
  );


  useEffect(() => {
    
    if(url){
      setRows([]);
      setLastFetchCount(limit);
    }
   
  }, [url, limit]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableRef.current);
  }, [fetchMoreOnBottomReached, tableRef]);

  return { rows, fetchMoreOnBottomReached, isFetching };
};
