import { Box, Button, Card, Tooltip } from "@mui/material";
import { FC, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { BorderColorOutlined, ContentCopy } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import MaterialReactTable from "material-react-table";
import useFetch from "../../../hooks/http/useFetch";
import { Plus } from "../../../icons/plus";
import { IAlertState, useAlertState } from "../../../store/alertState";
import ModalWrapper from "../../common/modal/wrapper";
import AddEnvironmentForm from "../AddEnvironment/AddEnvironmentForm";

interface IEnvironmentsTableProps extends RouteComponentProps {
  applicationId: string;
}
const EnvironmentsTable: FC<IEnvironmentsTableProps> = ({
  history,
  match,
  applicationId,
}) => {
  const { setMessage, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;
  const {
    data: environmentsData,
    loading,
    fetchData,
  } = useFetch(`/applications/${applicationId}/environments`);
  const environments = useMemo(
    () => environmentsData?.environments || [],
    [environmentsData]
  );
  return (
    <Card elevation={0} sx={{ textAlign: "left" }}>

      <ModalWrapper
        maxWidth="md"
        title="Add Environment"
        trigger={
          <Button color="primary" size="small" variant="contained" sx={{ my: 2 }}>
            Add <Plus />
          </Button>
        }
        child={
          <AddEnvironmentForm
            preventRedirect
            applicationId={applicationId}
            callback={() => {

              fetchData()
            }}
          />
        }
      />

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[

          {
            accessorKey: "name",
            header: "Name",

            size: 130,
          },
          {
            accessorKey: "id",
            header: "ID",

            size: 130,
            Cell: (params: any) => (
              <pre style={{ display: "inline" }} onClick={() => {
                copy(params?.row?.original?.id);
                clearMessage();
                setMessage({
                  title: "ID has been copied to clipboard",
                  type: "success",
                });
              }}>
                <Tooltip title="Click to copy">

                  <Box>
                    {params?.row?.original?.id}
                    <ContentCopy fontSize="inherit" sx={{ ml: 2 }} /></Box>
                </Tooltip>
              </pre>
            ),
          },

          {
            accessorKey: "actions",

            header: "Actions",
            Cell: (params: any) => (
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <ModalWrapper
                  maxWidth="sm"
                  title="Edit"
                  trigger={
                    <Button>
                      <BorderColorOutlined />
                    </Button>
                  }
                  child={
                    <AddEnvironmentForm
                      updateOnly
                      callback={() => fetchData()}
                      preventRedirect
                      updateUUID={params?.row?.original?.id}
                      applicationId={applicationId}
                      defaultFormValues={{ environment: params?.row?.original?.name }}
                    />
                  }
                />
              </Box>
            ),
          },
        ]}
        data={environments}
        initialState={{
          density: "compact",
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader

        enableBottomToolbar={false}


        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: loading,
        }}
      />
    </Card>
  );
};

export default withRouter(EnvironmentsTable);
