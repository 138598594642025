import { TextField } from "@material-ui/core";
import { Settings } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Alert,
  Box,
  Button, Chip,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Tab,
  Tabs, Tooltip, Typography
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { getConfigs } from "../../../../configs";
import { DocumentText } from "../../../../icons/document-text";
import { ExternalLink } from "../../../../icons/external-link";
import { Refresh } from "../../../../icons/refresh";
import { axiosInstance } from "../../../../services/axios";
import RunHistoryPopup from "../../../applications/apiComponents/runHistoryPopup";
import { ApiModalContent } from "../../../applications/ApiModal";
import ModalWrapper from "../../../common/modal/wrapper";
import CategoryBar from "../../../dashboard/widgetLibrary/categoryBar";
import SeverityDonut from "../../../dashboard/widgetLibrary/severityDonut";
import { JobSpecContainer } from "../../../jobs/JobSpecContainer";
import { Labels } from "./labels";
import SequenceGraphPopup from "./sequenceGraphPopup";

export interface IDataTile {
  title: string;
  ComponentToRender?: FC<any>;
  description?: string;
  hoverText?: string;
  onClick?: () => void;
  xs?: number;
  md?: number;
}

export const FindingDataTile: FC<IDataTile> = ({
  title,
  ComponentToRender,
  description,
  hoverText = undefined,
  onClick,
  xs,
  md,
}) => {
  return (
    <Grid item md={md} xs={xs} sx={{ pr: 3, pt: 1 }}>
      <Typography variant={"overline"} sx={{ fontSize: "12px", opacity: 0.6 }}>
        {title}
      </Typography>
      <Typography
        className={onClick || hoverText ? "hand" : ""}
        sx={{ width: "fit-content" }}
        variant={"h6"}
        onClick={onClick ? onClick : () => { }}
      >
        {onClick ? <ExternalLink fontSize="inherit" /> : null}
        {hoverText ? <Tooltip title={hoverText}><Box sx={{ width: "fit-content" }}>{description}</Box></Tooltip> : description}
        {ComponentToRender ? <ComponentToRender /> : null}
      </Typography>
    </Grid>
  );
};

export const ResultData: FC<any> = ({
  id,
  result,
  api,
  onFaultsGroupChange,
  selectedTab,
  setSelectedTab,
  Summary,
}: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDefinitionModal, setOpenDefinitionModal] = useState(false);
  const [resultDataTiles, setResultDataTiles] = useState([] as IDataTile[]);
  const [resultName, setResultName] = useState(result?.name);
  const [saveNameText, setSaveNameText] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (result) {
      setResultName(result?.name);
      const xs = 12;
      const md = 4;
      const data: IDataTile[] = [
        {
          title: "API",
          description: result.apiName,
          onClick: handleOpenDefinitionModal,
          xs,
          md,
        },
        {
          title: "Environment",
          description: result.environmentName,
          xs,
          md,
        },
        {
          title: "Updated At",
          description: moment(result?.updatedAt).format("DD MMM YYYY h:mm a"),
          xs,
          md,
        },
        {
          title: "Duration",
          description: `${moment
            .duration(result?.metrics?.duration, "milliseconds")
            .minutes()} m ${moment
              .duration(result?.metrics?.duration, "milliseconds")
              .seconds()} s`,
          xs,
          md,
        },
        {
          title: "Sequences",
          ComponentToRender: () => (
            <SequenceGraphPopup resultId={id} metrics={result?.metrics} />
          ),
          xs,
          md,
        },
        {
          title: "Requests",
          description: result?.metrics?.requestsCount,
          xs,
          md,
        },
        {
          title: "Issues",
          description: result?.metrics?.incidentsCount,
          xs,
          md,
        },
        {
          title: "New Issues",
          description: result?.metrics?.incidentsCountNew,
          xs,
          md,
        },
        {
          title: "Configuration",
          onClick: handleDialogOpen,
          xs,
          md,
        },
      ];
      setResultDataTiles(data);
    }
  }, [result]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleOpenDefinitionModal = () => {
    setOpenDefinitionModal(true);
  };

  const handleCloseDefinitionModal = () => {
    setOpenDefinitionModal(false);
  };

  const saveName = useCallback(
    debounce(async (name: string) => {
      try {
        await axiosInstance.patch(
          `${getConfigs().baseApiUrl}/results/${result.id}`,
          {
            checkpoint: name?.trim() !== "",
            name: name?.trim() || "",
          }
        );
      } catch (error) { }
    }, 500),
    [result]
  );

  const handleEndSaveNameText = useCallback(
    debounce(() => {
      setTimeout(() => {
        setSaveNameText("( Saved )");
        setTimeout(() => {
          setSaveNameText("");
        }, 4000);
      }, 1000);
    }, 500),
    []
  );

  const downloadPDF = async (id: string) => {
    setIsDownloading(true);
    try {
      const element = document.createElement("a");
      const response = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/reports?resultId=${id}`,
        {
          responseType: "blob",
          timeout: 60000,
        }
      );
      const blob = response?.data;
      const file = new Blob([blob], {
        type: "application/octet-stream",
      });
      element.href = URL.createObjectURL(file);
      element.download = `run_${id}.pdf`;
      document.body.appendChild(element);
      element.click();
    } catch (error: any) {
      console.log(error);
    }
    setIsDownloading(false);
  };

  useEffect(() => {
    if (saveNameText === "( Saving... )") {
      handleEndSaveNameText();
    }
  }, [saveNameText, handleEndSaveNameText]);

  return (
    <>
      <Dialog maxWidth="lg" onClose={handleDialogClose} open={dialogOpen}>
        <Box sx={{ m: 3 }}>
          <JobSpecContainer id={id} />
        </Box>
      </Dialog>

      <Box
        sx={{ px: 2, mt: 1, display: "flex", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Box> <Typography variant="overline"
            sx={{ fontSize: "12px", display: "block" }}
            color="text.secondary">
            Run #{result && result?.id ? result?.id?.split("-")[0] : null}{" "}
          </Typography>
            <TextField
              label={`Name ${saveNameText}`}
              size="small"
              value={resultName || ""}
              helperText="Naming a run turns it into a checkpoint"
              onChange={({ target }) => {
                setResultName(target.value);
                setSaveNameText("( Saving... )");
                saveName(target.value);
              }}
            ></TextField>
          </Box>

          <Box sx={{ pr: 3 }}>

            <Box sx={{ display: "flex", justifyContent: "end", pb: 2 }}>
              <Box className="hand" sx={{ display: "inline", mx: 2 }} onClick={() => window.open(`/projects/${result?.applicationId}`)}>

                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px" }}
                  color="text.secondary"
                >
                  Project
                </Typography>
                <br />
                <Box sx={{ display: "flex" }}>  <ExternalLink fontSize="inherit" /> <Typography variant="h6">{result?.applicationName}</Typography></Box>
              </Box>
              <Box sx={{ display: "inline", mx: 2 }}>
                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px" }}
                  color="text.secondary"
                >
                  TYPE
                </Typography>
                <br />
                <Typography variant="h6">{result?.toolInfo?.type}</Typography>
              </Box>
              <Box sx={{ display: "inline", mx: 2 }}>
                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px" }}
                  color="text.secondary"
                >
                  TOOL
                </Typography>
                <br />
                <Typography variant="h6">{result?.toolInfo?.name}</Typography>
              </Box>
              <Box sx={{ display: "inline", mx: 2 }}>
                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px" }}
                  color="text.secondary"
                >
                  {result.assetType}
                </Typography>
                <br />
                <Typography variant="h6">{result.apiName}</Typography>
              </Box>
              <Box sx={{ display: "inline", mr: 2 }}>
                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px" }}
                  color="text.secondary"
                >
                  Environment
                </Typography>
                <br />
                <Typography variant="h6">{result.environmentName}</Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Box sx={{ display: "inline", mr: 2 }}>
                <Chip
                  size="small"
                  color={result?.state === "completed" ? "success" : "warning"}
                  label={result?.state}
                />
              </Box>
              <Box sx={{ display: "inline", mr: 1 }}>
                <Typography variant="body2">
                  at {moment(result?.updatedAt).format("h:mm A on DD MMM YYYY")}
                  &nbsp;
                </Typography>
              </Box>
              <Box sx={{ display: "inline", mr: 1 }}>
                <Typography variant="body2">
                  in{" "}
                  {moment
                    .duration(result?.metrics?.duration, "milliseconds")
                    .minutes()}
                  m&nbsp;
                  {moment
                    .duration(result?.metrics?.duration, "milliseconds")
                    .seconds()}
                  s
                </Typography>
              </Box>
              <Box sx={{ display: "inline", mr: 2, pt: 1 }}></Box>
              <Box sx={{ pt: 1, display: "inline" }}>
                {result?.state !== "completed" ? (
                  <Refresh
                    sx={{ pl: 1, position: "relative", top: "0.25em" }}
                    className="hand"
                    fontSize="small"
                    onClick={() => window.location.reload()}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>


      </Box>
      <ResultProgress result={result} />

      <Box sx={{ mt: 1, display: "flex", justifyContent: api?.assetType === "api" ? "space-between" : "end" }}>
        {api?.assetType === "api" &&
          <Tabs
            sx={{
              backgroundColor: "background.default",
              width: "fit-content",
              height: "min-content",
              minHeight: 0,
              borderRadius: "5px",
              // my: 1,
              mx: 2,
              pr: 1,
              pl: 2,
              ".MuiTabs-indicator": {
                display: "none",
              },
            }}
            value={selectedTab}
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
          >
            {["Faults", "Performance"]?.map((category: any) => {
              return (
                <Tab
                  sx={{
                    fontWeight: 600,
                    height: "min-content",

                    mr: 1,
                    lineHeight: 0,
                    px: 2,
                    py: 2,
                    minHeight: 0,
                    "&.Mui-selected": {
                      backgroundColor: "primary.main",
                      color: "background.paper",
                      borderRadius: "5px",
                      border: 0,
                    },
                  }}
                  label={category}
                  value={category}
                />
              );
            })}
          </Tabs>
        }

        <Box sx={{ display: "flex", justifyContent: "end", pr: 4 }}>

          {result?.metrics?.incidentsCountNew > 0 && <Chip color="error" sx={{ mr: 1 }} variant={parseInt(result?.metrics?.incidentsCountNew) ? "filled" : "outlined"} label={result?.metrics?.incidentsCountNew + " New"} />}
          {api?.assetType === "api" && (
            <SequenceGraphPopup resultId={id} metrics={result?.metrics} />
          )}
          <Box sx={{ ml: 1 }}>
            <Labels labels={result?.labels} color="default" />
          </Box>
          {Summary && <Summary />}
          {(api?.assetType === "api") && <>

            <Tooltip title="Configuration"><IconButton sx={{ py: 0.5, height: "fit-content" }} onClick={handleDialogOpen}><DocumentText sx={{ mr: 1 }} /></IconButton></Tooltip>


          </>}

          <Tooltip title={isDownloading ? "..." : "PDF report"}><IconButton sx={{ py: 0.5, height: "fit-content" }} onClick={() => !isDownloading && downloadPDF(id)}><PictureAsPdfIcon sx={{ mr: 1 }} /></IconButton></Tooltip>

          <RunHistoryPopup sx={{ width: "100%" }} apiId={result.apiId} toolInfo={result?.toolInfo} environmentId={result?.environmentId} />
          {api?.assetType === "api" && <ModalWrapper maxWidth="lg" title="Settings" trigger={<Tooltip title="Settings"><IconButton sx={{ py: 0.5, height: "fit-content" }}  ><Settings /></IconButton></Tooltip>} child={<ApiModalContent apiId={api?.id} />} />}


        </Box>
      </Box>

      <Box
        sx={{
          px: 2,

          // display: "flex",
          // justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-evenly", ml: 2 }}>
          {!isEmpty(result?.metrics) && selectedTab === "Faults" ? <Grid container spacing={2} sx={{ width: "100%", mt: 1, mr: 2, border: "2px solid #5148e626", backgroundColor: "#5048e508", borderRadius: "0.35em", p: 3 }}>
            <CategoryBar runId={result?.id} height="200px" maxDisplayCount={4} disableClick />

            <SeverityDonut runId={result?.id} height="200px" disableClick />
          </Grid> : null}

        </Box>

      </Box >





    </>
  );
};

const ResultProgress: FC<any> = ({ result }) => {
  return result.state && result?.state === "running" ? (
    <>
      <Alert severity="info" sx={{ m: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          This run is currently in progress. We've already{" "}
          <b>found {result?.metrics?.incidentsCount} issues</b>. It'll take
          a few more moments to complete.
        </Typography>
        <LinearProgress />
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </Button>
      </Alert>
    </>
  ) : <></>
};
