import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid, TextField,
  Typography
} from "@mui/material";
import { filter, find, isEmpty } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, HELPER_TEXTS, STRINGS } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
import { IAppState, useAppState } from "../../../store/appState";
var format = require("string-template");

const types = [
  { name: "OpenAPI Spec", id: "API_KIND_OPENAPI" },
  { name: "GraphQL", id: "API_KIND_GRAPHQL" },
  // { name: "GRPC", id: "API_KIND_GRPC" },
];

interface IError {
  error: boolean;
  message: string;
}

interface IAddApiFormError {
  application?: IError;
  api?: IError;
}

interface IAddApiFormValues {
  group: any;
  application: any;
  api: string;
  type: string;
}

interface IApplication {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean;
  defaultFormValues?: any;
  groupId?: string;
  defaultAllowEdit?: boolean;
  preventRedirect?: boolean;
  callback?: (data: any) => void;
  updateUUID?: string;
}

const AddApiForm: FC<IAddForm> = ({
  history,
  match,
  updateOnly = false,
  groupId = undefined,
  defaultFormValues,
  defaultAllowEdit = false,
  preventRedirect = false,
  callback = undefined,
  updateUUID,
}) => {
  const clearFormValues = { group: null, application: null, api: "", type: "" };
  const { context } = useAppState((state) => state) as IAppState;
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const query = new URLSearchParams(history.location.search);
  const groupIdByQuery = useMemo(() => query.get("groupId") || groupId, [query, groupId]);

  const [formValues, setFormValues] = useState({
    ...clearFormValues,
    ...defaultFormValues,
  } as IAddApiFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    application: {},
    api: {},
  } as IAddApiFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);

  const [allowEdit, setAllowEdit] = useState(defaultAllowEdit);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const [clickToCreateProject, setClickToCreateProject] = useState("");
  const [clickToCreateGroup, setClickToCreateGroup] = useState("");
  const { data: groupsData, fetchData: fetchGroups } = useFetch("/group");
  const groups = useMemo(() => {
    const availableGroups = groupsData?.groups || [];

    return availableGroups;
  }, [groupsData]);
  const {
    data: applicationsData,
    loading: isFetchingApplications,
    fetchData: fetchApplications,
  } = useFetch(formValues.group ? format(API_SLUGS.APPLICATIONS_BY_GROUP, {
    groupId: formValues.group.id

  }) : "");

  const applications = useMemo(() => {
    return applicationsData?.applications || [];
  }, [applicationsData]);

  useEffect(() => {
    let newDefaultFormValues = { ...formValues };
    if (!updateOnly) {
      if (context.application) {
        newDefaultFormValues.application = context.application;
      }

      setFormValues(newDefaultFormValues);
    }
  }, []);


  useEffect(() => {
    if (groupIdByQuery) {
      const group = groups.find((group: any) => group.id === groupIdByQuery);
      if (group) {
        setFormValues({ ...formValues, group })
      }
    }
  }, [groupIdByQuery, groups])

  const handleChange = async (
    value: any,
    valueType: "group" | "application" | "api" | "type"
  ) => {
    let newValues = { ...formValues, [valueType]: value };

    if (valueType === "group") {
      newValues.application = null
    }

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (updateOnly) {



      if (formValues.application && formValues.api.trim() !== "") {
        await updateApi();
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }

    } else {



      if (formValues.application && formValues.api.trim() !== "") {
        await addApi();
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }

    }
  };

  const handleDelete = async () => {

    await axiosInstance.delete(
      format(API_SLUGS.UPDATE_API, { id: updateUUID })
    );
    if (!preventRedirect) {
      history.push(`/projects`);
    }
    setMessage({ title: "Api has been deleted", type: "success" });


    if (callback) {
      callback({});
    }
  };

  const addApplication = async (name: string, reroute: boolean = false) => {
    try {
      let applicationResponse = await axiosInstance.post(
        API_SLUGS.ADD_APPLICATION,
        { name: name, groupId: formValues.group.id }
      );
      await fetchApplications();
      if (reroute && !preventRedirect) {
        history.push(`/projects`);
        setMessage({ title: "Project has been added", type: "success" });
      }

      handleChange(applicationResponse.data, "application");
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  const addGroup = async (name: string, reroute: boolean = false) => {
    try {
      let groupResponse = await axiosInstance.post(API_SLUGS.ADD_GROUP, {
        name: name,
      });
      await fetchGroups();

      handleChange(groupResponse.data, "group");
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  const addApi = async () => {
    setIsSubmitting(true);
    try {
      let apiResponse = await axiosInstance.post(API_SLUGS.ADD_ASSET, {
        applicationId: formValues.application.id,
        name: formValues.api,
        type: formValues.type,
        assetType: "api",
      });
      if (!preventRedirect) {
        history.push(`/projects`);
      }
      setMessage({ title: "Api has been added", type: "success" });
      if (callback) {
        callback(apiResponse?.data);
      }
      clearForm();
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
    setIsSubmitting(false);
  };


  const updateApi = async () => {
    setIsSubmitting(true);
    try {
      let data: any = {
        applicationUUID: formValues.application.id,
        name: formValues.api,
        type: formValues.type,
      };

      await axiosInstance.patch(
        format(API_SLUGS.UPDATE_API, { id: updateUUID }),
        data
      );
      window.location.reload();
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
    setIsSubmitting(false);
  };

  const clearForm = () => {
    setFormValues(clearFormValues);
  };



  return (
    <Box sx={{ textAlign: "left" }}>
      {!allowEdit &&
        updateOnly &&
        !showDeleteForm ? (
        <Box>
          <Typography variant="overline" color="neutral.400">

            <span className="hand" onClick={() => history.push(`/projects`)}>
              {formValues.application.name}
            </span>

          </Typography>
          <Typography variant="h4">
            {formValues.api}{" "}
            <ButtonGroup sx={{ ml: 2 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAllowEdit(true)}
              >
                Edit
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setShowDeleteForm(true)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Typography>
        </Box>
      ) : showDeleteForm ? (
        [
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1, textAlign: "left" }}
                variant="h6"
              >
                Enter api name to
                delete the api
              </Typography>

              <TextField
                autoComplete="off"
                sx={{ maxWidth: "50%", textAlign: "left" }}
                size="medium"
                fullWidth
                name="Api"

                placeholder={formValues.api}
                onChange={(e: any) => {
                  setAllowDelete(e.target.value === formValues.api);
                }}
                required
                variant="outlined"
              />

            </Grid>
            <Grid item xs={12}>
              <Button
                color="error"
                size="medium"
                variant="contained"
                disabled={!allowDelete}
                onClick={handleDelete}
                sx={{ mr: 1 }}
              >
                Delete
              </Button>
              <Button
                color="primary"
                size="medium"
                variant="outlined"
                onClick={() => setShowDeleteForm(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>,
        ]
      ) : (
        [
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1, textAlign: "left" }}
                variant="h6"
              >
                Group
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Autocomplete
                  sx={{ maxWidth: "50%", textAlign: "left" }}
                  size="medium"
                  disabled={updateOnly}
                  getOptionLabel={(option): any => option?.name}
                  options={groups}
                  value={formValues.group}
                  disableClearable

                  onChange={(e, value) => {
                    if (!isEmpty(value) && "id" in value) {
                      handleChange(value, "group");

                    }
                  }}
                  clearOnBlur
                  filterOptions={(options, params) => {
                    const filtered = filter(options, function (item) {
                      return item.name.indexOf(params.inputValue) > -1;
                    });
                    if (params.inputValue !== "" && filtered.length === 0) {
                      setClickToCreateGroup(params.inputValue);
                    } else {
                      setClickToCreateGroup("");
                    }

                    return filtered;
                  }}
                  renderInput={(params): JSX.Element => {
                    return (
                      <TextField
                        autoComplete="off"
                        name="group"
                        helperText={HELPER_TEXTS?.add_application?.group}
                        variant="outlined"
                        {...params}
                      />
                    );
                  }}
                />
                {clickToCreateGroup?.trim() !== "" && (
                  <Button
                    size="small"
                    sx={{
                      height: "fit-content",
                      ml: 1,
                      mt: 1.5,
                      mb: 1.5,
                      p: 1,
                    }}
                    onClick={async () => {
                      await addGroup(clickToCreateGroup);
                      setClickToCreateGroup("");
                    }}
                  >
                    Create Group "{clickToCreateGroup}"
                  </Button>
                )}
              </Box>

              <Typography
                color="textPrimary"
                sx={{ my: 1, textAlign: "left" }}
                variant="h6"
              >
                Project
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Autocomplete
                  sx={{ maxWidth: "50%", textAlign: "left" }}
                  size="medium"
                  disabled={updateOnly}
                  getOptionLabel={(option): any => option?.name}
                  options={applications}
                  value={formValues.application}
                  onChange={(e, value) => {
                    if (!isEmpty(value) && "id" in value) {
                      handleChange(value, "application");
                    }
                  }}
                  clearOnBlur
                  filterOptions={(options, params) => {
                    const filtered = filter(options, function (item) {
                      return item.name.indexOf(params.inputValue) > -1;
                    });
                    if (params.inputValue !== "" && filtered.length === 0) {
                      setClickToCreateProject(params.inputValue);
                    } else {
                      setClickToCreateProject("");
                    }

                    return filtered;
                  }}
                  renderInput={(params): JSX.Element => {
                    return (
                      <TextField
                        autoComplete="off"
                        name="application"
                        helperText={HELPER_TEXTS?.add_api?.application}
                        variant="outlined"
                        {...params}
                      />
                    );
                  }}
                />
                {clickToCreateProject?.trim() !== "" && (
                  <Alert severity="info" sx={{ width: "fit-content", ml: 2, height: "fit-content" }}>
                    <Box>
                      <Typography variant="h6" sx={{ display: "inline" }}>
                        Project named "{clickToCreateProject}" doesn't exist.
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ my: 0, mx: 1 }}
                        onClick={async () => {
                          await addApplication(clickToCreateProject);
                          setClickToCreateProject("");
                        }}
                      >
                        Create Now
                      </Button>
                    </Box>
                  </Alert>
                )}
              </Box>

            </Grid>


            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1, textAlign: "left" }}
                variant="h6"
              >
                API
              </Typography>
              <TextField
                autoComplete="off"
                sx={{ maxWidth: "50%", textAlign: "left" }}
                size="medium"
                fullWidth
                name="Api"
                helperText={HELPER_TEXTS?.add_api?.api}
                defaultValue={formValues.api}
                onChange={(e: any) => {
                  handleChange(e.target.value, "api");
                }}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1, textAlign: "left" }}
                variant="h6"
              >
                Type
              </Typography>
              <Autocomplete
                getOptionLabel={(option): any => option?.name}
                options={types}
                disabled={updateOnly}
                value={find(types, { id: formValues.type })}
                sx={{ maxWidth: "50%", textAlign: "left" }}
                size="medium"
                onChange={(e, value) => {
                  handleChange(value?.id, "type");
                }}
                renderInput={(params): JSX.Element => {
                  return (
                    <TextField
                      autoComplete="off"
                      name="type"
                      helperText={HELPER_TEXTS?.add_api?.type}
                      variant="outlined"
                      {...params}
                    />
                  );
                }}
              />
            </Grid>


          </Grid>,

          errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
            <Box sx={{ mt: 2 }}>
              <Alert severity="error" sx={{ width: "fit-content" }}>
                <div>{errorMessage}</div>
              </Alert>
            </Box>
          ) : null,

          <Box
            sx={{
              display: "flex",
              mt: 3,
            }}
          >
            {successMessage && successMessage.trim() ? null : (
              <Button
                color="primary"
                size="medium"
                variant="contained"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
            {updateOnly ? (
              <Button
                color="primary"
                size="medium"
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => {
                  setAllowEdit(false);
                }}
              >
                Cancel
              </Button>
            ) : null}
          </Box>,
        ]
      )}



    </Box>
  );
};

export default withRouter(AddApiForm);
