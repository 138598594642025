import {
    Box, Card,
    CardContent,
    Container
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { API_SLUGS } from '../../../constants';
import { usePageContext } from '../../../hooks/process/usePageContext';
import { axiosInstance } from '../../../services/axios';
import RevisionsTable from '../Revisions/table';
import AddApiForm from './AddApiForm';

var format = require("string-template")

const UpdateApi: React.FC<RouteComponentProps> = ({ history, match }) => {
    const [api, setApi] = useState({} as any)
    const { selectedGroup } = usePageContext();
    useEffect(() => {
        fetchApi()
    }, [])

    const fetchApi = async () => {
        try {
            let apiResponse = await axiosInstance.get(format(API_SLUGS.GET_API, {
                //@ts-ignore
                uuid: match.params.uuid
            }))
            setApi(apiResponse.data)
        } catch (error: any) {
        }
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                {!isEmpty(api) ? <AddApiForm defaultFormValues={{ application: { name: api.applicationName, uuid: api.applicationId }, api: api.name, type: api.type }} updateOnly updateUUID={api.id} groupId={selectedGroup?.id} /> : null}
                                {!isEmpty(api) ? <RevisionsTable apiId={api?.id} /> : null}
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(UpdateApi);
