

import { find, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API_SLUGS } from "../../constants";
import useFetch from "../http/useFetch";
const format = require("string-template");


export const usePageContext = () => {
    const { data: groups, loading: groupsLoading } = useFetch("/group");
    const [groupId, setGroupId] = useState<string | null>(null);
    const projectUrl = useMemo(() => groupId? format(API_SLUGS.APPLICATIONS_BY_GROUP, {groupId: groupId}): undefined, [groupId]);
    const [projectId, setProjectId] = useState<string | null>(null);
    const {data: projects, loading: projectsLoading } = useFetch(projectUrl)
    const selectedGroup = useMemo(() => find(groups?.groups, {id: groupId}), [groups, groupId]);
    const selectedProject = useMemo(() => find(projects?.applications, {id: projectId}), [projects, projectId]);

    const changeGroup = useCallback((id?: string)=> {
        let newGroupId = ""
        if(id) { 
            newGroupId = ""
        } else {
            const localStoragedGroupId = localStorage.getItem("groupId");
            
            if(localStoragedGroupId) {
                newGroupId = localStoragedGroupId
            }       
        } 
        let group = find(groups?.groups, {id: newGroupId})
        if(group){
            setGroupId(newGroupId)
        }else{
            setGroupId(groups?.groups?.[0]?.id)
        }
    }, [groups]);



    const changeProject = useCallback((id?: string)=> {
        if(id===null){
            localStorage.removeItem("projectId")
            setProjectId(null)
            return
        }
        let newProjectId = ""
        if(id) {
            
            newProjectId = id
        } else {
            const localStoragedProjectId = localStorage.getItem("projectId");
            if(localStoragedProjectId) {
                
                newProjectId=localStoragedProjectId
            } 
        }
        let project = find(projects?.applications, {id: newProjectId})
        if(project){
            setProjectId(newProjectId)
        // }else{
        //    setProjectId(projects?.applications?.[0]?.id)
        }

    }, [projects]);

    useEffect(() => {
        
        if(groupId) {
            
            localStorage.setItem("groupId", groupId || "");
        }
    }, [groupId]);

    useEffect(() => {
        if(projectId) {
            localStorage.setItem("projectId", projectId || "");
        }
    }, [projectId]);
    

    useEffect(() => {
        if(!isEmpty(projects?.applications)) {
            changeProject();
        }
    }, [projects, changeProject, groupId]);
    

    useEffect(() => {
        if(!isEmpty(groups?.groups)) {
            changeGroup();
        }
    }, [groups, changeGroup]);
    
    
    return {selectedGroup,
    selectedProject,
        groupsLoading,
        projectsLoading,

        
         groups: groups?.groups|| [], projects: projects?.applications|| [], changeGroup, changeProject};
};
