import { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { axiosInstance } from "../../services/axios";
import { API_SLUGS } from "../../constants";
import { isEmpty } from "lodash";
import { Close } from "@mui/icons-material";
import { RouteComponentProps, withRouter } from "react-router-dom";
var format = require("string-template");

interface IMessageModal extends RouteComponentProps {
  selectedMessage: string;
  onClose: () => void;
}

interface IMessageDetails {
  uuid: string;
  title: string;
  content: string;
  createdAt: string;
  type: string;
  actionPayload: any;
}

const MessageModal: FC<IMessageModal> = ({
  selectedMessage,
  onClose,
  history,
}) => {
  const [message, setMessage] = useState({} as IMessageDetails);
  const [isFetchingMessage, setIsFetchingMessage] = useState(false);

  useEffect(() => {
    fetchMessage();
  }, []);

  const markMessageRead = async () => {
   try {
    await axiosInstance.patch(
      format(API_SLUGS.MARK_MESSAGE, { uuid: selectedMessage }),
      { status: "read" }
    );
   } catch (error) {
     
   }
  };

  const fetchMessage = async () => {
    setIsFetchingMessage(true);
    try {
      let messagesResponse = await axiosInstance.get(
        format(API_SLUGS.MESSAGE, { uuid: selectedMessage })
      );
      setMessage(messagesResponse.data);
      markMessageRead();
    } catch (error: any) {}
    setIsFetchingMessage(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={12}>
          <ClickAwayListener onClickAway={onClose}>
            <Box>
              {isFetchingMessage ? (
                <Box sx={{ p: 3 }}>Loading...</Box>
              ) : !isEmpty(message) ? (
                <>
                  <Box
                    sx={{
                      pt: 3,
                      pl: 3,
                    }}
                  >
                    <Close className="hand" onClick={onClose} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      pb: 4,
                      pt: 3,
                      px: 3,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        color: "common.blue",
                        mr: 2,
                      }}
                    >
                      {message?.title[0]}
                    </Avatar>
                    <Box>
                      <Typography color="textPrimary" variant="h5">
                        {message?.title}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        sx={{ mt: 1 }}
                        variant="body2"
                      >
                        {message?.content}
                      </Typography>
                      {message?.type === "incident" ? (
                        <Chip
                          size="small"
                          sx={{ mt: 2 }}
                          variant="filled"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/findings?incidentId=${message?.actionPayload?.id}`
                            )
                          }
                          label="Open Issue"
                        />
                      ) : null}
                      {message?.type === "result" ? (
                        <Chip
                          size="small"
                          sx={{ mt: 2 }}
                          variant="filled"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/findings?resultId=${message?.actionPayload?.id}`
                            )
                          }
                          label="Open Result"
                        />
                      ) : null}
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </ClickAwayListener>
        </Paper>
      </Container>
    </Box>
  );
};

export default withRouter(MessageModal);
