import {
    Box, Container, Typography
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IncidentsTable from './IncidentsTable';






const Analyzers: React.FC<RouteComponentProps> = ({ history, match }) => {

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.paper',

                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 1 }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',

                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Issues
                            </Typography>
                        </div>


                    </Box>



                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >


                        <IncidentsTable />
                    </Box>

                </Container>
            </Box>
        </>
    );
};


export default withRouter(Analyzers);
