import create from "zustand";
import { devtools } from "zustand/middleware";
import { STRINGS } from "../constants";


export interface IAppState {
    globalError: string | null
    setGlobalError: (text: string) => void
  
    context: any
    setContext: (data: any) => void
    clearGlobalError: () => void
}

export const useAppState = create(devtools(set => ({
    globalError: null,
    setGlobalError: (text: string) => {
        set((state: IAppState) => {
            return { ...state, globalError: text }
        })
    },
   
    context: { application: null, service: null, api: null },
    setContext: (data: any) => {
        set((state: IAppState) => {
            let newContext = { ...state.context }
            if (data?.application) {
                newContext.service = null
                newContext.api = null
            }
            if (data?.service) {
                newContext.api = null
            }
            return { ...state, context: { ...newContext, ...data } }
        })
    },
    clearGlobalError: () => {
        set((state: IAppState) => {
            return { ...state, globalError: null }
        })
    },

} as IAppState)))