import { Download, PlayCircle, Settings, Terminal } from "@mui/icons-material";
import {
  Box, Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { capitalize, find, isEmpty, sortBy, upperCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { API_SLUGS } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { ChartSquareBar } from "../../../icons/chart-square-bar";
import { ExclamationCircle } from "../../../icons/exclamation-circle";
import { axiosInstance } from "../../../services/axios";
import { ApiModalContent } from "../../applications/ApiModal";
import ModalWrapper from "../../common/modal/wrapper";
import ConfigTablePopup from "../configTablePopup";
import DownloadPostmanPopup from "../downloadPostmanPopup";
import Loaders from "../Loaders";
import ScannersPopup from "../scannersPopup";

var format = require("string-template");



const ApiEnvironmentInsights: React.FC<any> = ({
  history,
  applicationId,
  project = undefined,

}) => {
  const [showHidden, setShowHidden] = useState(true);
  const {
    data: apisData,
    loading: isFetchingApis,
    error,
  } = useFetch(format(API_SLUGS.APIS, { applicationId: applicationId }));

  const [environments, setEnvironments] = useState([] as any);
  const [isFetchingEnvironments, setIsFetchingEnvironments] = useState(true);
  const [expandEnvironments, setExpandEnvironments] = useState(false);

  useEffect(() => {
    fetchEnvironments();
  }, []);

  const fetchEnvironments = async () => {
    try {

      let response = await axiosInstance.get(
        `/applications/${applicationId}/environments`
      );
      let sortedEnvironments = sortBy(response?.data?.environments, "ranAt").reverse();
      // Without this all assets won't be visible if no run has been initiated
      if (isEmpty(sortedEnvironments)) {
        sortedEnvironments = [{
          id: "default",
          name: "default",
          ranAt: new Date()
        }]
      }
      setEnvironments(sortedEnvironments);
    } catch (error: any) { }
    setIsFetchingEnvironments(false);
  };



  return (
    <Grid item container md={12} xs={12} sx={{ mb: 1, mt: 2, textAlign: "left" }}>
      {
        environments?.map((environment: any, index: any) => (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="overline" sx={{ m: 2 }}>

                {project?.name}
              </Typography>
              <Tooltip title="Environment">
                <Typography variant="h6" sx={{ width: "fit-content", m: 2, mt: 0, display: "flex", justifyContent: "start" }}>

                  <Terminal fontSize="small" /> {environment?.name}
                </Typography>
              </Tooltip>
            </Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {apisData?.apis?.map(
                (api: any, index: any) =>




                  <ApiSection
                    api={api}
                    project={project}
                    environment={environment}

                    showHidden={showHidden}
                    history={history}
                  />



              )}
            </Grid>

          </Grid>
        ))
      }


    </Grid>
  );
};

const ApiSection: React.FC<any> = ({
  api,
  project,

  showHidden,
  environment,
  history,
}) => {

  return (


    <EnvironmentCard
      apiId={api.id}
      assetType={api.assetType}
      env={environment}
      history={history}
      insightsFallback={() => <Box sx={{ display: "flex", justifyContent: "space-between" }}><Typography variant="h6" sx={{ mt: 2, textAlign: "left", opacity: 0.7 }}>No insights are available. Run an analysis.</Typography>
        {api?.assetType === 'repository'
          ? <ScannersPopup apiId={api?.id} environmentId={environment?.id === 'default' ? '' : environment?.id} />
          : <ConfigTablePopup apiId={api?.id} defaultProjectID={project?.id} />
        }
      </Box>
      }
      headerToRender={() =>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

          <Box sx={{ display: "flex", justifyContent: "start" }}>

            <Typography variant="h6" sx={{ mt: 1, pr: 2, minWidth: "fit-content" }}>{api.name}</Typography>

          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>

            {api.assetType === "api" && <DownloadPostmanPopup apiId={api?.id} trigger={<Tooltip title="Download Postman"><IconButton><Download /></IconButton></Tooltip>} />}
            <Tooltip title="All Issues">
              <IconButton

                sx={{ background: "white" }}
                onClick={() =>
                  history.push(
                    `/api/${api?.id}/details?environmentId=${environment?.id}`
                  )
                }
              >
                <ExclamationCircle />
              </IconButton>
            </Tooltip>
            {api?.assetType === 'repository'
              ? <ScannersPopup apiId={api?.id} environmentId={environment?.id === 'default' ? '' : environment?.id} trigger={<Tooltip title="Analyze"><IconButton ><PlayCircle /></IconButton></Tooltip>} />
              : <ConfigTablePopup apiId={api?.id} defaultProjectID={project?.id} trigger={<Tooltip title="Analyze"><IconButton ><PlayCircle /></IconButton></Tooltip>} />
            }

            <ModalWrapper maxWidth="lg" title="" trigger={<Tooltip title="Settings"><IconButton ><Settings /></IconButton></Tooltip>} child={<ApiModalContent apiId={api?.id} />} />
          </Box>
        </Box>


      }
    />


  )

};

const EnvironmentCard: React.FC<any> = ({
  apiId,
  assetType,
  env,
  history,
  headerToRender,
  insightsFallback
}) => {

  const [insightsPerAsset, setInsightsPerAsset] = useState<any>({});
  const [isFetchingInsights, setIsFetchingInsights] = useState(true);
  const availableInsights = useMemo(() => {

    for (let key in insightsPerAsset) {
      if (insightsPerAsset[key]) {
        return true
      }
    }
    return false
  }
    , [insightsPerAsset])


  useEffect(() => {
    fetchInsightsPerAsset();
  }, []);




  const fetchInsights = async (type: any) => {
    if (env?.id === "default") {
      return
    }
    try {
      let apiResponse = await axiosInstance.get(
        format(
          "/insights/incidents?apiId={apiId}&type=risk&environmentId={environmentId}&findingType={type}",
          {
            apiId: apiId,
            environmentId: env?.id,
            type: type.key
          }
        )
      );
      if (!apiResponse?.data?.byRisk?.length) {
        return
      }
      const critical = find(apiResponse?.data?.byRisk, { risk: "critical" })?.open;
      const high = find(apiResponse?.data?.byRisk, { risk: "high" })?.open;
      const medium = find(apiResponse?.data?.byRisk, { risk: "medium" })?.open;
      const low = find(apiResponse?.data?.byRisk, { risk: "low" })?.open;
      const information = find(apiResponse?.data?.byRisk, { risk: "information" })?.open;
      return {
        critical: critical,
        high: high,
        medium: medium,
        low: low,
        information: information,
        total: critical + high + medium + low + information,
        latestResultId: apiResponse?.data?.latestResultId
      }
    } catch (error: any) { }
  };

  const fetchInsightsPerAsset = async () => {
    try {
      let newInsightsPerAsset = { ...insightsPerAsset }
      for (let type of findingTypesByAssetType[assetType]) {
        const insights = await fetchInsights(type)

        if (insights) {
          newInsightsPerAsset[type.key] = insights
        }
      }


      setInsightsPerAsset(newInsightsPerAsset)

    } catch (error) { }
    setIsFetchingInsights(false)
  };
  return (

    <Grid item xs={12} md={6} lg={4}>
      <Card elevation={12} sx={{ width: "100%", height: "100%" }}>
        <CardContent>
          {headerToRender()}

          <Divider />
          {isFetchingInsights && <Loaders />}
          {!availableInsights && !isFetchingInsights &&
            insightsFallback()
          }

          {findingTypesByAssetType[assetType]?.map((type: any, index: number) => {

            if (!insightsPerAsset[type.key]) {
              return null
            }
            return <InsightsCard headerToRender={headerToRender} history={history} apiId={apiId} environment={env} type={type} assetType={assetType} insights={
              insightsPerAsset[type.key]
            } />

          })}
        </CardContent>
      </Card>
    </Grid>







  );
};

const InsightsCard: React.FC<any> = ({ history, apiId, environment, type, assetType, insights }) => {

  const insightsToRender = useMemo(() => {
    //only top two severity insights are shown
    let insightsToRender = [];


    insightsToRender.push({
      total: insights?.critical || 0,
      severity: "critical",
    });

    insightsToRender.push({
      total: insights?.high || 0,
      severity: "high",
    });



    insightsToRender.push({
      total: insights?.medium || 0,
      severity: "medium",
    });




    insightsToRender.push({
      total: insights?.low || 0,
      severity: "low",
    });

    insightsToRender.push({
      total: insights?.information || 0,
      severity: "information"
    })


    return insightsToRender
  }

    , [insights]);




  if (isEmpty(insights)) {
    return <></>
  }

  return (

    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

      <Chip
        label={upperCase(type?.key)}
        size="small"
        sx={{ display: "inline", mt: 1.25, pt: 0.25 }}
      />



      <Box sx={{ display: "flex", justifyContent: "end" }}>

        <Box sx={{ mt: 1, pl: 1 }}>
          {
            insightsToRender.map((insight: any, index: number) => {
              return <Tooltip title={capitalize(insight?.severity)}>
                <Chip
                  label={insight?.total}
                  size="small"
                  variant={insight?.total ? "filled" : "outlined"}
                  //@ts-ignore
                  color={insight?.severity === "critical" ? "critical" : insight?.severity === "high" ? "error" : insight?.severity === "medium" ? "warning" : insight?.severity === "low" ? "info" : "information"}
                  sx={{ display: "inline", mr: 0.5, minWidth: "5em" }}
                />
              </Tooltip>
            })
          }

        </Box>
        <Tooltip title="Latest Run">
          <IconButton
            sx={{ background: "white" }}
            onClick={() =>
              history.push(`/findings?resultId=${insights?.latestResultId || environment?.latestResultId}&findingType=${type.key}`)
            }
          >
            <ChartSquareBar />
          </IconButton>
        </Tooltip>

      </Box>


    </Box>
    // {!isEmpty(environment?.faultGroupInsights) && (
    //   <Box>
    //     <Typography variant="overline" color="neutral.400">
    //       Fault Groups
    //     </Typography>
    //     <Box>
    //       <Tooltip
    //         title={Object.keys(environment?.faultGroupInsights).join(
    //           ", "
    //         )}
    //       >
    //         <Chip
    //           size="small"
    //           variant="outlined"
    //           color="error"
    //           label={`${Object.keys(environment?.faultGroupInsights)[0]
    //             } ${Object.keys(environment?.faultGroupInsights).length > 1
    //               ? `+${Object.keys(environment?.faultGroupInsights)
    //                 .length - 1
    //               }`
    //               : ""
    //             }`}
    //         />
    //       </Tooltip>
    //     </Box>
    //   </Box>
    // )}



  );
};

const findingTypesByAssetType: any = {
  "api": [{
    key: "api"
  }],
  "repository": [{
    key: "secret"
  },
  {
    key: "dependency"
  },
  {
    key: "sast"
  },
  {
    key: "container"
  },
  {
    key: "license"
  },
  {
    key: "iac"
  },
  {
    key: "k8s"
  },
  {
    key: "aws"
  }]
}

export default withRouter(ApiEnvironmentInsights);
