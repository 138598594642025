import { FC, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, STRINGS, ROUTES } from "../../../constants";
import { axiosInstance } from "../../../services/axios";
var format = require("string-template");

const AddRoleForm: FC<RouteComponentProps> = ({ history, match }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    permissions: {
      object: { permissions: [] as string[] },
      route: { permissions: [] as string[] },
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingRoleDetails, setIsFetchingRoleDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const [permissions, setPermissions] = useState({
    object: { permissions: [] as string[] },
    route: { permissions: [] as string[] },
  });
  const [viewType, setViewType] = useState("ADD" as "ADD" | "EDIT");

  useEffect(() => {
    //@ts-ignore
    if (match.params.uuid) {
      setViewType("EDIT");
    }
  }, [
    //@ts-ignore
    match.params.uuid,
  ]);

  useEffect(() => {
    //@ts-ignore
    if (match.params.uuid) {
      fetchRoleDetails();
    }
    fetchPermissionsList();
  }, []);

  const fetchPermissionsList = async () => {
    setIsFetchingRoleDetails(true);
    try {
      let permissions = await axiosInstance.get(API_SLUGS.PERMISSIONS);
      setPermissions(permissions.data?.data?.permissions);
    } catch (error: any) {}
    setIsFetchingRoleDetails(false);
  };

  const fetchRoleDetails = async () => {
    setIsFetchingRoleDetails(true);
    try {
      let role = await axiosInstance.get(
        format(API_SLUGS.ROLE, {
          //@ts-ignore
          uuid: match.params.uuid,
        })
      );
      setFormValues(role.data?.data);
    } catch (error: any) {}
    setIsFetchingRoleDetails(false);
  };

  const handleChange = (
    value: any,
    type: "name" | "object-permission" | "route-permission",
    index: number | null
  ) => {
    let newValues = { ...formValues };
    if (type === "object-permission") {
      newValues["permissions"]["object"]["permissions"][index!] = value;
    } else if (type === "route-permission") {
      newValues["permissions"]["route"]["permissions"][index!] = value;
    } else {
      newValues[type] = value;
    }

    console.log({ newValues });

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      //@ts-ignore
      if (!match.params.uuid) {
        await axiosInstance.post(API_SLUGS.ADD_ROLE, formValues);
      } else {
        await axiosInstance.put(
          format(API_SLUGS.EDIT_ROLE, {
            //@ts-ignore
            uuid: match.params.uuid,
          }),
          formValues
        );
      }
      history.push(ROUTES.ROLES);
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) {}
    }
    setIsSubmitting(false);
  };

  const removePermissions = (type: "route" | "object", index: number) => {
    let newFormValues = { ...formValues };
    let newPermissions = [...newFormValues.permissions[type].permissions];
    newPermissions.splice(index, 1);
    newFormValues.permissions[type].permissions = newPermissions;

    setFormValues(newFormValues);
  };

  if (isFetchingRoleDetails) {
    return null;
  }

  return (
    <form>
      <Grid container spacing={3} sx={{ textAlign: "left" }}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={5}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Role Name"
              name="name"
              value={formValues.name}
              onChange={(e: any) => {
                handleChange(e.target.value, "name", null);
              }}
              required
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography color="textPrimary" gutterBottom variant="h6">
            Object Permissions
          </Typography>
        </Grid>
        {formValues.permissions.object.permissions.map((value, index) => (
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                getOptionLabel={(option): string => option}
                options={permissions.object.permissions}
                value={value}
                onSelect={(e) => {
                  //@ts-ignore
                  let selectedValue = e.target.value;
                  handleChange(selectedValue, "object-permission", index);
                }}
                renderInput={(params): JSX.Element => (
                  <TextField
                    autoComplete="off"
                    label="Object Permission"
                    name="object-permission"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button onClick={() => removePermissions("object", index)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            mt: 3,
          }}
        >
          <Button
            onClick={() =>
              setFormValues({
                ...formValues,
                permissions: {
                  ...formValues.permissions,
                  object: {
                    permissions: [
                      ...formValues.permissions.object.permissions,
                      "",
                    ],
                  },
                },
              })
            }
          >
            Add New Object Permission
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography color="textPrimary" gutterBottom variant="h6">
            Route Permissions
          </Typography>
        </Grid>
        {formValues.permissions.route.permissions.map((value, index) => (
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                getOptionLabel={(option): string => option}
                options={permissions.route.permissions}
                defaultValue={value}
                onSelect={(e) => {
                  //@ts-ignore
                  let selectedValue = e.target.value;
                  handleChange(selectedValue, "route-permission", index);
                }}
                renderInput={(params): JSX.Element => (
                  <TextField
                    autoComplete="off"
                    label="Route Permission"
                    name="route-permission"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button onClick={() => removePermissions("route", index)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            mt: 3,
          }}
        >
          <Button
            onClick={() =>
              setFormValues({
                ...formValues,
                permissions: {
                  ...formValues.permissions,
                  route: {
                    permissions: [
                      ...formValues.permissions.route.permissions,
                      "",
                    ],
                  },
                },
              })
            }
          >
            Add New Route Permission
          </Button>
        </Grid>
      </Grid>

      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        <Button
          color="primary"
          size="medium"
          variant="contained"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

export default withRouter(AddRoleForm);
