import { FC } from "react";
import { Box, Button, Container, Paper } from "@mui/material";
import OperationView from "../generators/OperationView";
import { Close } from "@mui/icons-material";

interface IOperationModal {
  selectedOperation: IOperationDetails;
  
  onClose: () => void;
}

interface IOperationDetails {
  apiId: string;
  revId: string;
  defaultOperationId?: string| null;
}

const OperationModal: FC<IOperationModal> = ({
  selectedOperation,
  onClose,
}) => {
  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={12} sx={{ p: 3 }}>
          <Box>
            <Close className="hand" onClick={onClose} />
          </Box>
          <Box sx={{ padding: "auto", height: "80vh", overflow: "scroll" }}>
            <OperationView apiId={selectedOperation.apiId} revId={selectedOperation.revId} defaultOperationId={selectedOperation.defaultOperationId as string | undefined}/>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default OperationModal;
