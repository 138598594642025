import { DoubleArrow } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import {
  Menu,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  useProSidebar
} from "react-pro-sidebar";
import { Link as RouterLink, matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import { getConfigs } from "../../../configs";
import usePermissionChecker from "../../../hooks/process/usePermissionChecker";
import NavBarData, { INavBarData, INavBarItem } from "./navBarData";

const NavBar: FC<RouteComponentProps> = ({ history }) => {
  const theme = useTheme();

  const { collapseSidebar, collapsed } = useProSidebar();



  useEffect(() => {
    collapseSidebar(localStorage.getItem("isSidebarCollapsed") === "true");
  }, []);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 600,
      height: "2.75em",
     
    },

    icon: {
      "&:hover": {
        color: theme.palette.secondary.main,
        fontWeight: 400,
      },
    },

    button: {
      height: "100%",
      "&:hover": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.neutral![800],
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


  return (
    <Sidebar
      style={{
        textAlign: "left",
        height: "100vh",
        minWidth: collapsed? 0: "14em",
        overflow: "auto",
      }}
      backgroundColor={theme.palette.neutral![900]}

      breakPoint="lg"
      rootStyles={{
        color: theme.palette.neutral![300],
        border: "none",
      }}
    >

      <Box sx={{ pt: 2, mx: 3, mb: 2 }} >
        {/* <OrganizationButton /> */}
        <RouterLink to="/" >
          <img src="/projectMercury.svg" style={{ width: "100%" }}></img>
        </RouterLink>
      </Box>


      {NavBarData?.map((category: INavBarData, index) => (
        <div>
          {!collapsed && (
            <Box sx={{ px: 3, mb: 0.25 }}>
              <Typography
                variant="overline"
                fontWeight={600}
                sx={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                {category.title}
              </Typography>
            </Box>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            {category.items.map((item: INavBarItem) => (
              <NavItem item={item} history={history} collapsed={collapsed} />
            ))}
          </Menu>
          {NavBarData.length - 1 > index && (
            <Box
              sx={{
                visibility: collapsed ? "inherit" : "hidden",
                mx: 3,
                my: 1.5,
                height: "1px",
                backgroundColor: "neutral.600",
              }}
            />
          )}
        </div>
      ))}
      <IconButton
        sx={{ mx: 2, mt: 1, position: "absolute", bottom: 10 }}
        onClick={() => {
          localStorage.setItem("isSidebarCollapsed", `${!collapsed}`);
          collapseSidebar();
        }}
      >
        <DoubleArrow
          sx={{ transform: collapsed ? "scaleX(1)" : "scaleX(-1)" }}
        />
      </IconButton>
    </Sidebar>
  );
};

interface INavItemProps {
  item: INavBarItem;
  history: any;
  collapsed: boolean;
}
const NavItem: FC<INavItemProps> = ({ item, history, collapsed }) => {
  const theme = useTheme();
  const { hasPermission } = usePermissionChecker(
    item.blacklistCheckParams?.target,
    item.blacklistCheckParams?.type
  );

  return (!item.experimental ||
    (item.experimental && getConfigs().experimentalFeatures)) &&
    !(item.hideOmnibus && getConfigs().isOmnibus) &&
    hasPermission ? (
    <Tooltip
      disableHoverListener={!collapsed}
      title={item.title}
      placement="right"
    >
      <MenuItem
        className={item.title.split(" ").join("")}
        style={
          matchPath(window.location.pathname, item?.path)?.isExact
            ? {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.neutral![800],
            }
            : {}
        }
        icon={item.icon}
        onClick={() =>
          item.isNotRelativePath
            ? window.open(
              `${item.path}?${item.queryParams ? item.queryParams() : ""}`,
              "_blank"
            )
            : history.push(item.path)
        }
      >
        {item.title}
      </MenuItem>
    </Tooltip>
  ) : (
    <></>
  );
};

export default withRouter(NavBar);
