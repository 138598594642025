import { axiosInstance } from "../../services/axios";
import useFetchOperations from "./useFetchOperations";

const useMergeResources = (apiId: string) => {
  const { operations, reFetchOperations } = useFetchOperations("api", apiId);

  const mergeResources = async (
    targetResources: string,
    sourceResource: string
  ) => {
    for (const operation of operations) {
      if (operation.settingsToUse.resource === sourceResource) {
        try {
          await axiosInstance.put(
            `/apis/${apiId}/operations/resourceprofiles`,
            {
              operation: operation.operationID,
              resource: targetResources,
            }
          );
        } catch (error) {}
      }
    }
    reFetchOperations();
  };

  return { mergeResources };
};

export default useMergeResources;
