import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import useFetch from "../../../hooks/http/useFetch";
import { IOperationState, useOperationState } from "../../../store/operations";

const ResourceReference: React.FC<any> = ({
  data,
  onSubmit,
  onChange,
  operations,
  allowSave = false,
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [formValues, setFormValues] = useState({ ...data } as any);
  const [userFilteredResources, setUserFilteredResources] = useState<string[]>(
    []
  );
  useEffect(() => {
    setFormValues({ ...data });
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    setHasChanged(false);
  };

  useEffect(() => {
    if (!isEmpty(operations)) {
      console.log({operations})
      let resources = new Set();
      for (let operation of operations!) {
        if (operation?.settingsToUse?.identifier?.trim() !== "" && ["create", "list"].indexOf(operation?.settingsToUse?.action)>-1) {
          resources.add(operation?.settingsToUse?.resource);
        }
      }

      console.log({resources})
      //@ts-ignore
      setUserFilteredResources(Array.from(resources));
    }
  }, [operations]);

  const handleChange = (value: any, type: "resource") => {
    let newFormValues = { ...formValues, [type]: value };
    setFormValues(newFormValues);
    onChange(newFormValues);
    setHasChanged(true);
  };

  return (
    <Box sx={{ display: "inline", width: "100%" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            sx={{
              minWidth: "10em",
              width: "100%",
              pr: 2,
              pt: 2,
              textAlign: "left",
            }}
            size="small"
            getOptionLabel={(option): string => option}
            options={userFilteredResources || []}
            fullWidth
            value={formValues?.resource}
            clearIcon={false}
            onChange={(e, value) => {
              handleChange(value, "resource");
            }}
            clearOnBlur
            renderInput={(params): JSX.Element => {
              return (
                <TextField
                  label={"Resource"}
                  placeholder="Select Resource"
                  name="faultGroups"
                  variant="outlined"
                  {...params}
                />
              );
            }}
          />

          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              disabled={
                !allowSave &&
                (!hasChanged ||
                  formValues.resource === undefined ||
                  formValues.resource.trim() === "")
              }
              size="small"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default ResourceReference;
