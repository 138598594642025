import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Button } from "@mui/material";
import React from 'react';
import { withRouter } from 'react-router-dom';
import { STRINGS } from '../../constants';

const VmWorkspaceOneLoginHandler: React.FC<any> = (props) => {
    console.log(props, "vmoneWorkspaceOneLogin");
    const { loginHandler } = props;
    return (<>
        <Button 
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={loginHandler}
            data-testid="signin-vmone-button"
            
            startIcon={<WorkspacesIcon />}
        >
            {STRINGS.SIGNIN_VMONE}</Button>
    </>);
}


export default withRouter(VmWorkspaceOneLoginHandler);