import {
  Box, Button,
  ButtonGroup, Chip, ClickAwayListener, Grid, Grow, MenuItem,
  MenuList, Paper,
  Popper, TextField, Typography
} from "@mui/material";
import { filter, find, isEmpty, reverse } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS } from "../../../../constants";
import { ChevronDown } from "../../../../icons/chevron-down";
import { Clock } from "../../../../icons/clock";
import { UserCircle } from "../../../../icons/user-circle";
import { axiosInstance } from "../../../../services/axios";
import Loaders from "../../../dashboard/Loaders";
var format = require("string-template");

export const FINDING_STATE_OPTIONS = [
  {
    key: "comment",
    label: "Comment",
    stateLabel: null,
    eventKind: "comment",
    defaultSelected: false,
    shouldAskComment: true,
    allowedTransitions: [
      "assigned",
      "risk_accepted",
      "false_positive",
      "fixed",
    ],
  },
  {
    key: "unassigned",
    label: "Unassign",
    eventKind: "state",
    stateLabel: "Unassigned",
    shouldAskComment: false,
    defaultSelected: true,
    allowedTransitions: [
      "comment",
      "assigned",
      "risk_accepted",
      "false_positive",
      "fixed",
    ],
  },
  {
    key: "assigned",
    label: "Assign",
    eventKind: "state",
    stateLabel: "Assigned",
    shouldAskComment: true,
    defaultSelected: true,
    shouldAskMember: true,
    allowedTransitions: [
      "comment",
      "assigned",
      "risk_accepted",
      "false_positive",
      "fixed",
    ],
  },
  {
    key: "risk_accepted",
    label: "Accept Risk",
    eventKind: "state",
    stateLabel: "Risk Accepted",
    defaultSelected: false,
    shouldAskComment: true,
    allowedTransitions: ["comment", "assigned"],
  },
  {
    key: "false_positive",
    label: "False Positive",
    eventKind: "state",
    defaultSelected: false,
    stateLabel: "False Positive",
    shouldAskComment: true,
    allowedTransitions: ["comment"],
  },
  {
    key: "fixed_confirmed",
    label: "Fixed Confirmed",
    eventKind: "state",
    defaultSelected: false,
    stateLabel: "Fixed Confirmed",
    shouldAskComment: false,
    allowedTransitions: ["comment"],
  },
  {
    key: "fixed",
    label: "Fixed",
    stateLabel: "Fixed",
    defaultSelected: false,
    eventKind: "state",
    shouldAskComment: true,
    allowedTransitions: ["comment"],
  },
  {
    key: "regression",
    label: "Regression",
    stateLabel: "Regression",
    defaultSelected: false,
    eventKind: "state",
    shouldAskComment: true,
    allowedTransitions: [
      "comment",
      "assigned",
      "risk_accepted",
      "false_positive",
      "fixed",
    ],
  },
];

export const RISK_STATE_OPTIONS = [
  {
    key: null,
    label: "None",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["information", "low", "medium", "high", "critical"],
  },
  {
    key: "information",
    label: "Information",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["low", "medium", "high", "critical"],
  },
  {
    key: "low",
    label: "Low",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["information", "medium", "high", "critical"],
  },
  {
    key: "medium",
    label: "Medium",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["information", "low", "high", "critical"],
  },
  {
    key: "high",
    label: "High",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["information", "low", "medium", "critical"],
  },
  {
    key: "critical",
    label: "Critical",
    eventKind: "risk",
    shouldAskComment: true,
    allowedTransitions: ["information", "low", "medium", "high"]
  },
];

interface IActionButton extends RouteComponentProps {
  state: string;
  user?: null | { [key: string]: any };
  risk?: string;
  buttons: "BOTH" | "STATE" | "RISK";
  expandedView?: boolean;
  jira?: {
    url: null | string;
    number: null | string;
  };
  incidentId: string;
}

const ActionButton: React.FC<IActionButton> = ({
  history,
  state,
  buttons = "BOTH",
  risk = null,
  incidentId,
  user = null,
  expandedView = false,
  jira,
}) => {
  const [jiraInfo, setJiraInfo] = useState({ ...jira });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [currentState, setCurrentState] = useState(state ? state : "comment");
  const [currentRisk, setCurrentRisk] = useState(risk);
  const [currentLabel, setCurrentLabel] = useState("Action");
  const [filteredOptions, setFilteredOptions] = useState([] as any[]);
  const [filteredRiskOptions, setFilteredRiskOptions] = useState([] as any[]);
  const [assignedUser, setAssignedUser] = useState(
    user as null | { [key: string]: any }
  );
  const [comment, setComment] = useState("");
  const [view, setView] = useState(
    "OPTIONS" as
    | "OPTIONS"
    | "COMMENT"
    | "MEMBERS"
    | "TIMELINE"
    | "JIRA"
    | "RISK"
  );
  const [temporarySelectedOption, setTemporarySelectedOption] = useState(
    {} as any
  );
  const [members, setMembers] = useState([]) as any;
  const [selectedMember, setSelectedMember] = useState(null as string | null);
  const [timeline, setTimeline] = useState([] as any);
  const [projects, setProjects] = useState([] as any);

  useEffect(() => {
    setAssignedUser(user);
    setCurrentState(state || "comment");
    setCurrentRisk(risk);
  }, [user, state, risk]);

  useEffect(() => {
    if (view === "MEMBERS") {
      fetchInvites();
    }
  }, [view, incidentId]);

  useEffect(() => {

    // Time out because this will show options for a split second before popup close animation ends
    setTimeout(() => {
      if (!open) {
        setView("OPTIONS");
      }
    }
      , 150);

  }, [open, incidentId]);

  useEffect(() => {
    if (view === "TIMELINE" || expandedView) {
      fetchTimeline();
    }

    if (view === "JIRA") {
      fetchJiraProjects();
    }
  }, [view, incidentId]);

  const fetchJiraProjects = async () => {
    try {
      let projectsResponse = await axiosInstance.get(API_SLUGS.JIRA_PROJECTS);

      setProjects(projectsResponse.data);
    } catch (error) { }
  };

  const fetchTimeline = async () => {
    try {
      let timelineResponse = await axiosInstance.get(
        format(API_SLUGS.POST_TIMELINE, { id: incidentId })
      );

      setTimeline(reverse(timelineResponse.data?.timelineEvents));
    } catch (error) { }
  };

  const fetchInvites = async () => {
    try {
      let membersResponse = await axiosInstance.get(
        API_SLUGS.GET_ORGANIZATION_MEMBERS
      );
      setMembers(membersResponse.data?.members);
    } catch (error: any) { }
  };

  useEffect(() => {
    try {
      let allowedTransitions = find(FINDING_STATE_OPTIONS, {
        key: currentState,
      })?.allowedTransitions;
      let newFilteredOptions = filter(FINDING_STATE_OPTIONS, (option) => {
        if (allowedTransitions?.indexOf(option?.key)! > -1) {
          return option;
        }
      });
      setFilteredOptions(newFilteredOptions);
    } catch (error) { }
  }, [currentState, incidentId]);

  useEffect(() => {
    try {
      let allowedTransitions = find(RISK_STATE_OPTIONS, {
        key: currentRisk,
      })?.allowedTransitions;
      let newFilteredOptions = filter(RISK_STATE_OPTIONS, (option) => {
        if (
          option?.key !== null &&
          allowedTransitions!.indexOf(option?.key!) > -1
        ) {
          return option;
        }
      });
      setFilteredRiskOptions(newFilteredOptions);
    } catch (error) { }
  }, [currentRisk, incidentId]);

  useEffect(() => {
    changeLabel();
  }, [currentState, incidentId]);

  const changeLabel = () => {
    try {
      let newLabel = find(FINDING_STATE_OPTIONS, { key: currentState })
        ?.stateLabel!;
      setCurrentLabel(newLabel);
    } catch (error) {
      setCurrentLabel("Action");
    }
  };

  const handleAssignProject = async (projectId: any) => {
    const response = await axiosInstance.post(API_SLUGS.POST_JIRA_ISSUE, {
      incidentId: incidentId,
      projectId: projectId,
    });

    setJiraInfo({
      url: response?.data?.jiraUrl,
      number: response?.data?.jiraNumber,
    });
    handleToggle();
  };

  const handleMenuItemClick = async (option: any, meta?: any) => {
    console.log({ comment });
    try {
      setSelectedMember(meta.assigneeId);
    } catch (error) { }

    if (selectedMember) {
      meta = { ...meta, assigneeId: selectedMember };
    }
    if (option?.shouldAskMember && (isEmpty(meta) || !meta.assigneeId)) {
      setTemporarySelectedOption(option);
      setView("MEMBERS");
    } else if (option?.shouldAskComment && comment.trim() === "") {
      setTemporarySelectedOption(option);
      setView("COMMENT");
    } else {
      try {
        let data = {
          eventKind: option?.eventKind,
          comment: comment,
          ...meta,
        };
        if (option?.eventKind === "state") {
          data.state = option?.key;
        }

        if (option?.eventKind === "risk") {
          data.risk = option?.key;
        }
        await axiosInstance.post(
          format(API_SLUGS.POST_TIMELINE, { id: incidentId }),
          data
        );
        option?.eventKind === "state" && setCurrentState(option?.key);
        option?.eventKind === "risk" && setCurrentRisk(option?.key);
        setView("OPTIONS");
        setComment("");
        setOpen(false);
        setSelectedMember(null);
        if (!isEmpty(meta) && !isEmpty(meta.assigneeId)) {
          setAssignedUser(find(members, { id: meta.assigneeId }));
        }
        if (expandedView) {
          fetchTimeline();
        }
      } catch (error: any) { }
    }
  };

  const handleToggle = (view?: any) => {
    if (["TIMELINE", "JIRA", "RISK"].indexOf(view) > -1 && !open) {
      setView(view);
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    setComment("");
    setOpen(false);

    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup
        sx={{ m: "auto", mb: 0, mt: 0, mr: 0.5 }}
        size="small"
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        {["BOTH", "RISK"].indexOf(buttons) > -1 ? (
          <Button
          
            onClick={() => handleToggle("RISK")}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            variant={"outlined"}
            //@ts-ignore
            color={
              !currentRisk
                ? undefined
                : currentRisk.toLowerCase() === "information" ?
                  "information"
                  : currentRisk.toLowerCase() === "low"
                    ? "info"
                    : currentRisk.toLowerCase() === "medium"
                      ? "warning"
                      : currentRisk.toLowerCase() === "high"
                        ? "error"
                        : "critical"
            }
          >
            {buttons === "BOTH" ? (
              <Typography sx={{ fontSize: "10px" }} variant="overline">
                Risk
              </Typography>
            ) : null}
            &nbsp;{find(RISK_STATE_OPTIONS, { key: currentRisk })?.label}{" "}
            <ChevronDown />
          </Button>
        ) : null}

        {["BOTH", "STATE"].indexOf(buttons) > -1 ? (
          <>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <div>
                <Typography variant="overline">
                  {currentState === "assigned" && !isEmpty(assignedUser) ? (
                    <>
                      <UserCircle
                        fontSize="small"
                        sx={{
                          display: "inline",
                          position: "relative",
                          top: "4px",
                        }}
                      />{" "}
                      <Box sx={{ display: "inline" }}>
                        {assignedUser!.firstName} {assignedUser!.lastName}
                      </Box>
                    </>
                  ) : (
                    currentLabel
                  )}
                </Typography>
              </div>
              <ChevronDown />
            </Button>

            {expandedView ? null : (
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => handleToggle("TIMELINE")}
              >
                <Clock />
              </Button>
            )}

            {jiraInfo.url ? (
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                variant="contained"
                onClick={() => window.open(jiraInfo.url!, "_blank")}
              >
                JIRA
              </Button>
            ) : (
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                variant="outlined"
                onClick={() => handleToggle("JIRA")}
              >
                JIRA
              </Button>
            )}
          </>
        ) : null}
      </ButtonGroup>

      {expandedView ? (
        <Box>
          <Box sx={{ display: "flex", mt: 2 }}>
            <TextField
              sx={{ maxWidth: "100%", textAlign: "left" }}
              size="small"
              fullWidth
              name="Comment"
              label="Comment"
              value={comment}
              onChange={(e: any) => {
                setComment(e.target.value);
              }}
              variant="outlined"
            />
            <Button
              variant="contained"
              sx={{ height: "fit-content", ml: 1 }}
              size="small"
              disabled={comment?.trim() === ""}
              onClick={() =>
                handleMenuItemClick(
                  find(FINDING_STATE_OPTIONS, {
                    key: "comment",
                  })
                )
              }
            >
              Comment
            </Button>
          </Box>
          <Timeline currentRisk={currentRisk} timeline={timeline} />
        </Box>
      ) : null}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        
        sx={{ zIndex: 999999 }}
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={15}>
              <ClickAwayListener
                onClickAway={(e) => {
                  view !== "COMMENT" && handleClose(e);
                }}
              >
                <Box>
                  {view === "COMMENT" ? (
                    <Box sx={{ p: 3 }}>
                      <TextField
                        sx={{ maxWidth: "100%", textAlign: "left", mb: 2 }}
                        size="medium"
                        fullWidth
                        name="Comment"
                        label="Comment"
                        value={comment}
                        onChange={(e: any) => {
                          setComment(e.target.value);
                        }}
                        required
                        variant="outlined"
                      />
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleMenuItemClick(temporarySelectedOption)
                        }
                      >
                        {temporarySelectedOption.label}
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          handleToggle();
                          setComment("");
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : null}
                  {view === "OPTIONS" ? (
                    <MenuList id="split-button-menu">
                      {filteredOptions.map((option, index) => (
                        <MenuItem
                          key={option?.key}
                          onClick={(event) => handleMenuItemClick(option)}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  ) : null}

                  {view === "MEMBERS" ? (
                    <MenuList id="split-button-menu">
                      {!isEmpty(members) ? (
                        members.map((option: any, index: number) => (
                          <MenuItem
                            key={option?.email}
                            onClick={(event) =>
                              handleMenuItemClick(temporarySelectedOption, {
                                assigneeId: option?.id,
                              })
                            }
                          >
                            <UserOption user={option} />
                          </MenuItem>
                        ))
                      ) : (
                        <Loaders />
                      )}
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => setView("OPTIONS")}
                      >
                        Cancel
                      </Button>
                    </MenuList>
                  ) : null}

                  {view === "JIRA" ? (
                    <MenuList id="split-button-menu">
                      {!isEmpty(projects) ? (
                        projects.map((option: any, index: number) => (
                          <MenuItem
                            key={option?.id}
                            onClick={(event) => handleAssignProject(option?.id)}
                          >
                            {option?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <Box sx={{ p: 2 }}>
                          <Typography variant="body1">
                            No JIRA Projects
                          </Typography>
                        </Box>
                      )}
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => setView("OPTIONS")}
                      >
                        Cancel
                      </Button>
                    </MenuList>
                  ) : null}
                  {view === "RISK" ? (
                    <MenuList id="split-button-menu">
                      {filteredRiskOptions.map((option, index) => (
                        <MenuItem
                          key={option?.key}
                          onClick={(event) => handleMenuItemClick(option)}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  ) : null}

                  {view === "TIMELINE"
                    ? [
                      <Timeline
                        currentRisk={currentRisk}
                        timeline={timeline}
                      />,
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => handleToggle()}
                      >
                        Close
                      </Button>,
                    ]
                    : null}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

const Timeline: React.FC<any> = ({ timeline, currentRisk }) => {
  return (
    <MenuList
      id="split-button-menu"
      sx={{ maxHeight: "20em", overflow: "auto" }}
    >
      {!isEmpty(timeline) ? (
        timeline.map((option: any, index: number) => (
          <MenuItem key={option?.comment} sx={{ px: 0 }}>
            <Grid container sx={{ maxWidth: "30em", overflow: "auto" }}>
              <Grid item xs={12}>
                <Chip
                  size="small"
                  variant="filled"
                  sx={{
                    borderRadius: "5px",
                    mr: 1,
                  }}
                  color={
                    option?.eventKind === "risk"
                      ? !option?.to
                        ? "default"
                        : option?.to.toLowerCase() === "low"
                          ? "info"
                          : option?.to.toLowerCase() === "medium"
                            ? "warning"
                            : "error"
                      : "default"
                  }
                  label={
                    //@ts-ignore
                    option?.eventKind === "risk"
                      ? `${find(RISK_STATE_OPTIONS, {
                        key: option!.to,
                      })!.label
                      }`
                      : option?.eventKind === "state"
                        ? `${find(FINDING_STATE_OPTIONS, {
                          key: option!.to,
                        })!.stateLabel
                        } ${option!.to === "assigned"
                          ? `to ${option?.assignee?.firstName} ${option?.assignee?.lastName}`
                          : ""
                        }`
                        : "Comment"
                  }
                />

                <Typography variant="caption" sx={{ fontSize: "14px" }}>
                  by {option?.assigner?.firstName} {option?.assigner?.lastName}
                  &nbsp;at&nbsp;
                  {moment(option?.createdAt).format("h:mm a DD MMM YYYY ")}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>

              <Grid item xs={12}>
                <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                  {option?.comment}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">No history</Typography>
        </Box>
      )}
    </MenuList>
  );
};

const UserOption: React.FC<any> = ({ user }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          {user.firstName} {user.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle1"} fontSize={"14px"}>
          {user.email}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withRouter(ActionButton);
