import { Box, Card, CardContent, Container } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ResultView from "./apiComponents/ResultView";
//   import './index.css'
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
var format = require("string-template");

const ApiDetails: React.FC<RouteComponentProps> = ({
  history,
  match,
  location,
}) => {
  //@ts-ignore
  const apiId = match.params.apiId;
  const [api, setApi] = useState({} as any);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    try {
      let apiResponse = await axiosInstance.get(
        format(API_SLUGS.GET_API, {
          //@ts-ignore
          uuid: apiId,
        })
      );
      setApi(apiResponse.data);
    } catch (error: any) { }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Card elevation={12}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {!isEmpty(api) && <ResultView api={api} />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(ApiDetails);
