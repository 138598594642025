import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import VisxTimeSeries from "../../common/charts/timeSeries";
import Loaders from "../Loaders";

interface Props extends RouteComponentProps {
  projectId?: string;
  height?: string;
  maxDisplayCount?: number;
  sx?: any;
}

const ISSUE_TYPE_OPTIONS = [
  {
    key: "new",
    label: "New",
  },
  {
    key: "open",
    label: "Open",
  },
  {
    key: "closed",
    label: "Closed",
  },
]


const parseTimeSeries = (blankObject: any, rawData: any) => {
  let data: any = {}
  for (let key in blankObject) {
    data[key] = rawData?.map((d: any, index: number) => {

      return {
        x: d.date,
        y: d[key],
      };
    });


  }
  return data
}

const SeverityTimeSeries: React.FC<Props> = ({ history, projectId = undefined, height = "400px", sx = {}
}) => {
  const url = useMemo(() => {

    const today = new Date().toISOString().slice(0, 10);
    const sinceDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().slice(0, 10);
    let baseUrl = `insights/incidents/timeseries?fromDate=${sinceDaysAgo}&toDate=${today}`;
    if (projectId) {
      return `${baseUrl}&applicationId=${projectId}`;
    }
    return baseUrl;
  }, [projectId]);
  const [displayIssues, setDisplayIssues] = useState<"closed" | "open" | "new">("open");
  const { data, loading: isFetchingData } = useFetch(url);
  const isDataEmpty = useMemo(() => {
    return !data || (data?.closedIssues?.length === 0 && data?.openIssues?.length === 0 && data?.newIssues?.length === 0);
  }, [data?.closedIssues, data?.newIssues, data?.openIssues]);

  const timeSeriesData = useMemo(() => {
    if (data) {
      const blankLineData = {
        "critical": [],
        "high": [],
        "medium": [],
        "low": [],
        "information": []

      }
      let closedIssues = parseTimeSeries(blankLineData, data?.closedIssues)
      let openIssues = parseTimeSeries(blankLineData, data?.openIssues)
      let newIssues = parseTimeSeries(blankLineData, data?.newIssues)



      let newTimeSeriesData: any = {
        "closed": closedIssues,
        "open": openIssues,
        "new": newIssues
      }


      // sort by date in ascending order date is in "dd-mm-yyyy" format
      for (let key in newTimeSeriesData) {
        for (let key2 in newTimeSeriesData[key]) {
          newTimeSeriesData[key][key2] = newTimeSeriesData[key][key2].sort((a: any, b: any) => {
            const aDate = new Date(a.x.split("-").reverse().join("-"));
            const bDate = new Date(b.x.split("-").reverse().join("-"));
            return aDate.getTime() - bDate.getTime();
          });
        }
      }
      // change dates to "dd-mm" format
      for (let key in newTimeSeriesData) {
        for (let key2 in newTimeSeriesData[key]) {
          newTimeSeriesData[key][key2] = newTimeSeriesData[key][key2].map((d: any) => {
            return {
              x: d.x.split("-").slice(0, 2).join("-"),
              y: d.y
            }
          });
        }
      }


      return newTimeSeriesData;
    }
    return null;
  }
    , [data]);

  const dataToDisplay = useMemo(() => {

    return timeSeriesData?.[displayIssues]
  }
    , [timeSeriesData, displayIssues]);

  const dataCount = useMemo(() => {
    let count = 0;
    let data = timeSeriesData?.[displayIssues]
    for (let key in data) {
      count += data[key].length;
    }
    return count;
  }
    , [timeSeriesData, displayIssues]);








  return (
    <Grid item md={6} xs={12} sx={sx}>

      <Box sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ ml: 1 , textAlign:"left"}}>Issues by Risk</Typography>


        {isDataEmpty && !isFetchingData && (

          <Typography variant="body2" sx={{ textAlign: "center", pt: 10 }}>
            No data available
          </Typography>
        )}

        {
          !isDataEmpty && (
            <Box sx={{ display: "flex", alignItems: "center", mt:1}}>
              {
                ISSUE_TYPE_OPTIONS?.map((option) => {
                  return <Chip color="primary" sx={{ ml: 1 }} size="small" label={option.label}
                    variant={displayIssues === option.key ? "filled" : "outlined"}
                    onClick={() =>
                      //@ts-ignore
                      setDisplayIssues(option.key)} />
                }
                )
              }
            </Box>
          )
        }
      </Box>
      {isFetchingData &&
        <Box sx={{ height: height, px: 3 }}>
          <Loaders />
        </Box>
      }

      {
        !isDataEmpty && (
          <Box sx={{
            height: height,
            //child text
            "& text": {
              fontFamily: `'Lato', 'Noto Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
                  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`
            }
          }}>
            <ParentSize>
              {(parent) => {
                const maxHeight = 405;
                const h = Math.min(parent.height, maxHeight);


                return (
                  <VisxTimeSeries
                    data={dataToDisplay}
                    width={parent.width}
                    height={h}

                    resize={parent.resize}
                  />
                );
              }}
            </ParentSize>
          </Box>
        )
      }



    </Grid>
  );
};

export default withRouter(SeverityTimeSeries);
