import {
  Autocomplete,
  Box, Container, LinearProgress, TextField,
  Typography
} from "@mui/material";
import { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import SplitButton from "../../common/tree/button";
import Projects from "../../projects";

const Analyzers: React.FC<RouteComponentProps> = ({ history }) => {


  const { data: groups, loading } = useFetch("/group");

  const groupId = useMemo(() => {
    const paramGroupId = new URLSearchParams(history.location.search).get("groupId");
    if (paramGroupId) {
      localStorage.setItem("groupId", paramGroupId);
      return paramGroupId;
    }
    const previousGroupId = localStorage.getItem("groupId");
    if (previousGroupId) {
      return previousGroupId;
    }

    return groups?.groups?.[0]?.id;
  },
    [groups?.groups, history.location.search])

  const { data: group, loading: isGroupLoading } = useFetch(
    `/group/${groupId}`
  );


  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          // flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="lg" sx={{ p: 3 }}>

          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              justifyContent: "start",
              mb: 3,
            }}
          >
            {/**Group name */}


            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "left", ml: 2 }}>

              {!isGroupLoading && (

                <Autocomplete
                  id="group"
                  options={groups?.groups || []}
                  getOptionLabel={(option) => option.name}
                  value={group}

                  size="small"
                  disableClearable
                  onChange={(event, newValue) => {
                    if (newValue) {
                      history.push(`/projects?groupId=${newValue.id}`);
                    }
                  }}
                  sx={{
                    width: "fit-content", mb: 1, minWidth: "10em", "&.MuiOutlinedInput-root": {
                      backgroundColor: "white !important"
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Group" />
                  )}
                />

              )}
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
                sx={{ textAlign: "left", mx: 2 }}
              >
                Projects
              </Typography>
              <SplitButton groupId={groupId} />
            </Box>


          </Box>
          {loading ? <LinearProgress /> : null}

          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Projects groupId={groupId} />
            {/* <ResultsTable groupId={groupId} /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(Analyzers);
