import { Close, Summarize } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { find, isEmpty, orderBy } from "lodash";
import MaterialReactTable from "material-react-table";
import { FC, UIEvent, useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { axiosInstance } from "../../../../services/axios";
import OperationModal from "../../../applications/OperationsModal";
import { DataCard } from "../../../dashboard";
import { ResultData } from "./ResultData";

import useFetch from "../../../../hooks/http/useFetch";
import useFetchOperations from "../../../../hooks/process/useFetchOperations";
import ModalWrapper from "../../../common/modal/wrapper";
import { IncidentData } from "../incidentComponents/IncidentView";
import PerformanceTable from "./performanceTable";
import ResultNewView from "./ResultNewView";
var format = require("string-template");

const OperationColumn: FC<any> = ({ operations, operationId }) => {
  const [operationData, setOperationData] = useState(null as any);

  useEffect(() => {
    try {
      setOperationData({
        ...find(operations, { operationID: operationId }),
        operationID: operationId,
      });
    } catch (error) {
      setOperationData({ operationID: operationId });
    }
  }, [operations, operationId]);

  if (!operationData) {
    return <></>;
  }
  return (
    <div>
      <Typography
        variant="caption"
        sx={{ fontSize: "12px" }}
        color="text.secondary"
      >
        {operationData?.["resource"]}
      </Typography>
      <Box>
        <Typography variant="overline" color="success.main">
          {operationData?.["method"]} &nbsp;
        </Typography>
        <Typography sx={{ display: "inline" }} variant="h6">
          {operationData?.["path"]}
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ fontSize: "12px" }}>
        {operationData?.["operationID"]}
      </Typography>
    </div>
  );
};

const ResultView: FC<any> = ({ history, resultId, location }) => {
  const [selectedFindingId, setSelectedFindingId] = useState(
    null as string | null
  );
  const [selectedTab, setSelectedTab] = useState<"Faults" | "Performance">(
    "Faults"
  );
  const [result, setResult] = useState({} as any);
  const [incidentId, setIncidentId] = useState("");
  const [selectedFaultGroup, setSelectedFaultGroup] = useState("");
  const [errorsTableData, setErrorsTableData] = useState<any>({});
  const [operationsTableData, setOperationsTableData] = useState<any>({});
  const [openOperationModal, setOpenOperationModal] = useState(false);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [apiId, setApiId] = useState(null);
  const { data: api, loading: apiLoading } = useFetch(result?.apiId ? `/apis/${result?.apiId}` : undefined);
  const [revId, setRevId] = useState(null);
  const { operations } = useFetchOperations("result", result?.apiId, resultId);

  useEffect(() => {
    fetchResult();
  }, []);

  const fetchResult = async () => {
    try {
      let response = await axiosInstance.get(format(`/results/${resultId}`));
      setResult(response?.data);
    } catch (error: any) { }
  };

  useEffect(() => {
    if (!isEmpty(result) && !isEmpty(operations)) {
      let newFaultAnalyzerColumns: any[] = [
        {
          header: "Operations",
          accessorKey: "operationID", // accessor key for the cell

          Cell: (params: any) => (
            <OperationColumn
              operations={operations}
              operationId={params?.row?.original?.["operationID"]}
            />
          ),
        },
      ];
      if (!isEmpty(result?.faultAnalyzers)) {
        for (let analyzer of result?.faultAnalyzers) {
          try {
            newFaultAnalyzerColumns.push({
              header: analyzer?.displayName,
              accessoryKey: analyzer?.name,
              type: "custom",
              Cell: (params: any) => {
                try {
                  if (!params?.row?.original?.[analyzer?.name]) {
                    return <></>
                  }
                  return (
                    <Chip
                      size="small"
                      sx={{ fontSize: "12px" }}
                      label={params?.row?.original?.[analyzer?.name]}
                      style={{
                        backgroundColor:
                          params?.row?.original?.[analyzer?.name]?.toLowerCase() === "pass"
                            ? "#10B981"
                            : params?.row?.original?.[analyzer?.name]?.toLowerCase() === "none"
                              ? "#e46b48"
                              : params?.row?.original?.[analyzer?.name]?.toLowerCase() === "skip"
                                ? "#40ce9f"
                                : "#D14343 ",
                        color: "#fff",
                      }}
                    />
                  );
                } catch (error) { }
                return null;
              },
            });
          } catch (error) { }
        }
      }
      let newFaultAnalyzerData = [];
      let newFaultAnalyzerStatusData = [];
      if (
        !isEmpty(result?.metrics) &&
        !isEmpty(result?.metrics?.perOperation)
      ) {
        for (let operation of result?.metrics?.perOperation) {
          let object: any = { operationID: operation?.operationId };
          newFaultAnalyzerStatusData.push({
            ...object,
            failedRequests: operation.requestsFailed,
            successfulRequests: operation.requestsSuccessful,
            status: operation.requestsSuccessful > 0 ? "PASS" : "FAIL",
          });
          for (let analyzer of operation?.perAnalyzer) {
            object[analyzer?.analyzerName] = analyzer?.decision;
          }
          newFaultAnalyzerData.push(object);
        }
      }
      setOperationsTableData({
        data: orderBy(
          newFaultAnalyzerData.map((o) => ({
            ...o,
            operationId: o.operationID,
            resource: find(operations, { operationID: o.operationID })
              ?.resource,
          })),
          "resource",
          "asc"
        ),
        columns: newFaultAnalyzerColumns,
      });
    }
  }, [result, operations]);

  const handleCloseOperationModal = () => {
    setSelectedOperationId(null);
    setOpenOperationModal(false);
  };


  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid xs={12} md={resultId ? 12 : 8}>
          <Card
            elevation={0}
            sx={{
              pl: 2,
              textAlign: "left",
            }}
          >
            <Modal
              open={openOperationModal}
              onClose={handleCloseOperationModal}
            >
              <OperationModal
                onClose={handleCloseOperationModal}
                selectedOperation={{
                  apiId: apiId!,
                  revId: revId!,
                  defaultOperationId: selectedOperationId,
                }}
              />
            </Modal>

            <ResultData
              id={resultId}
              api={api}
              result={result}
              history={history}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onFaultsGroupChange={(value: string) =>
                setSelectedFaultGroup(value)
              }
              Summary={
                api?.assetType !== "api" && result?.toolInfo?.type !== 'DEPENDENCY' ? null : () => (
                  <ModalWrapper
                    maxWidth="xl"
                    title="Summary"
                    trigger={
                      <Tooltip title="Summary"><IconButton sx={{ py: 0.5, height: "fit-content" }} ><Summarize sx={{ mr: 1 }} /></IconButton></Tooltip>
                      
                    }
                    child={
                      <>
                        {result?.toolInfo?.type === 'DEPENDENCY' && <DependencySummary result={result} />}
                        {api?.assetType === "api" && <APISummary
                          columns={operationsTableData?.columns}
                          rows={operationsTableData?.data}
                        />}
                      </>
                    }
                  />
                )}
            />


            {selectedTab === "Performance" && (
              <PerformanceTable result={result} />
            )}

            {selectedTab === "Faults" &&
              !isEmpty(errorsTableData?.data) && (
                <Box sx={{ m: 2 }}>
                  <Accordion elevation={12}>
                    <AccordionSummary>
                      <Typography variant="h6">Errors</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataCard
                        name={""}
                        config={{
                          widget: "table",
                          enableContextFilter: true,
                          width: 8,
                          hideSearch: true,
                          skipJsonPath: true,
                          columns: errorsTableData?.columns,
                          mock_data: errorsTableData?.data,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}

            {selectedTab === "Faults" && !apiLoading && (
              <ResultNewView result={result} operations={operations} api={api} />
            )}

          </Card>
        </Grid>
      </Grid>
      <Modal
        open={selectedFindingId != null}
        onClose={() => setSelectedFindingId(null)}
      >
        <Box
          sx={{
            minHeight: "100%",
            overflow: "auto",
            p: 3,
          }}
        >
          <Container maxWidth="lg">
            <Paper elevation={12} sx={{ p: 3 }}>
              <Close
                className="hand"
                onClick={() => setSelectedFindingId(null)}
              />

              <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                <IncidentData
                  id={incidentId}
                  history={history}
                  findingId={selectedFindingId}
                />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

const DependencySummary = ({ result }: any) => {
  const { data } = useFetch(`insights/incidents/summary?resultId=${result?.id}`)


  if (data?.packages?.length === 0) {
    return <Typography variant="body2">No actions required</Typography>
  }

  return <>
    <List>
      {data?.packages?.map((pkg: any) => (

        <ListItem>
          <Chip label={pkg.action} size="small" color="info" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {pkg.package}   <Chip label={"Current: " + pkg.currentVersion} size="small" color="default" />
          </Typography>
        </ListItem>
      ))}
    </List>

  </>

}


const APISummary: React.FC<any> = ({ rows = [], columns }) => {
  const [rowsToShow, setRowsToShow] = useState(rows.slice(0, 50));
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&

          rowsToShow.length < rows.length
        ) {
          setRowsToShow((prev: any) => rows.slice(0, Math.min(prev.length + 50, rows.length)));
        }
      }
    },
    [setRowsToShow, rowsToShow]
  );


  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached, tableContainerRef]);




  return (
    <Grid item md={12} xs={12}>
      <Card elevation={20}>


        <CardContent sx={{ p: 4 }}>

          <MaterialReactTable muiTablePaperProps={{
            elevation: 0
          }} enableGlobalFilter={false}
            columns={columns}
            data={rowsToShow}


            initialState={{
              density: "compact",
              columnPinning: { left: ['operationID'] },
            }}
            enableColumnActions={false}
            enableSorting={false}

            enableHiding={false}
            enableFullScreenToggle={false}
            enableStickyHeader

            enableTopToolbar={false}
            enableBottomToolbar={false}
            enablePagination={false}
            enableDensityToggle={false}
            enableColumnFilters={false}
            // enableColumnPinning={true}



            muiTableContainerProps={{
              ref: tableContainerRef, //get access to the table container element
              sx: { maxHeight: "500px" }, //give the table a max height
              onScroll: (
                event: UIEvent<HTMLDivElement> //add an event listener to the table container element
              ) => {


                fetchMoreOnBottomReached(event.target as HTMLDivElement)

              }

            }}
          />
        </CardContent>
      </Card>

    </Grid>
  );
};

export default withRouter(ResultView);
