import { Close } from "@mui/icons-material";
import { Box, Container, Paper } from "@mui/material";
import { findIndex } from "lodash";
import { FC, useEffect, useState } from "react";
import ChordDiagram from "react-chord-diagram";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import LayoutFlow from "../layouts/Overview";
var format = require("string-template");

var format = require("string-template");

interface IGraphModal {
  selectedGraph: IGraphDetails;
  onClose?: () => void;
}

interface IGraphDetails {
  apiId: string;
  revId: string;
}

const GraphModal: FC<IGraphModal> = ({ selectedGraph, onClose }) => {


  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={12} sx={{ p: 3 }}>
          {onClose && <Box>
            <Close className="hand" onClick={onClose} />

          </Box>}
          <GraphDetails selectedGraph={selectedGraph} />

        </Paper>
      </Container>
    </Box>
  );
};

export const GraphDetails: FC<any> = ({ selectedGraph }) => {
  const [elements, setElements] = useState([]);
  const [initialElements, setInitialElements] = useState([] as any);
  const [labels, setLabels] = useState([] as any[]);
  const [colors, setColors] = useState([] as any[]);
  const [matrix, setMatrix] = useState([] as any[][]);
  const [view, setView] = useState("CHORD" as "DDG" | "CHORD");
  useEffect(() => {
    fetchGraph();
  }, []);

  const getRandomColor = (number: number) => {
    const hue = number * 137.508; // use golden angle approximation
    return `hsl(${hue},50%,55%)`;
  };

  const fetchGraph = async () => {
    try {
      let graphResponse = await axiosInstance.get(
        format(API_SLUGS.GRAPH, {
          //@ts-ignore
          apiId: selectedGraph.apiId,
          revId: selectedGraph.revId,
        })
      );
      let nodes = [...graphResponse.data?.ddg.nodes];
      let newLabels = nodes.map((node) => node.operation);
      setLabels(newLabels);
      for (let index in nodes) {
        nodes[index] = {
          ...nodes[index],
          id: nodes[index].operation,
          data: { label: nodes[index].operation ? nodes[index].operation : "" },
          position: { x: 0, y: 0 },
        };
      }

      let newMatrix = nodes.map(() => nodes.map(() => 0));

      let newColors = nodes.map((node, index) => getRandomColor(index + 1));
      setColors(newColors);

      let edges = [...graphResponse.data?.ddg.edges];
      for (let index in edges) {
        let consumer = edges[index].consumer;
        let producer = edges[index].producer;

        let consumerIndex = findIndex(nodes, { id: consumer });
        let producerIndex = findIndex(nodes, { id: producer });

        newMatrix[consumerIndex][producerIndex] += 1;
      }

      setMatrix(newMatrix);
      setInitialElements([...nodes, ...edges]);
    } catch (error) { }
  };

  return <Box sx={{ padding: "auto", height: "80vh", overflow: "scroll" }}>
    {view === "CHORD" ? (
      <ChordDiagram
        matrix={matrix}
        width={1000}
        height={800}
        outerRadius={200}
        strokeWidth={3}
        componentId={1}
        groupLabels={labels}
        groupColors={colors}
      />
    ) : (
      <LayoutFlow
        apiId={selectedGraph.apiId}
        revId={selectedGraph.revId}
      />
    )}
  </Box>
}

export default GraphModal;
