import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  styled
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ALLOWED_PATHNAMES, ROUTES } from "../../constants";
import ComponentErrorBoundary from "../../ErrorBoundary";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import AppHeader from "../common/appHeader/AppHeader";
import NavBar from "../common/appSidebar";
import { ILayoutParams } from "./layout.d";


const AppLayout: React.FC<ILayoutParams> = ({
  Component,
  isTreeAvailable,
  history,
  showContextFilter = false,
}) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);
  const [isVerifyingQuickStart, setIsVerifyingQuickStart] = useState(false);
  // const { isTreeOpen, toggleTreeOpen, contextualSidebarData } = useSidebarState(state => state) as ISidebarState

  useEffect(() => {
    // verifyQuickStart();
  }, []);

  const checkAllowedPathnameWithCurrentPathname = (pathname: string) => {
    const allowedPathnames = ALLOWED_PATHNAMES
    // allowed Pathname can be in this format /editConfig/:source?/:id? 
    // ? means optional
    return allowedPathnames.some((allowedPathname) => {
      const allowedPathnameArray = allowedPathname.split("/")
      const pathnameArray = pathname.split("/")
      // if allowed pathname has optional params then count the number of optional params
      const optionalParamsCount = allowedPathnameArray.filter((allowedPathnameItem) => allowedPathnameItem.includes("?")).length
      // pathname can only be min and max of allowed pathname length
      if (pathnameArray.length < allowedPathnameArray.length - optionalParamsCount || pathnameArray.length > allowedPathnameArray.length) {
        return false
      }
      return allowedPathnameArray.every((allowedPathnameItem, index) => {
        if (allowedPathnameItem.includes(":")) {
          return true
        }
        return allowedPathnameItem === pathnameArray[index]
      })
    })
  }
  const verifyQuickStart = async () => {

    try {
      if (window.location.pathname !== ROUTES.QUICK_START) {
        const quickStartStatus = await axiosInstance.get(
          "/guides/quickstart/status"
        );

        if (
          !quickStartStatus?.data?.isCompleted &&
          !checkAllowedPathnameWithCurrentPathname(window.location.pathname)
        ) {
          history.push(ROUTES.QUICK_START);
        }
      }
      setIsVerifyingQuickStart(false);
    } catch (error) { }
  };

  const AppLayoutRoot = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100vh",
    overflow: "auto"
  }));

  return (
    <div style={{ display: "flex", width: "100vw", height: "100%" }}>
      <NavBar />

      <AppLayoutRoot>
        <AppHeader
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          showContextFilter={showContextFilter}
        />


        <Grid container sx={{
          height: "calc(100vh - 65px)",
          overflow: "visible"
        }}>
          <Grid xs={12} maxWidth="lg" sx={{
            height: "100%",
            width: "100%"

          }}>
            {isVerifyingQuickStart ? (
              <Box sx={{ pt: 10 }}>
                <CircularProgress disableShrink />
              </Box>
            ) : (
              <ComponentErrorBoundary>
                <Component />
              </ComponentErrorBoundary>
            )}
          </Grid>
        </Grid>


      </AppLayoutRoot>
    </div>
  );
};

export const AlertPopup: React.FC = () => {
  const { messageData, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;

  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (messageData) {
      setOpenAlert(true);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [messageData]);

  const handleClose = () => {
    setOpenAlert(false);
    clearMessage();
  };

  if (isEmpty(messageData)) {
    return null;
  }
  return (
    <Snackbar
      open={openAlert}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={messageData?.type}
        sx={{ width: "100%" }}
      >
        {messageData?.title}
      </Alert>
    </Snackbar>
  );
};

export default withRouter(AppLayout);
