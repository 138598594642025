import { axiosInstance } from "../../services/axios";

export const operationFunctions = {
    getOperations: async (apiId: string) => {
      let operations: any[] = [];
      try {
        let response = await axiosInstance.get(`/apis/${apiId}/operations`);
        operations = response?.data?.operations;
        for (let index in operations) {
          operations[index]["id"] = operations[index]?.["operationID"];
        }
      } catch (error) {}
      return operations;
    },
    getOperationsByResult: async (resultId: string) => {
      let operations: any[] = [];
      try {
        let response = await axiosInstance.get(`results/${resultId}/operations`);
        operations = response?.data?.operations;
        for (let index in operations) {
          operations[index]["id"] = operations[index]?.["operationID"];
        }
      } catch (error) {}
      return operations;
    },

    getOperationsByRevision: async (apiId:string, revisionId: string) => {
      let operations: any[] = [];
      try {
        let response = await axiosInstance.get(`apis/${apiId}/revisions/${revisionId}/operations`);
        operations = response?.data?.operations;
        for (let index in operations) {
          operations[index]["id"] = operations[index]?.["operationID"];
        }
      } catch (error) {}
      return operations;
    },
    
    getAssignedGenerators: async (apiId: string) => {
      let assignedGenerators: any = {};
      try {
        let response = await axiosInstance.get(`/generators?apiId=${apiId}`);
        assignedGenerators = response?.data;
      } catch (error) {}
      return assignedGenerators;
    },
    postGenerator: async (apiId: string, operationId: string, generator: any) => {
      let response = await axiosInstance.post(`/apis/${apiId}/generators`, {
        operationId,
        ...generator,
      });
  
      return response;
    },
    patchGenerator: async (
      apiId: string,
      operationId: string,
      generator: any
    ) => {
      let response = await axiosInstance.patch(
        `/apis/${apiId}/generators/${generator?.id}`,
        {
          operationId,
          ...generator,
        }
      );
      return response;
    },
    deleteGenerator: async (apiId: string, generatorId: string) => {
      let response = await axiosInstance.delete(
        `/apis/${apiId}/generators/${generatorId}`
      );
      return response;
    },
  };