import { FC, useEffect, useState } from 'react';
import {
    Button,
    Box,
    Card,
    Dialog,
    DialogTitle,
    Grid,
    Link,
    Typography
} from '@mui/material';
import moment from "moment";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DataCard } from '../../dashboard';
import { API_SLUGS, STRINGS } from '../../../constants';
import { axiosInstance } from '../../../services/axios';
import { getConfigs } from '../../../configs';
import { IAlertState, useAlertState } from '../../../store/alertState';
import { JobSpecForm } from '../JobSpecForm';
import { CommitConfigForm } from '../CommitConfigForm';
import { ExternalLink } from "../../../icons/external-link";
import { parseHermitConfig, b64DecodeUnicode } from '../../../utils/hermitConfigUtils';

const RunsTable: FC<RouteComponentProps> = ({ history, match }) => {
    const [jobSpec, setJobSpec] = useState({ name: '', apiId: '', createdAt: '', config: '' });
    const [apis, setApis] = useState([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
    const [environments, setEnvironments] = useState([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
    const [runs, setRuns] = useState([]);
    const [analyzers, setAnalyzers] = useState([]);
    const [selectedToken, setSelectedToken] = useState('');
    const [runDialogOpen, setRunDialogOpen] = useState(false);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [specDialogOpen, setSpecDialogOpen] = useState(false);
    const [logDialogText, setLogDialogText] = useState('');
    const { setMessage, clearMessage } = useAlertState(state => state) as IAlertState;

    const jobId: string =
        //@ts-ignore
        match.params.jobId;

    useEffect(() => {
        fetchJobSpec(jobId);
        fetchJobRuns(jobId);
        fetchApis();
        fetchEnvironments();
        fetchAnalyzers();
    }, [])

    const handleRunDialogClose = () => {
        setRunDialogOpen(false);
    }

    const handleRunDialogOpen = () => {
        setRunDialogOpen(true);
    }

    const handleLogDialogOpen = (text: string) => {
        setLogDialogText(text);
        setLogDialogOpen(true);
    }
    
    const handleLogDialogClose = () => {
        setLogDialogOpen(false);
    }

    const handleSpecDialogClose = () => {
        setSpecDialogOpen(false);
    }

    const handleSpecDialogOpen = () => {
        setSpecDialogOpen(true);
    }

    const runJob = async (config: any) => { 
        clearMessage();
        try {
            await axiosInstance.post(`${getConfigs().baseApiUrl}/jobs/${jobId}/runs`, { 
                jobId,
                config: btoa(JSON.stringify(config)),
                tokenId: selectedToken
            }).then(() => {setMessage({ title: "Remote Sift job execution has been started", type: "success" });})
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    setMessage({ title: error.response.data.message, type: "error" });
                }              
            });          
            setTimeout(() => history.go(0), 2000);
            // fetchJobSpec(jobId);
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchJobSpec = async (jobId: string) => {
        try {
            const jobResponse = await axiosInstance.get(`/jobs/${jobId}`);
            const job = jobResponse.data;
            setJobSpec(job);
        } catch (error: any) {
            console.log(error);
        }
    }

    const fetchJobRuns = async (jobId: string) => {
        try {
            const runsResponse = await axiosInstance.get(`/jobs/runs/${jobId}`);
            setRuns(runsResponse.data);
        } catch (error: any) {
            console.log(error);
        }
    }

    const fetchApis = async () => {
        try {
            let apisResponse = await axiosInstance.get(API_SLUGS.ADD_API);
            setApis(apisResponse.data?.apis)
        } catch (error: any) {
            console.log(error);
        }
    }

    const fetchEnvironments = async () => {
        try {
            let environmentsResponse = await axiosInstance.get(API_SLUGS.ENVIRONMENTS);
            setEnvironments(environmentsResponse.data?.environments);
        } catch (error: any) {
            console.log(error);
        }
    }

    const fetchAnalyzers = async () => {
        try {
            let analyzersResponse = await axiosInstance.get(API_SLUGS.FAULT_ANALYZERS);
            setAnalyzers(analyzersResponse.data?.faultAnalyzers);
        } catch (error: any) {
            console.log(error);
        }
    }

    const parsedConfig = parseHermitConfig(jobSpec.config);
    // @ts-ignore
    const {applicationName, apiName} = runs.length && runs[0];

    return (<Card elevation={0} sx={{ textAlign: "left" }}>
        <Box sx={{ ml: 1, mb: 2 }}>
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
            >
                {jobSpec.name}
            </Typography>
            <Box
                sx={{ mt: 3, p: 1 }}
            >
                <Grid alignItems="center" container spacing={3} item xs={12}>
                <Grid xs={12} item sx={{ textAlign: "left" }} container>
                    { // @ts-ignore
                    applicationName ? (
                    <Grid item md={4} xs={12} sx={{ pr: 3, pt: 1 }}>
                        <Typography
                        variant={"overline"}
                        sx={{ fontSize: "12px", opacity: 0.6 }}
                        >
                        Project
                        </Typography>
                        <Typography
                        className="hand"
                        variant={"h6"}
                        >
                        {applicationName}
                        <ExternalLink fontSize="inherit" />
                        </Typography>
                    </Grid>
                    ) : null}

                    {apiName ? (
                    <Grid item md={4} xs={12} sx={{ pr: 3, pt: 1 }}>
                        <Typography
                        variant={"overline"}
                        sx={{ fontSize: "12px", opacity: 0.6 }}
                        >
                        API
                        </Typography>
                        <Typography
                        className="hand"
                        variant={"h6"}
                        >
                        {apiName}
                        <ExternalLink fontSize="inherit" />
                        </Typography>
                    </Grid>
                    ) : null}
                    
                    <Grid item md={4} xs={12} sx={{ pr: 3, pt: 1 }}>
                        <Typography
                            variant={"overline"}
                            sx={{ fontSize: "12px", opacity: 0.6 }}
                        >
                            Configuration
                        </Typography>
                        <Typography
                            className="hand"
                            variant={"h6"}
                            onClick={handleSpecDialogOpen}
                        >
                            <ExternalLink fontSize="inherit" />
                        </Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={{ ml: 1, mb: 2 }}>
            <Button
                color="primary"
                size="medium"
                variant="contained"
                onClick={() => handleRunDialogOpen()}
            >
                Run job
            </Button>
        </Box>
        <DataCard name={"Jobs"} config={{
            "widget": "table",
            "width": 8,
            "mock_data": runs,
            "data_points": [
                { "key": "id", "jsonpath": "$[*].id" },
                { "key": "firstName", "jsonpath": "$[*].firstName" },
                { "key": "lastName", "jsonpath": "$[*].lastName" },
                { "key": "started", "jsonpath": "$[*].started" },
                { "key": "finished", "jsonpath": "$[*].finished" },
                { "key": "resultId", "jsonpath": "$[*].resultId" },
                { "key": "hermitLog", "jsonpath": "$[*].hermitLog" },
                { "key": "status", "jsonpath": "$[*].status" }
            ],
            "pagination": false,
            "columns": [
                { key: "id", name: "ID", type: "hidden" },
                { key: "firstName", name: "firstName", type: "hidden" },
                { key: "lastName", name: "lastName", type: "hidden" },
                { key: "resultId", name: "resultId", type: "hidden" },
                { key: "hermitLog", name: "hermitLog", type: "hidden" },
                { key: "status", name: "status", type: "hidden" },
                { key: "started", name: "started", type: "hidden" },
                { key: "finished", name: "finished", type: "hidden" },
                {   key: "lastRunTime", name: "Started", type: "custom",
                    code: (row: any) => row.started ? moment(row.started).fromNow() : ''
                },
                {
                    key: "user", name: "Started by", type: "custom", code: (row: any) => (row.firstName || row.lastName) ? `${row.firstName} ${row.lastName}` : ''
                },
                {   key: "duration", name: "Duration", type: "custom",
                    code: (row: any) => {
                        if (row.finished) {
                            const duration = moment(row.finished).diff(moment(row.started));
                            return moment.utc(duration).format("mm:ss");
                        }
                        return '';
                    }
                },
                {
                    key: "result",
                    name: "Result",
                    type: "custom",
                    code: (row: any) => {
                        switch(row.status){
                            case 'complete':
                                return row.resultId ? (<Box sx={{ pr: 1, display: "inline-block" }}>
                                <Link color="inherit" href={`/findings?resultId=${row.resultId}`}><ExternalLink fontSize="inherit" />{row.resultId.split("-")[0]}</Link>
                                </Box>) : '';
                                break;
                            case 'failed':
                                return row.hermitLog ? (<Box sx={{ pr: 1, display: "inline-block" }}>
                                <Link color="inherit" sx={{cursor: 'pointer'}} onClick={() => handleLogDialogOpen(b64DecodeUnicode(row.hermitLog))}><ExternalLink fontSize="inherit" /> Failed</Link>
                                </Box>) : '';
                                break;
                            case 'started':
                                /* do not show status if job was started more than 15mins ago
                                (max lambda execution time) and did not finish */ 
                                if (moment().diff(moment(row.started)) < 15 * 60 * 1000) {
                                    return (<>Pending</>);
                                }
                                return '';
                                break;
                            default:
                                return '';
                          }
                    }
                }
            ]

        }} />
        <Dialog fullWidth maxWidth="md" onClose={handleRunDialogClose} open={runDialogOpen}>
            <Box sx={{ m: 3 }}>
            <CommitConfigForm hermitConf={parsedConfig} onSubmit={handleRunDialogClose} runJob={runJob} />
            </Box>
        </Dialog>
        <Dialog fullWidth maxWidth="md" onClose={handleLogDialogClose} open={logDialogOpen}>
            <DialogTitle>Error log</DialogTitle>
            <Box sx={{ m: 3 }}>
            {logDialogText}
            </Box>
        </Dialog>
        <Dialog maxWidth="lg" onClose={handleSpecDialogClose} open={specDialogOpen}>
            <Box sx={{ m: 3 }}>
                <JobSpecForm
                    hermitConf={parsedConfig}
                    apis={apis}
                    environments={environments}
                    analyzers={analyzers}
                    source='listJobs'
                    cloneLink={jobId}
                    />
            </Box>
        </Dialog>

    </Card >
    )
};

export default withRouter(RunsTable);
