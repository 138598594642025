import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { filter } from "lodash";
import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, GROUP_ROLES, STRINGS } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
var format = require("string-template");



interface IAddMemberFormValues {
  member: string;
  role?: string;
}

interface IMember {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  
  defaultFormValues?: any;
  preventRedirect?: boolean;
  updateUUID?: string;
  groupId: string;
  callback?: (data: any) => void;
}

const AddMemberForm: FC<IAddForm> = ({
  history,
  match,
  preventRedirect = false,
  callback=undefined,
  defaultFormValues,
  updateUUID,
  groupId,
}) => {
  const [formValues, setFormValues] = useState({
    member: "",
    role: "maintainer",
    ...defaultFormValues,
  } as IAddMemberFormValues);
  const { data: organizationMembers, loading: loadingMembers } = useFetch(
    API_SLUGS.GET_ORGANIZATION_MEMBERS
  );

  const { setMessage } = useAlertState((state) => state) as IAlertState;

  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);

  const handleChange = async (value: any, type: "member"| "role") => {
    let newValues = { ...formValues, [type]: value };

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (formValues.member.trim() !== "") {
      await addMember(formValues.member.trim());
    } else {
      setErrorMessage("Please fill all the mandatory fields");
    }
  };

  const addMember = async (id: string) => {
    try {
      await axiosInstance.post(format(API_SLUGS.ADD_MEMBER, {groupId}), {
        role: formValues.role || "maintainer",
        memberId: id,
      });
      if (!preventRedirect) {
        history.push("/members");
      }
      if (callback) {
        console.log("CALLBACK")
        callback({memberId: id});
      }
      setMessage({ title: "Member has been added", type: "success" });
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) {}
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Select Member
          </Typography>
          <Autocomplete
            sx={{ maxWidth: "50%", textAlign: "left", flex: 1 }}
            size="small"
            getOptionLabel={(option): any => `${option.firstName} ${option.lastName} (${option.email})`}
            options={organizationMembers?.members || []}
            onChange={(e, value) => {
              handleChange(value.id, "member");
            }}
            value={organizationMembers?.members?.find(
              (r: any) => r.key === formValues.member
            )}
            filterOptions={(options, params) => {
              
              const filtered = filter(options, function (item: any) {
                return item.email?.indexOf(params.inputValue) > -1;
              });

              return filtered;
            }}
            disableClearable
            renderInput={(params): JSX.Element => {
              return (
                <TextField
                  autoComplete="off"
                  name="id"
                  variant="outlined"
                  {...params}
                />
              );
            }}
          />
           <Typography
            color="textPrimary"
            sx={{ mb:1, mt: 2, textAlign: "left" }}
            variant="h6"
          >
            Select Role
          </Typography>
          <Autocomplete
            sx={{ maxWidth: "50%", textAlign: "left", flex: 1 }}
            size="small"
            getOptionLabel={(option): any => option.name}
            options={GROUP_ROLES || []}
            onChange={(e, value) => {
              handleChange(value?.key, "role");
            }}
            value={GROUP_ROLES.find(
              (r: any) => r.key === formValues.role
            )}
            filterOptions={(options, params) => {
              
              const filtered = filter(options, function (item: any) {
                return item?.key?.indexOf(params.inputValue) > -1;
              });

              return filtered;
            }}
            disableClearable
            
            renderInput={(params): JSX.Element => {
              return (
                <TextField
                  autoComplete="off"
                  name="id"
                  variant="outlined"
                  {...params}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={handleSubmit}
        >
          Add
        </Button>

        
      </Box>
    </Box>
  );
};

export default withRouter(AddMemberForm);
