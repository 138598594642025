import { Close } from "@mui/icons-material";
import { Box, Container, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import { base64ToString } from "../../utils/base64ToString";
var format = require("string-template");

interface IDefinitionModal {
  selectedDefinition: IDefinitionDetails;
  onClose?: () => void;
}

interface IDefinitionDetails {
  apiId: string;
  revId: string;
}

const DefinitionModal: FC<IDefinitionModal> = ({
  selectedDefinition,
  onClose,
}) => {

  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="xl">
        <Paper elevation={12} sx={{ p: 3 }}>
          {onClose && <Close className="hand" onClick={onClose} />}

          <DefinitionDetails selectedDefinition={selectedDefinition} />
        </Paper>
      </Container>
    </Box>
  );
};

export const DefinitionDetails: FC<any> = ({ selectedDefinition }) => {
  const [definition, setDefinition] = useState("" as any);
  const [api, setApi] = useState({} as any);

  useEffect(() => {
    fetchDefinition();
    fetchApi();
  }, []);

  const fetchApi = async () => {
    try {
      let apiResponse = await axiosInstance.get(
        format(API_SLUGS.GET_API, {
          //@ts-ignore
          uuid: selectedDefinition.apiId,
        })
      );
      setApi(apiResponse.data);
    } catch (error: any) { }
  };

  const fetchDefinition = async () => {
    try {
      let definitionResponse = await axiosInstance.get(
        format(API_SLUGS.DEFINITION, {
          //@ts-ignore
          apiId: selectedDefinition.apiId,
          revId: selectedDefinition.revId,
        })
      );

      setDefinition(base64ToString(definitionResponse.data?.definition));
    } catch (error) { }
  };

  return <Box
    sx={{
      pt: 2,
      maxHeight: "50em",
      overflow: "auto",
      "& .swagger-ui": {
        filter:
          localStorage.getItem("selectedTheme") === "dark"
            ? "invert(88%) hue-rotate(180deg)"
            : "",
      },
      "& .swagger-ui .highlight-code": {
        filter:
          localStorage.getItem("selectedTheme") === "dark"
            ? "invert(100%) hue-rotate(180deg)"
            : "",
      },
    }}
  >
    <Box sx={{ pl: 2 }}>
      <Typography variant="overline" color="text.secondary">
        {api?.applicationName}
      </Typography>
      <Typography variant="h5">{api?.name}</Typography>
    </Box>
    <SwaggerUI spec={definition} />
    {/* <pre>
  {definition}
</pre> */}
  </Box>
}

export default DefinitionModal;
