import { BorderColorOutlined, ContentCopy, Download, Terminal } from '@mui/icons-material';
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText, MenuItem
} from '@mui/material';
import { FC } from 'react';
import { Eye } from '../../../icons/eye';
import { Trash } from '../../../icons/trash';
import CommonPopper from '../../common/commonPopper';
import ModalWrapper from '../../common/modal/wrapper';
import AddJobSpecificationsForm from '../AddJobSpecifications/addJobSpecificationsForm';

export const ConfigActions: FC<any> = ({ apiId = undefined, jobId, toggle, open, download, deleteConf, runCommand, viewCommand }: any) => {



    const handleDownload = () => {

        toggle(jobId);
        download(jobId);
    };

    const handleDelete = () => {

        toggle(jobId);
        deleteConf(jobId);
    };

    const handleRunCommand = () => {

        toggle(jobId);
        runCommand(jobId);
    };

    const handleViewCommand = () => {

        toggle(jobId);
        viewCommand(jobId);
    };

    return (
        <>
            <CommonPopper
                elevatePopper
                trigger={<IconButton
                    id={`button-${jobId}`}
                    sx={{ transform: 'rotate(90deg)' }}
                >
                    ...
                </IconButton>}
                child={<Box sx={{ py: 1 }}>
                    <MenuItem onClick={handleViewCommand}>
                        <ListItemIcon>
                            <Eye fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>View</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDownload}>
                        <ListItemIcon>
                            <Download fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Download</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleRunCommand}>
                        <ListItemIcon>
                            <Terminal fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy run command</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ModalWrapper
                            title="Edit Configuration"
                            maxWidth="lg"
                            trigger={<Box sx={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                <ListItemIcon>
                                    <BorderColorOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>
                            </Box>}
                            child={
                                <AddJobSpecificationsForm apiId={apiId} preventRedirect configId={jobId} source="editConfig" />
                            }
                            onClose={() => toggle(jobId, true)}
                        />
                    </MenuItem>
                    <MenuItem>
                        <ModalWrapper
                            title="New Configuration"
                            maxWidth="lg"
                            trigger={<Box sx={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                <ListItemIcon>
                                    <ContentCopy fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Clone</ListItemText>
                            </Box>}
                            child={
                                <AddJobSpecificationsForm apiId={apiId} preventRedirect configId={jobId} source="listJobs" />
                            }
                            onClose={() => { toggle(jobId, true) }}
                        />
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <Trash fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem></Box>}
            />

            {/*           
            <Menu
                id={`menu-${jobId}`}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                
            </Menu> */}
        </>
    )
}