import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, TextField, Autocomplete, TextareaAutosize, Modal, Paper, Tooltip, ClickAwayListener, IconButton } from '@mui/material';
import { Close, Info } from '@mui/icons-material';
import { find } from 'lodash';
import ReactJson from 'react-json-view'

const WebhookModal = (props: any) => {
	const { formValues, handleChange, handleSubmit, testWebhook, types, open, onClose, title } = props;
	const [formErrors, setFormErrors] = useState({
		name: {
			error: false,
			message: ""
		},
		url: {
			error: false,
			message: ""
		},
		payload: {
			error: false,
			reason: ""
		}
	})
	const [show, setShow] = useState(false);
	const handleTooltipClose = () => {
		setShow(false);
	};

	const handleTooltipOpen = () => {
		setShow(true);
	};

	const validateName = (value: string) => {
		setFormErrors({ ...formErrors, name: { error: false, message: "" } });

		if (!value) {
			setFormErrors({ ...formErrors, name: { error: true, message: "Name is mandatory field" } });
			return false;
		}

		return true;
	}

	const validateUrl = (value: string) => {
		setFormErrors({ ...formErrors, url: { error: false, message: "" } });

		if (!value) {
			setFormErrors({ ...formErrors, url: { error: true, message: "URL is mandatory field" } });
			return false;
		} else {
			try {
				new URL(value)
			} catch (err) {
				setFormErrors({ ...formErrors, url: { error: true, message: "Enter valid URL" } });
				return false;
			};
		}

		return true;
	}

	const validate = () => {
		if (!validateName(formValues.name) || !validateUrl(formValues.url)) {
			return false
		}

		setFormErrors({ ...formErrors, url: { error: false, message: "" }, name: { error: false, message: "" } });
		return true;
	}

	return (
		<>
			<Modal
				open={open}
				onClose={() => { 
					setFormErrors({ ...formErrors, url: { error: false, message: "" }, name: { error: false, message: "" } });
					onClose();
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					minHeight: "100%",
					p: 3,
				}}>
					<Container maxWidth={"md"}>
						<Paper elevation={12} sx={{ p: 3 }}>
							<Close className="hand" onClick={() => { 
								setFormErrors({ ...formErrors, url: { error: false, message: "" }, name: { error: false, message: "" } });
								onClose();
							}} />
							<Typography
								color="textPrimary"
								gutterBottom
								variant="h5"
							>{title}</Typography>
							<Box>
								<TextField
									sx={{ textAlign: 'left', mb: 3 }}
									fullWidth
									name="name"
									label="Name"
									value={formValues.name}
									onChange={(e: any) => {
										handleChange(e.target.value, 'name');
									}}
									onBlur={(e: any) => {
										validateName(e.target.value);
									}}
									required
									variant="outlined"
									error={formErrors.name.error}
									helperText={formErrors.name.message}
								/>

							</Box>
							<Box>
								<TextField
									sx={{ textAlign: 'left', mb: 3 }}
									fullWidth
									name="url"
									label="URL"
									value={formValues.url}
									onChange={(e: any) => { 
										handleChange(e.target.value, 'url');
									 }}
									 onBlur={(e: any) => {
										validateUrl(e.target.value);
									}}
									required
									variant="outlined"
									error={formErrors.url.error}
									helperText={formErrors.url.message}
								/>

							</Box>
							{
								props.showAuth && (
									<Box>
										<Autocomplete
											getOptionLabel={(option): any => option?.name}
											options={types}
											value={find(types, { id: formValues.type || 'none' })}
											sx={{ textAlign: "left", mb: 3 }}
											size="medium"
											onChange={(e, value) => {
												handleChange(value?.id, "type");
											}}
											renderInput={(params): JSX.Element => {
												return (
													<TextField
														autoComplete="off"
														label="Authorization Type"
														name="type"
														variant="outlined"
														{...params}
													/>
												);
											}}
										/>
										{
											formValues.type && formValues.type !== "none" && (
												<TextField
													sx={{ textAlign: 'left', mb: 3 }}
													fullWidth
													label="Token"
													name="token"
													value={formValues.token}
													onChange={(e: any) => { handleChange(e.target.value, 'token'); }}
													variant="outlined"
												/>
											)
										}

									</Box>
								)
							}
							<Box>
								<Box sx={{
									display: "flex",
									alignItems: "center"
								}}>
									<Typography
										color="textPrimary"
										sx={{ mb: 1, textAlign: "left", maxWidth: "50%" }}
										variant="h6"
									>

										Payload
									</Typography>
									<ClickAwayListener onClickAway={handleTooltipClose}>
										<Tooltip
											componentsProps={{
												tooltip: {
													sx: {
														fontSize: "14px",
														backgroundColor: "white",
														color: "black",
														border: "1px solid black",
														padding: "12px"
													},
												},
											}}
											placement='right'
											PopperProps={{
												disablePortal: true,
											}}
											onClose={handleTooltipClose}
											open={show}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											// title="Payload contains all the attributes which are needed for the webhook "
											title="Payload is a template for mapping given webhook attributes to swrl platform attributes ['swrlMessage', 'url'].  e.g, For Discord webhook, { 'content': '{{swrlMessage}}, [View Analysis]({{url}})'} "
										>
											<IconButton style={{ padding: "0 8px", marginBottom: "8px" }} onClick={handleTooltipOpen}><Info /></IconButton>
										</Tooltip>
									</ClickAwayListener>
								</Box>
								{/* <TextareaAutosize
									name="API Template"
									value={formValues.template.toString()}
									onChange={(e: any) => { handleChange(e.target.value, 'template'); }}
									style={{
										width: "100%"
									}}
									minRows={10}
								/> */}
								 <ReactJson 
								 	enableClipboard={(e)=>{ navigator.clipboard.writeText(typeof e.src=="string"? e.src: JSON.stringify(e.src)); return true}}
									onAdd={(e)=>{ handleChange(e.updated_src, 'template')}}
									onEdit={(e)=>{ handleChange(e.updated_src, 'template')}} 
									displayDataTypes={false} 
									name={false} src={formValues.template}/>
							</Box>
							<Box>
								<Box
									sx={{
										display: 'flex',
										mt: 3
									}}
								>
									<Button
										color="primary"
										size="large"
										variant="contained"
										onClick={() => {
											if (validate()) handleSubmit();
										}}
									>
										Save
									</Button>
											&nbsp;&nbsp;
									<Button
										color="primary"
										size="large"
										variant="outlined"
										onClick={() => {
											if (validate()) testWebhook();
										}}
									>
										test
									</Button>
								</Box>
							</Box>
						</Paper>
					</Container>

				</Box>
			</Modal>
		</>
	)
}

export default WebhookModal;
