import {
    Box, Container
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { API_SLUGS } from '../../constants';
import useFetch from '../../hooks/http/useFetch';
import ProjectDetails from './ProjectDetails';
const format = require("string-template");


const Project: React.FC<RouteComponentProps> = ({ history, match }) => {
    //@ts-ignore
    const projectId = match.params?.['projectId'];
    const { data: project, loading } = useFetch(projectId ? format(API_SLUGS.GET_APPLICATION, { uuid: projectId }) : undefined)
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 1 }}
                >





                    {project && <ProjectDetails project={project} allowRedirect={false} />
                    }


                </Container>
            </Box>
        </>
    );
};

export default withRouter(Project);
