import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../configs";
import { ISidebarState, useSidebarState } from "../../store/sidebarState";
import { IUserStoreState, useUserStore } from "../../store/userState";
import AddIntegrations from "./integrations/AddIntegrations";
import OrganizationSettings from "./organization";
import PlanSettings from "./plans";
import TabGenerator from "./TabGenerator";
import Team from "./team";
import UserProfileSettings from "./userProfile";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { activePlan } = useUserStore() as IUserStoreState;
  const { fetchContext, context } = useSidebarState(
    (state) => state
  ) as ISidebarState;
  useEffect(() => {
    if (isEmpty(context)) {
      fetchContext();
    }
  }, [context]);
  const tabs = [
    {
      label: "Profile",
      value: "profile",
      component: <UserProfileSettings />,
    },
    // {
    //   label: "Integrations",
    //   value: "integrations",
    //   component: <AddIntegrations />,
    // },
    {
      label: "Organization",
      value: "organization",
      component: <OrganizationSettings />,
    },
    {
      label: "Plan",
      value: "plan",
      component: <PlanSettings />,
      visible: !getConfigs().isOmnibus
      // visible: !context?.isPersonal
    },
    // {
    //   label: "Team Members",
    //   value: "team",
    //   component: <Team />,
    //   visible: !getConfigs().isOmnibus
    //   // visible: activePlan?.price>0,
    // },
  ];

  return <TabGenerator tabs={tabs} activeTab={activeTab} />;
};

export default withRouter(Settings);
