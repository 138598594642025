import {
  Button,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { InformationCircleOutlined } from "../../../icons/information-circle-outlined";
import CommonPopper from "../../common/commonPopper";
import { regexes } from "./regexHelpers";

const Regex: React.FC<any> = ({
  data,
  onSubmit,
  onChange,
  allowSave = false,
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [formValues, setFormValues] = useState({} as any);
  const [isValidRegex, setIsValidRegex] = useState(true);

  useEffect(() => {
    if (typeof data.regex === "string") {
      setFormValues({ ...data });
    }
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    setHasChanged(false);
  };

  const handleChange = (value: any, type: "regex") => {
    let newFormValues = { ...formValues, [type]: value };
    setFormValues(newFormValues);
    onChange(newFormValues);
    setHasChanged(true);
    if (type === "regex") {
      var isValid = true;
      try {
        new RegExp(value);
      } catch (e) {
        isValid = false;
      }
      setIsValidRegex(isValid);
    }
  };

  return (
    <Box sx={{ display: "inline", width: "100%" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            label="Regex"
            sx={{ mr: 1 }}
            margin="normal"
            hiddenLabel
            size="small"
            InputLabelProps={{
              shrink:
                formValues &&
                formValues.regex &&
                formValues?.regex?.trim() !== "",
            }}
            value={formValues.regex}
            error={!isValidRegex}
            helperText={!isValidRegex && "Invalid Regex"}
            variant="outlined"
            onChange={(e: any) => {
              handleChange(e.target.value, "regex");
            }}
            inputProps={{ "data-testid": "email" }}
          />
          <CommonPopper
            elevatePopper
            trigger={
              <Tooltip title="Common Regexes">
                <InformationCircleOutlined
                  className="hand"
                  fontSize="small"
                  sx={{ pr: 1 }}
                />
              </Tooltip>
            }
            child={
              <>
                <Box sx={{ pl: 2, pt: 2 }}>
                  <Typography variant="h6">Common Regexes</Typography>
                  <Typography variant="body1">
                    Click on anyone to use it
                  </Typography>
                </Box>
                <List sx={{ maxHeight: "20em", overflow: "scroll" }}>
                  {regexes.map((regex) => {
                    return (
                      <ListItem
                        className="hand"
                        key={regex.input_keyword}
                        onClick={() =>
                          handleChange(regex.regex_pattern, "regex")
                        }
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {regex.keyword}
                        </span>{" "}
                        - {regex.description}
                      </ListItem>
                    );
                  })}
                </List>
              </>
            }
          />

          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              disabled={
                (!allowSave &&
                  (!hasChanged ||
                    formValues.regex === undefined ||
                    formValues.regex.trim() === "")) ||
                !isValidRegex
              }
              size="small"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default Regex;
