import { BorderColorOutlined, Terminal } from '@mui/icons-material';
import { Chip, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import AddApplicationForm from '../applications/AddApplication/AddApplicationForm';

import ModalWrapper from '../common/modal/wrapper';
import EnvironmentsTable from '../management/ViewEnvironments/EnvironmentsTable';



const ProjectsList: FC<any> = ({ onSelect, selectedProject, projects }) => {

    return (
        <List sx={{maxHeight: "30em", overflow:"auto"}}>
            {projects.map((project: any, index: any) => (
                <ProjectItem project={project} onSelect={onSelect} selectedProject={selectedProject} index={index} />
            ))}
        </List>
    );
}

const ProjectItem: FC<any> = ({ project, onSelect, selectedProject, index }) => {
    const [hover, setHover] = useState(false)
    return (
        <ListItem onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            className="hand" key={index} onClick={() => onSelect(project?.id)} sx={{ background: project?.id === selectedProject || hover ? "#e5e5e5" : "white", }}>
            <ListItemText primary={project?.name} sx={{ fontWeight: project?.id === selectedProject || hover ? "600" : "normal" }} />
            {(project?.id === selectedProject || hover) && <>
                <ModalWrapper
                    maxWidth="lg"
                    title={`${project?.name} - Edit`}
                    trigger={
                        <Chip
                            size="small"
                            color="primary"
                            variant="outlined"
                            icon={<Tooltip title="Edit"><BorderColorOutlined /></Tooltip>}


                            sx={{ border: "none" }}
                            onClick={() => { }}
                        />
                    }
                    child={<AddApplicationForm

                        defaultFormValues={{
                            application: project.name,
                        }}
                        preventRedirect
                        callback={() => {
                            window.location.reload()
                        }}
                        updateOnly
                        defaultAllowEdit={false}
                        updateUUID={project.id}
                    />}
                />
                <ModalWrapper
                    maxWidth="lg"
                    title={`${project?.name} - Environments`}
                    trigger={
                        <Chip
                            size="small"
                            color="primary"
                            variant="outlined"
                            icon={<Tooltip title="Environments"><Terminal /></Tooltip>}


                            sx={{ border: "none" }}
                            onClick={() => { }}
                        />
                    }
                    child={<EnvironmentsTable applicationId={project.id} />}
                />
            </>}
        </ListItem>
    );
}

export default ProjectsList;