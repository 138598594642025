import { Close } from "@mui/icons-material";
import {
  Box, Card, Chip, Container, Grid, Modal,
  Paper, Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../../../configs";
import { SEVERITY_OPTIONS } from "../../../../constants";
import { axiosInstance } from "../../../../services/axios";
import DefinitionModal from "../../../applications/DefinitionModal";
import { DataCard } from "../../../dashboard";
import { IDataTile } from "../runComponents/ResultData";
import ActionButton from "../shared/button";
import WidgetHandler from "./WidgetHandler";
var format = require("string-template");
var jp = require("jsonpath");

const templates: any = {
  "sift_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Operation"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.operationId"
      },
    },
    extras: {
      "title": {
        type: "keyPath",
        value: "fault.faultId"
      },
      "text": {
        type: "keyPath" || "const",
        value: "fault.faultName"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "sequence",
    data: "fault.sequence"
  }
  ],
  "semgrep_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }
    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },

  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "trivy_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Package Name"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.PkgName"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "gitleaks_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "trivy_license_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.location"
      },


    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],

  "fallback": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ]
}

export const IncidentData: FC<any> = ({
  id,
  history,
  findingId,
  setIncidentData,
}) => {
  const [incident, setIncident] = useState({} as any);
  const [latestFindingInfo, setLatestFindingInfo] = useState({} as any);
  const [openDefinitionModal, setOpenDefinitionModal] = useState(false);
  const [result, setResult] = useState({} as any);
  const [incidentDataTiles, setIncidentDataTiles] = useState([] as IDataTile[]);

  useEffect(() => {
    if (result) {
      const xs = 12;
      const md = 6;
      const data: IDataTile[] = [
        {
          title: "API / Operation",
          description: `${incident?.apiName} / 
          ${latestFindingInfo && latestFindingInfo?.findingDetail
              ? latestFindingInfo?.findingDetail?.operationId
              : null
            }`,
          onClick: handleOpenDefinitionModal,
          xs,
          md,
        },
        {
          title: "Environment",
          description: incident.environmentName,
          xs,
          md,
        },
      ];
      setIncidentDataTiles(data);
    }
  }, [incident, latestFindingInfo]);

  const fetchResult = async (id: string) => {
    try {
      let response = await axiosInstance.get(format(`/results/${id}`));
      setResult(response?.data);
    } catch (error: any) { }
  };

  useEffect(() => {
    fetchLatestFindingInfo();
  }, []);

  const fetchLatestFindingInfo = async () => {
    let findingDetailResponse: any = {};
    let faultResponse: any = {};
    try {
      let findingResponse = await axiosInstance.get(

        `/findings?limit=1&sort=-timestamp&incidentId=${id}`

      );

      let finding = findingResponse?.data?.findings
        ? findingResponse?.data?.findings[0]
        : null;

      if (finding) {
        findingDetailResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/findings/${findingId || finding?.id}`
          )
        );

        faultResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/faults/${finding?.faultId}`
          )
        );
      }

      fetchResult(findingDetailResponse?.data?.resultId);
    } catch (error: any) { }

    setLatestFindingInfo({
      findingDetail: findingDetailResponse?.data,
      sequence: findingDetailResponse?.data?.sequence,
      fault: { id: findingDetailResponse?.data?.faultId, ...faultResponse?.data },
    });
  };

  useEffect(() => {
    fetchIncident();
  }, []);

  const handleOpenDefinitionModal = () => {
    setOpenDefinitionModal(true);
  };

  const handleCloseDefinitionModal = () => {
    setOpenDefinitionModal(false);
  };

  const fetchIncident = async () => {
    try {
      let response = await axiosInstance.get(format(`/incidents/${id}`));
      setIncident(response?.data);
      setIncidentData(response?.data);
    } catch (error: any) { }
  };



  return (
    <>

      <Box sx={{ pl: 1 }}>
        <Typography variant="overline"
          sx={{ fontSize: "12px", display: "block" }}
          color="text.secondary" onClick={() => history.push(`/findings?incidentId=${id}`)}>
          Issue&nbsp;

          #{incident?.index}

        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 600,lineHeight: 0.75 }} >
          {incident?.findingName}
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          mt: 3,
        }}
      >

        <Box sx={{ ml: 1, mr: 2, textAlign: "left" }} >

          {
            (templates?.[latestFindingInfo?.findingDetail?.schema] || templates?.["fallback"])?.map((widget: any, index: number) =>
              <WidgetHandler widget={widget} info={{ finding: latestFindingInfo, incident }} />
            )
          }

        </Box>



        <Modal open={openDefinitionModal} onClose={handleCloseDefinitionModal}>
          <DefinitionModal
            onClose={handleCloseDefinitionModal}
            selectedDefinition={{
              apiId: incident?.apiId,
              revId: result?.apiRevisionId,
            }}
          />
        </Modal>
      </Box>
    </>
  );
};

const IncidentView: FC<any> = ({ history, match, id, location }) => {
  const [selectedFindingId, setSelectedFindingId] = useState(
    null as string | null
  );
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const resultId = query.get("resultId");
  const findingId = query.get("findingId");
  const [incidentId, setIncidentId] = useState(id);
  const [incident, setIncident] = useState({} as any);
  const [selectedFaultGroup, setSelectedFaultGroup] = useState("");
  const [showHistory, setShowHistory] = useState(!incidentId as boolean);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [apiId, setApiId] = useState(null);
  const [revId, setRevId] = useState(null);

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid xs={12} md={8}>
          <Card
            elevation={0}
            sx={{
              pl: 2,
              textAlign: "left",
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            {/* <Modal
              open={openOperationModal}
              onClose={handleCloseOperationModal}
            >
              <OperationModal
                onClose={handleCloseOperationModal}
                selectedOperation={{
                  apiId: apiId!,
                  revId: revId!,
                  defaultOperationId: selectedOperationId,
                }}
              />
            </Modal> */}
            <IncidentData
              id={incidentId}
              history={history}
              findingId={findingId}
              setIncidentData={setIncident}
            />
            <Grid xs={12}>
              <Box
                sx={{
                  display: showHistory ? "block" : "none",
                }}
              >
                <DataCard
                  name={""}
                  config={{
                    widget: "table",
                    enableContextFilter: true,
                    width: 8,
                    data_source: `${getConfigs().baseApiUrl
                      }/findings?faultGroupId=${selectedFaultGroup}&incidentId=${incidentId}&limit={limit}&offset={offset}`,
                    data_points: [
                      { key: "id", jsonpath: "$.findings[*].id" },
                      { key: "api", jsonpath: "$.findings[*].apiName" },
                      {
                        key: "operation",
                        jsonpath: "$.findings[*].operationId",
                      },
                      { key: "target", jsonpath: "$.findings[*].target" },
                      { key: "name", jsonpath: "$.findings[*].name" },
                      { key: "faultId", jsonpath: "$.findings[*].faultId" },
                      {
                        key: "incidentId",
                        jsonpath: "$.findings[*].incidentId",
                      },
                      {
                        key: "incidentNumber",
                        jsonpath: "$.findings[*].incidentNumber",
                      },
                      { key: "runId", jsonpath: "$.findings[*].resultId" },
                      { key: "findingType", jsonpath: "$.findings[*].type" },
                      { key: "severity", jsonpath: "$.findings[*].severity" },
                      {
                        key: "environment",
                        jsonpath: "$.findings[*].environmentName",
                      },
                      { key: "method", jsonpath: "$.findings[*].method" },
                      { key: "isNew", jsonpath: "$.findings[*].isNew" },
                    ],
                    pagination: true,
                    pagination_limit: 20,
                    columns: [
                      { key: "id", name: "ID", type: "hidden" },

                      {
                        key: "faultId",
                        name: "Fault",
                        type: "string",
                        width: "100px",
                        onClick: (row: any) => {
                          window.open(
                            `/findings?incidentId=${row?.incidentId}&findingId=${row?.id}`,
                            "_blank"
                          );
                        },
                      },
                      {
                        key: "severity",
                        name: "Severity",
                        type: "tag",
                        width: "150px",
                        columnHeaderFilter: true,
                        columnHeaderFilterOptions: SEVERITY_OPTIONS,
                      },
                      {
                        key: "isNew",
                        name: "",
                        type: "custom",
                        code: (row: any) => (
                          <>
                            {row?.isNew ? (
                              <Chip
                                size="small"
                                color="success"
                                label="New"
                              ></Chip>
                            ) : null}
                          </>
                        ),
                        columnHeaderFilter: true,
                        columnHeaderFilterOptions: [
                          { key: true, label: "New" },
                        ],
                      },
                      { key: "operation", name: "Operation" },
                      {
                        key: "incidentId",
                        name: "Incident UUID",
                        type: "hidden",
                      },
                      {
                        key: "runId",
                        name: "Run Id",
                        width: 100,
                        type: "custom",
                        code: (row: any) =>
                          row?.runId ? row?.runId.split("-")[0] : "",
                        onClick: (row: any) =>
                          history.push(`findings?resultId=${row?.runId}`),
                      },
                      {
                        key: "name",
                        name: "Name",
                        type: "string",
                        isSearchable: true,
                        width: "300px",
                      },
                    ],
                  }}
                />
              </Box>
            </Grid>
          </Card>
        </Grid>

        <Grid xs={12} md={4}>
          <Card
            elevation={0}
            sx={{ pt: 2, textAlign: "left", minHeight: "85vh" }}
          >
            <Box sx={{ pl: 1 }}>
              {!isEmpty(incident) ? (
                <ActionButton
                  incidentId={incident?.id}
                  buttons="STATE"
                  state={incident?.state}
                  risk={incident?.risk}
                  expandedView
                  user={incident?.assignee}
                  jira={{
                    url: incident?.jiraUrl,
                    number: incident?.jiraNumber,
                  }}
                />
              ) : null}
            </Box>
            <hr style={{ border: "3px solid #F5F5F5" }} />
            <Box sx={{ pl: 1 }}>
              <Typography variant="overline">Fault History</Typography>
            </Box>
            <DataCard
              name={""}
              config={{
                widget: "table",
                enableContextFilter: true,
                hideSearch: true,
                width: 8,
                data_source: `${getConfigs().baseApiUrl
                  }/findings?faultGroupId=${selectedFaultGroup}&${resultId
                    ? `resultId=${resultId}&`
                    : incidentId
                      ? `incidentId=${incidentId}&`
                      : ""
                  }limit={limit}&offset={offset}`,
                data_points: [
                  { key: "id", jsonpath: "$.findings[*].id" },

                  { key: "runId", jsonpath: "$.findings[*].resultId" },
                  { key: "timestamp", jsonpath: "$.findings[*].timestamp" },
                  {
                    key: "incidentId",
                    jsonpath: "$.findings[*].incidentId",
                  },
                ],
                pagination: true,
                pagination_limit: 20,
                columns: [
                  { key: "id", name: "ID", type: "hidden" },
                  {
                    key: "incidentId",
                    name: "Incident UUID",
                    type: "hidden",
                  },
                  {
                    key: "runId",
                    name: "Run Id",
                    width: 100,
                    type: "custom",
                    code: (row: any) =>
                      row?.runId ? row?.runId.split("-")[0] : "",
                    onClick: (row: any) => {
                      window.open(
                        `/findings?incidentId=${row?.incidentId}&findingId=${row?.id}`,
                        "_blank"
                      );
                    },
                  },
                  { key: "timestamp", name: "Found At", type: "dateTime" },
                ],
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={selectedFindingId != null}
        onClose={() => setSelectedFindingId(null)}
      >
        <Box
          sx={{
            minHeight: "100%",
            overflow: "auto",
            p: 3,
          }}
        >
          <Container maxWidth="lg">
            <Paper elevation={12} sx={{ p: 3 }}>
              <Close
                className="hand"
                onClick={() => setSelectedFindingId(null)}
              />

              <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                <IncidentData
                  id={incidentId}
                  history={history}
                  findingId={selectedFindingId}
                />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default withRouter(IncidentView);
