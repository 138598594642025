import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { getConfigs } from "../../../configs";
import { API_SLUGS } from "../../../constants";
import ModalWrapper from "../../common/modal/wrapper";
import { DataCard } from "../../dashboard";
import InputCell from "../AddApi/InputCell";
import { DefinitionDetails } from "../DefinitionModal";
import { GraphDetails } from "../GraphModal";

const RevisionsTable: FC<any> = ({ apiId }) => {
    return <Box sx={{ pt: 4 }}>
        <DataCard
            name={"Revision Table"}
            config={{
                name: "Revisions",
                widget: "table",
                width: 8,
                data_source: `${getConfigs().baseApiUrl
                    }/apis/${apiId}/revisions`,
                data_points: [
                    { key: "id", jsonpath: "$.revisions[*].id" },
                    { key: "name", jsonpath: "$.revisions[*].name" },
                ],
                pagination: false,
                columns: [
                    {
                        key: "name",
                        name: "Name",
                        type: "custom",
                        code: (row: any) => {
                            return (
                                <InputCell
                                    input={row.name}
                                    name="revision-name"
                                    label="Revision Name"
                                    apiDetails={{
                                        url: API_SLUGS.UPDATE_REVISION,
                                        urlFormatter: {
                                            uuid: apiId,
                                            revId: row.id,
                                        },
                                        requestField: "name",
                                    }}
                                />
                            );
                        },
                    },
                    {
                        key: "",
                        name: "",
                        type: "custom",
                        code: (row: any) => {
                            return [
                                <ModalWrapper
                                    maxWidth="lg"
                                    title="Resource Graph"
                                    trigger={<Button

                                    >
                                        Resource Graph
                                    </Button>}
                                    child={
                                        <GraphDetails

                                            selectedGraph={{ apiId: apiId, revId: row?.id }}
                                        />
                                    }
                                />,

                                <ModalWrapper
                                    maxWidth="lg"
                                    title="Definition"
                                    trigger={<Button

                                    >
                                        Definition
                                    </Button>}
                                    child={
                                        <DefinitionDetails

                                            selectedDefinition={{ apiId: apiId, revId: row?.id }}
                                        />
                                    }
                                />
                            ];
                        },
                    },
                    {
                        key: "id",
                        name: "ID",
                        type: "id",
                    },
                ],
            }}
        />
    </Box>
}

export default RevisionsTable;