import { Download, Key, Sync } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/http/useFetch";
import usePatch from "../../hooks/http/usePatch";
import downloadFile from "../../utils/downloadFile";
import CommonPopper from "../common/commonPopper";

const DownloadPostmanPopup: React.FC<any> = ({ apiId, history, sx, trigger=undefined }) => {
  return (
    <Box>
      <CommonPopper
        trigger={trigger || <Chip variant="outlined" color="primary"  icon={<Download />} label="Download" />}
        child={
          <Card elevation={0} sx={{ display: "flex", justifyContent: "start" }}>
            <CardContent>
              <Typography variant="h6" sx={{ pb: 1 }}>
                Download Postman Collection
              </Typography>

              <DownloadButton apiId={apiId} />
              <Divider sx={{ my: 2 }} />
              <PostmanSection apiId={apiId} history={history} />
            </CardContent>
          </Card>
        }
      />
    </Box>
  );
};
const PostmanSection = ({ apiId, history }: any) => {
  return (
    <>
      
      <Typography variant="h6" sx={{ pb: 1 }}>
        Auto-Sync Postman Collection
      </Typography>
      <Tooltip title="If turned on, everytime a new revision is added, sample tests will automatically be synced to your postman.">
        <PostmanButton apiId={apiId} history={history}/>
      </Tooltip>
    </>
  );
};

const PostmanButton = ({ apiId, history }: any) => {
  const { data: postmanIntegrationData } = useFetch(
    `/cloudintegration?type=postman`
  );
  const { loading: syncing, patchData } = usePatch(`/oas/sync?apiId=${apiId}`);
  
  return postmanIntegrationData?.postman?.isActive ? (
    <Chip
      className="hand"
      color="primary"
      size="small"
      variant={"filled"}
      sx={{ ml: 1 }}
      disabled={syncing}
      label={syncing ? "Syncing..." : "Sync"}
      onClick={() => patchData({}, "Synced completed", true)}
      icon={<Sync />}
    />
  ) : (
    <Chip
      className="hand"
      color="primary"
      size="small"
      variant={"outlined"}
      sx={{ ml: 1 }}
      label={"Link Postman API Key"}
      onClick={()=>history.push(`/settings?tab=integrations`)}
      icon={<Key />}
    />
  );
};

const DownloadButton = ({ apiId }: any) => {
  
  const { data, loading, fetchData } = useFetch(undefined);

  const handleClick = async () => {
    await fetchData(`/oas/export?apiId=${apiId}`);
  }

  React.useEffect(() => {
    if (data) {
      downloadFile(data, "postman", "JSON");
    }
  }, [data]);

  return (
    <Chip
      className="hand"
      color="primary"
      size="small"
      sx={{ ml: 1 }}
      
      label={loading ? "Downloading...": "Download"}
      disabled={loading}
      icon={<Download />}
      onClick={handleClick}
    />
  );
};

export default withRouter(DownloadPostmanPopup);
