import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";

const Default: React.FC<any> = ({ data, onSubmit, onChange, allowSave=false }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [formValues, setFormValues] = useState({ ...data } as any);

  useEffect(() => {
    setFormValues({ ...data });
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    setHasChanged(false);
  };

  return (
    <Box sx={{ display: "inline", width: "100%" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              size="small"
              variant="contained"
              disabled={!allowSave&& !hasChanged}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default Default;
