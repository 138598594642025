import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, HELPER_TEXTS, STRINGS } from "../../../constants";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
var format = require("string-template");

interface IError {
  error: boolean;
  message: string;
}

interface IAddGroupFormError {
  group?: IError;
}

interface IAddGroupFormValues {
  group: string;
}

interface IGroup {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean;
  defaultFormValues?: any;
  preventRedirect?: boolean;
  updateUUID?: string;
}

const AddGroupForm: FC<IAddForm> = ({
  history,
  match,
  preventRedirect=false,
  updateOnly = false,
  defaultFormValues,
  updateUUID,
}) => {
  const [formValues, setFormValues] = useState({
    group: "",
    ...defaultFormValues,
  } as IAddGroupFormValues);
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);


  const handleChange = async (value: any, type: "group") => {
    let newValues = { ...formValues, [type]: value };

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (updateOnly) {
      if (formValues.group.trim() != "") {
        await updateGroup(formValues.group.trim());
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    } else {
      if (formValues.group.trim() != "") {
        await addGroup(formValues.group.trim());
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    }
  };

  const addGroup = async (name: string) => {
    try {
      let groupResponse = await axiosInstance.post(
        API_SLUGS.ADD_GROUP,
        { name: name }
      );
      if (!preventRedirect) {
        history.push("/groups");
      }
      setMessage({ title: "Group has been added", type: "success" });
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) {}
    }
  };

  const updateGroup = async (name: string) => {
    try {
      await axiosInstance.patch(
        format(API_SLUGS.UPDATE_GROUP, { id: updateUUID }),
        { name: name }
      );
      if(!preventRedirect){
      history.push("/groups");
      }
      setMessage({ title: "Group has been updated", type: "success" });
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) {}
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Group
          </Typography>
          <TextField
            autoComplete="off"
            sx={{ maxWidth: "50%", textAlign: "left" }}
            size="medium"
            fullWidth
            name="Group"
            helperText={HELPER_TEXTS?.add_group?.group}
            defaultValue={formValues.group}
            onChange={(e: any) => {
              handleChange(e.target.value, "group");
            }}
            required
            variant="outlined"
          />
        </Grid>
      </Grid>
      { errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        
          <Button
            color="primary"
            size="medium"
            variant="contained"
            
            onClick={handleSubmit}
          >
            Save
          </Button>
        
        {updateOnly ? (
          <Button
            color="primary"
            size="large"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={() => {
              history.push("/groups");
            }}
          >
            Cancel
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default withRouter(AddGroupForm);
