import { Settings } from "@mui/icons-material";
import { Chip, Modal, SxProps } from "@mui/material";
import { isEmpty } from "lodash";
import { FC, useState } from "react";
import { IAlertState, useAlertState } from "../../store/alertState";
import ApiModal from "../applications/ApiModal";
import DefinitionModal from "../applications/DefinitionModal";
import GraphModal from "../applications/GraphModal";
import OperationModal from "../applications/OperationsModal";
import RevisionModal from "../applications/RevisionModal";


type EnabledActions =
  | "SETTINGS"
  | "RESOURCE_GRAPH"
  | "DEFINITION"
  | "REVISIONS"
  | "COPY";

interface IAPIActions {
  row: any;
  enabledActions: EnabledActions[];
  sx?: SxProps
}
const APIActions: FC<IAPIActions> = ({ row, enabledActions, sx = {} }) => {
  const [selectedRevision, setSelectedRevision] = useState(
    null as null | string
  );
  const [selectedApi, setSelectedApi] = useState(null);
  const [openGraphModal, setOpenGraphModal] = useState(false);
  const [openOperationModal, setOpenOperationModal] = useState(false);
  const [openRevisionModal, setOpenRevisionModal] = useState(false);
  const [openApiModal, setOpenApiModal] = useState(false);
  const [openDefinitionModal, setOpenDefinitionModal] = useState(false);
  

  const handleOpenGraphModal = (uuid: string) => {
    setSelectedRevision(uuid);
    setOpenGraphModal(true);
  };

  const handleCloseGraphModal = () => {
    setSelectedRevision(null);
    setOpenGraphModal(false);
  };

  const handleOpenOperationModal = (uuid: string) => {
    setSelectedRevision(uuid);
    setOpenOperationModal(true);
  };

  const handleCloseOperationModal = () => {
    setSelectedRevision(null);
    setOpenOperationModal(false);
  };
  const handleOpenRevisionModal = () => {
    setOpenRevisionModal(true);
  };

  const handleCloseRevisionModal = () => {
    setSelectedRevision(null);
    setOpenRevisionModal(false);
  };

  const handleOpenApiModal = () => {
    setOpenApiModal(true);
  };

  const handleCloseApiModal = () => {
    setSelectedRevision(null);
    setOpenApiModal(false);
  };

  const handleOpenDefinitionModal = (uuid: string) => {
    setSelectedRevision(uuid);
    setOpenDefinitionModal(true);
  };

  const handleCloseDefinitionModal = () => {
    setSelectedRevision(null);
    setOpenDefinitionModal(false);
  };

  const isActionEnabled = (action: EnabledActions) => {
    return enabledActions.indexOf(action) > -1;
  };

  return (
    <>
      {isActionEnabled("SETTINGS") && (

        <Chip
          color="default"
          className="hand"
          sx={{ mt: -2, ...sx }}
          onClick={() => {
            setSelectedApi(row?.id);
            handleOpenApiModal();
          }}
          icon={<Settings />}
          label="Settings"
        />

      )}
      {isActionEnabled("DEFINITION") && (
        <Chip
          size="small"
          sx={{  mr: 1 }}
          
          variant="outlined"
          disabled={isEmpty(row?.currentRevision)}
          onClick={() => {
            setSelectedApi(row?.id);
            handleOpenDefinitionModal(
              !isEmpty(row?.currentRevision) ? row?.currentRevision.id : null
            );
          }}
          label={"View Definition"}
        />
      )}
      {isActionEnabled("RESOURCE_GRAPH") && (
        <Chip
          size="small"
          sx={{  mr: 1 }}
          
          variant="outlined"
          disabled={isEmpty(row?.currentRevision)}
          onClick={() => {
            setSelectedApi(row?.id);
            handleOpenGraphModal(
              !isEmpty(row?.currentRevision) ? row?.currentRevision.id : null
            );
          }}
          label={"Resource Graph"}
        />
      )}

      {isActionEnabled("REVISIONS") && (
        <Chip
          size="small"
          sx={{  mr: 1 }}
          
          variant="outlined"
          disabled={isEmpty(row?.currentRevision)}
          onClick={() => {
            setSelectedApi(row?.id);
            handleOpenRevisionModal();
          }}
          label={"Revisions"}
        />
      )}

      <Modal open={openGraphModal} onClose={handleCloseGraphModal}>
        <GraphModal
          onClose={handleCloseGraphModal}
          selectedGraph={{ apiId: selectedApi!, revId: selectedRevision! }}
        />
      </Modal>

      <Modal open={openOperationModal} onClose={handleOpenOperationModal}>
        <OperationModal
          onClose={handleCloseOperationModal}
          selectedOperation={{ apiId: selectedApi!, revId: selectedRevision! }}
        />
      </Modal>

      <Modal open={openRevisionModal} onClose={handleOpenRevisionModal}>
        <RevisionModal
          onClose={handleCloseRevisionModal}
          apiId={selectedApi!}
        />
      </Modal>

      <Modal open={openApiModal} onClose={handleOpenApiModal}>
        <ApiModal onClose={handleCloseApiModal} apiId={selectedApi!} />
      </Modal>

      <Modal open={openDefinitionModal} onClose={handleCloseDefinitionModal}>
        <DefinitionModal
          onClose={handleCloseDefinitionModal}
          selectedDefinition={{ apiId: selectedApi!, revId: selectedRevision! }}
        />
      </Modal>
    </>
  );
};

export default APIActions;
