export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

export const STRINGS = {
  EMPTY: "",
  LOCAL_STORAGE_TOKEN: "token",
  LOCAL_STORAGE_USER: "user",
  EMAIL_ADDRESS: "Email Address",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  PASSWORD: "Password",
  SIGNUP: "Sign Up",
  LOGIN: "Login",
  IDP_LOGIN: "IDP Login",
  LOGOUT: "Logout",
  INBOX: "Inbox",
  USERNAME: "Username",
  ORGANIZATION_NAME: "Organization Name",
  SIGNIN_GITHUB: "Signin with github",
  SIGNIN_VMONE: 'Signin with Workspace one',
  PASSCODE: "Passcode",
  LOCAL_STORAGE_REDIRECT_TO: "redirectTo",
};

export const ERROR_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVER_TIMEOUT: "ECONNABORTED",
};

export const ERRORS = {
  FIRST_NAME_EMPTY: "First Name cannot be empty",
  LAST_NAME_EMPTY: "Last Name cannot be empty",
  EMAIL_EMPTY: "Email cannot be empty",
  INVALID_EMAIL: "Enter valid email",
  PASSWORD_EMPTY: "Password cannot be empty",
  PASSWORD_LENGTH_SHORT: "Password needs to be at least 8 characters long",
  PASSWORDS_NOT_SAME: "Passwords don't match",
  SERVER_TIMEOUT: "Server Timeout",
  PASSCODE_EMPTY: "Passcode cannot be empty",
  PASSCODE_INVALID: "Enter Valid Passcode",
  PERMISSION_DENIED: "You don't have permission to take that action",
};

export const API_SLUGS = {
  LOGIN: "/user/login",
  LOGIN_METHOD: "user/loginType",
  IDP_LOGIN: "/user/login/sso",
  SIGNIN_GITHUB: "/user/auth/github",
  SIGNIN_VMONE: "/user/auth/workspace",
  FT_AUTH_HANDLER: "/user/auth/federatedtoken/{token}",
  SIGNUP: "/user/signup",
  MESSAGES: "/message",
  MESSAGES_SUMMARY: "/message/summary",
  MESSAGE: "/message/{uuid}",
  MARK_MESSAGE: "/message/{uuid}",
  ALERTS: "/alert",
  ALERT: "/alert/{uuid}",
  MARK_ALERT: "/alert/{uuid}",
  USER_PROFILE_SETTINGS: "/user",
  CHANGE_PASSWORD: "/user/password",
  CURRENT_USER_DETAILS: "/user/current",
  SLACK_CONNECT: "/user/slack",
  SLACK_DISCONNECT: "/user/slackdisconnect",
  ADD_ANALYZER: "/analyzer",
  ADD_BATCH_ANALYZER: "/analyzer/batch-upload",
  ANALYZERS: "/analyzer?{contextLevel}={uuid}",
  ANALYZER: "/analyzer/{uuid}",
  ANALYZER_SUMMARY: "/summary/dashboard-overview?{contextLevel}={uuid}",
  DELETE_ANALYZERS: "/analyzer/batch-delete",
  EXECUTE_ANALYZERS: "/analyzer/execute",
  ADD_ANALYZER_VERSION: "/analyzer/{uuid}/version",
  ANALYZER_VERSIONS: "/analyzer/{uuid}/version",
  DELETE_ANALYZER_VERSIONS: "/analyzer/{uuid}/version/batch-delete",
  IDP_MAPPINGS: "/role/idp-roles",
  SAVE_IDP_MAPPINGS: "/role/idp-roles",
  ROLES: "/role",
  ROLE: "/role/{uuid}",
  ADD_ROLE: "/role",
  EDIT_ROLE: "/role/{uuid}",
  DELETE_ROLES: "/role/batch-delete",
  PERMISSIONS: "/permission",
  SUMMARY: "/summary",
  DASHBOARDS: "/dashboard",
  APPLICATIONS: "/applications",
  APPLICATIONS_BY_GROUP: "/applications?groupId={groupId}",
  GET_APPLICATION: "/applications/{uuid}",
  ADD_APPLICATION: "/applications",
  UPDATE_APPLICATION: "/applications/{id}",
  SERVICES: "/application/{uuid}/services",
  GET_SERVICE: "/service/{uuid}",
  ADD_SERVICE: "/service",
  APIS: "/apis?applicationId={applicationId}",
  GET_API: "/apis/{uuid}",
  UPDATE_API: "/apis/{id}",
  ADD_API: "/apis",
  ADD_ASSET: "/assets",
  GRAPH: "/apis/{apiId}/revisions/{revId}/ddg",
  UPDATE_REVISION: "/apis/{uuid}/revisions/{revId}",
  LIST_REVISIONS: "/apis/{uuid}/revisions",
  DEFINITION: "/apis/{apiId}/revisions/{revId}/definition",
  BUBBLE_CHART: "/results/chart-data?apiUUID={uuid}{resultCount}",
  ADD_ENVIRONMENT: "/applications/{applicationId}/environments",
  GET_ENVIRONMENT: "/environments/{id}",
  UPDATE_ENVIRONMENT: "/applications/{applicationId}/environments/{id}",
  ENVIRONMENTS: "/environments",
  INSIGHTS_ENVIRONMENTS:
    "/insights/environments?applicationIds={applicationIds}",
  ADD_PLATFORM_KEY: "/user/accesstokens",
  REVOKE_PLATFORM_KEY: "/user/accesstokens/{id}",
  ADD_JOB_SPEC: "/jobs",
  ADD_ORGANIZATION: "/organization",
  ORGANIZATIONS: "/organization",
  SET_CONTEXT: "/user/context",
  GET_CONTEXT: "/user/context",
  GET_USER_INVITES: "/user/invites",
  ACTION_USER_INVITES: "/user/invites/{id}",
  SEND_INVITES: "/invites",
  GET_INVITES: "/invites",
  ACTION_INVITES: "/invites/{id}",
  POST_TIMELINE: "/incidents/{id}/timeline",
  ALLOWED_DOMAINS: "/jobs/allowedDomains",
  GET_ORGANIZATION_MEMBERS: "/organization/members",
  FAULT_ANALYZERS: "/faultanalyzers",
  JIRA_PROJECTS: "/jira/projects",
  POST_JIRA_ISSUE: "/jira/issue",
  MFA_SETTINGS: "/user/mfa/settings",
  MFA_QRCODE: "/user/mfa/qrcode",
  MFA_VERIFY: "/user/mfa/verifytotp",
  RUN_CONFIGURATIONS: "/run/configurations",
  GET_PLANS: "/plans",
  GET_OPERATIONS: "/apis/{apiId}/operations",
  GET_OPERATIONS_BY_RESULT: "/results/{resultId}/operations",
  GET_OPERATIONS_PERFORMANCE_BY_RESULT: "/results/{resultId}/operations/temp",
  GET_OPERATIONS_BY_REVISION: "/apis/{apiId}/revisions/{revisionId}/operations",
  GET_USER_OPERATIONS_SETTINGS: "/apis/{apiId}/operations/resourceprofiles",
  GET_PERMISSION_BLACKLIST: "/plans/permissions?type='restricted'",
  GET_ACTIVE_PLAN: "/activeplan",
  GET_GROUP: "/group/{id}",
  ADD_GROUP: "/group",
  UPDATE_GROUP: "/group/{id}",
  ADD_MEMBER: "/group/{groupId}/member",
  UPDATE_MEMBER: "/group/{groupId}/member/{id}",
  ORG_MEMBERS: "/organization/members",
  UPDATE_ORG_MEMBER: "/organization/members/{id}",
};

export const ROUTES = {
  JOIN: "/join",
  LOGIN: "/login",
  SIGNUP: "/sign-up",
  INBOX: "/inbox",
  TEAM: "/team",
  ALERTS: "/alerts",
  DASHBOARD: "/",
  SETTINGS: "/settings",
  USER_PROFILE_SETTINGS: "/settings/user-profile",
  IDP_MAPPING_SETTINGS: "/idp-mappings",
  CHANGE_PASSWORD: "/settings/change-password",
  IDP_REDIRECT_AUTH_HANDLER: "/__/auth/mercury-saml2",
  COLLECTIONS: "/analyzers/v/:context?/:uuid?",
  ADD_COLLECTION: "/analyzers/add",
  BATCH_ADD_COLLECTION: "/analyzers/add-multiple",
  COLLECTION_VERSIONS: "/analyzers/:uuid/versions",
  ADD_COLLECTION_VERSION: "/analyzers/:uuid/versions/add",
  ROLES: "/roles",
  ADD_ROLE: "/roles/add",
  EDIT_ROLE: "/roles/:uuid",
  ADD_APPLICATION: "/project/add",
  ADD_SERVICE: "/service/add",
  ADD_API: "/api/add",

  ADD_REPOSITORY: "/repository/add",
  UPDATE_APPLICATION: "/project/:uuid",
  UPDATE_SERVICE: "/service/:uuid",
  UPDATE_API: "/api/:uuid",
  GRAPH: "/revision/:uuid/graph",

  APPLICATIONS: "/projects",
  ENVIRONMENTS: "/environments",
  ADD_ENVIRONMENT: "/environment/add",
  UPDATE_ENVIRONMENT: "/environment/:uuid",
  ADD_ORGANIZATION: "/organization/add",
  PLATFORM_KEYS: "/platform-keys",
  ADD_PLATFORM_KEY: "/platform-key/add",
  INCIDENTS: "/incidents",
  FINDINGS: "/findings",
  RESULTS: "/runs",
  LIST_JOBS: "/jobs/:jobId?",
  ADD_CONFIGS: "/addConfigs/:source?/:id?",
  EDIT_CONFIG: "/editConfig/:source?/:id?",
  LIST_SPECS: "/configurations",
  AUTOMATIONS: "/automations",
  DOWNLOADS: "/downloads/:downloadId?",
  HAR_CONVERTER: "/har-converter",
  OPENAPI_MERGER: "/openapi-merger",
  POSTMAN_CONVERTER: "/postman-converter",
  GENERATORS: "/generators",
  API_DETAILS: "/api/:apiId/details",
  QUICK_START: "/quick-start",
  PLANS: "/plans",
  PLAN_SUCCESS: "/orders/:id/success",
  PLAN_FAILURE: "/orders/:id/failure",
  PROJECT: "/projects/:projectId",
  GROUPS: "/groups",
  UNKNOWN: "*",
};
export const SEVERITY_OPTIONS = [
  { key: "critical", label: "Critical" },
  { key: "high", label: "High" },
  { key: "medium", label: "Medium" },
  { key: "low", label: "Low" },
  { key: "information", label: "Information" },
];
export const FINDING_TYPE_OPTIONS = [
  { key: "functional", label: "Functional" },
  { key: "security", label: "Security" },
];

export const NEW_OPTIONS = [
  { key: "true", label: "New" },

];

export const STATUS_OPTIONS = [
  { key: "open", label: "Open" },
  { key: "closed", label: "Closed" },
];

export const HELPER_TEXTS = {
  add_application: {
    application: "The name of your Project e.g. Bookstore",
    group: "The name for your Group e.g. Bookstore Sales",
  },
  add_api: {
    application: "The name of your Project e.g. Bookstore",
    api: "The name for your API e.g. Bookstore API",
    type: "The type of your API specification",
  },
  add_repository: {
    application: "The name of your Project e.g. Bookstore",
    repository: "The name for your Repository e.g. BookstoreRepo",

  },
  add_platform_key: {
    platform_key:
      "You will need a Platform Key to connect to the Aptori platform. Please pick a name for your Platform Key",
    expiry: "The duration for the validity of the Platform Key",
  },
  add_environment: {
    environment: "The name for your Environment e.g. BookstoreCI",
  },
  add_group: {
    group: "The name for your Group e.g. Bookstore Sales",
  },
  add_job_configuration: {
    name: "The name for this configuration e.g. Bookstore",
    api: "The API to analyze.",
    environment: "The Environment in which you will execute the analysis.",
    target_url: "A valid URL, e.g. http://fawlty.xyz/ ",
    definition:
      "The location of your API Specification, e.g. http://fawlty.xyz/specification, or path to local file",
    revision: "Version of API specification",
    analyzers: "Select the Analyzers to include in the analysis.",
    trace_header:
      "A header to be included in each http request e.g. Sift-Request",
    included_operations:
      "The operations that you want to limit the analysis to.",
    excluded_operations:
      "The operations that you want to exclude from the analysis.",
  },
  authorization: {
    title: "Authorization tokens to use in requests sent to a target API.",
    name: "User name for reference",
    user_name_literal: "Literal username (will be stored with configuration).",
    user_password_literal:
      "Literal password (will be stored with configuration).",
    user_name_template:
      "Name of environment variable containing username, e.g. USERNAME1",
    user_password_template:
      "Name of environment variable containing password, e.g. PASSWORD1",
    header_name: "Header name, e.g. authorization",
    header_value_literal:
      "Header value, e.g. bearer abcdef (will be stored with configuration).",
    header_value_template:
      "Name of environment variable containing header value, e.g. TOKEN1",
    command:
      "Command to execute to generate an authorization token.",
    http_command:
      "Command to execute to generate HTTP headers.",
    timeout:
      "Interval in seconds at which the command will be executed."
  },
  generators: {
    switch:
      "Input value generators used to produce values for input variables of an operation.",
  },
};

export const ALLOWED_PATHNAMES = [ROUTES.SETTINGS, ROUTES.EDIT_CONFIG];

export const GROUP_ROLES = [
  // {
  //   key: "administrator",
  //   name: "Administrator",
  //   description: "Administrator",
  // },
  {
    key: "owner",
    name: "Owner",
    description: "Owner",
  },
  {
    key: "maintainer",
    name: "Maintainer",
    description: "Maintainer",
  },
  {
    key: "developer",
    name: "Developer",
    description: "Developer",
  },
  {
    key: "guest",
    name: "Guest",
    description: "Guest",
  },
];

export const TOOL_TYPES: any = [
  {
    key: "api",
    label: "API"
  }, {
    key: "secret",
    label: "Secret"
  },
  {
    key: "dependency",
    label: "Dependency"
  },
  {
    key: "sast",
    label: "SAST"
  },
  {
    key: "container",
    label: "Container"
  },
  {
    key: "iac",
    label: "IAC"
  },
  {
    key: "k8s",
    label: "K8S"
  },{
    key: "aws",
    label: "AWS"
  },]
export const FACTS: any = [{
  type: "incident",
  label: "Issue",
  facts: [{
    key: "isNew",
    label: "New",
    type: "select",
    options: [{

      key: "true",
      label: "True"
    },
    {

      key: "false",
      label: "False"
    }]
  },
  {
    key: "findingType",
    label: "Type",
    type: "select",
    options: TOOL_TYPES
  },
  {
    key: "severity",
    label: "Severity",
    type: "select",
    options: [{

      key: "critical",
      label: "Critical"
    },{

      key: "high",
      label: "High"
    },
    {
      key: "medium",
      label: "Medium"
    },
    {
      key: "low",
      label: "Low"
    },
    {
      key: "information",
      label: "Information"
    }
  ]
    ,
  },
  {
    key: "risk",
    label: "Risk",
    type: "select",
    options: [{
      key: "critical",
      label: "Critical"
    },
    {
      key: "high",
      label: "High"
    },
    {
      key: "medium",
      label: "Medium"
    },
    {
      key: "low",
      label: "Low"
    },
    {
      key: "information",
      label: "Information"
    }]
    ,
  },

  ]
}]
