import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";

const IntRange: React.FC<any> = ({
  data,
  onSubmit,
  onChange,
  allowSave = false,
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [formValues, setFormValues] = useState({ ...data } as any);

  useEffect(() => {
    setFormValues({ ...data });
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    setHasChanged(false);
  };

  const handleChange = (value: any, type: "min" | "max") => {
    let newFormValues = { ...formValues, [type]: value };
    setFormValues(newFormValues);
    onChange(newFormValues);
    setHasChanged(true);
  };

  return (
    <Box sx={{ display: "inline", width: "100%" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            label={"Minimum"}
            margin="normal"
            sx={{ mr: 1 }}
            name="email"
            size="small"
            type="number"
            value={formValues.min}
            variant="outlined"
            onChange={(e: any) => {
              handleChange(e.target.value, "min");
            }}
            inputProps={{ "data-testid": "email" }}
          />
          <TextField
            fullWidth
            label={"Maximum"}
            margin="normal"
            sx={{ mr: 1 }}
            name="password"
            size="small"
            type="number"
            value={formValues.max}
            variant="outlined"
            onChange={(e: any) => {
              handleChange(e.target.value, "max");
            }}
            inputProps={{ "data-testid": "password" }}
          />

          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              size="small"
              variant="contained"
              disabled={
                !allowSave &&
                (!hasChanged ||
                  formValues.min === undefined ||
                  formValues.max === undefined)
              }
              data-testid="login-button"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default IntRange;
