import React,{ useState,useCallback,useEffect } from 'react';
import ReactFlow,{
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  getBezierPath,getMarkerEnd
} from 'react-flow-renderer';
import dagre from 'dagre';
import { API_SLUGS } from '../../constants';
import { axiosInstance } from '../../services/axios';
import './layouting.css';
var format = require("string-template")

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

const nodeWidth = 172;
const nodeHeight = 36;



const LayoutFlow = ({ apiId,revId }) => {
  const [elements,setElements] = useState([]);
  const [initialElements,setInitialElements] = useState([])
  useEffect(() => {
    fetchGraph()
  },[])


  useEffect(() => {
    if (initialElements.length) {
      setElements(getLayoutedElements(initialElements))
    }
  },[initialElements])

  const fetchGraph = async () => {
    // setIsFetchingGraphData(true)
    try {
      let graphResponse = await axiosInstance.get(format(API_SLUGS.GRAPH,{
        //@ts-ignore
        apiId: apiId,
        revId: revId
      }))
      let nodes = [...graphResponse.data?.ddg.nodes]
      for (let index in nodes) {
        nodes[index] = { ...nodes[index],id: nodes[index].operation,data: { label: nodes[index].operation ? nodes[index].operation : "" },position: { x: 0,y: 0 } }
      }

      let edges = [...graphResponse.data?.ddg.edges]
      for (let index in edges) {
        edges[index] = {
          ...edges[index],
          id: `${edges[index].consumer}-${edges[index].producer}`,source: edges[index].consumer,target: edges[index].producer,type: 'arrowclosed',animated: false,label: edges[index].output ? edges[index].output : "dsf",arrowHeadType: 'arrowclosed'
        }
      }

      setInitialElements([...nodes,...edges])
    } catch (error) {
    }
    // setIsFetchingGraphData(false)
  }


  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params,type: 'smoothstep',animated: true },els)
    );
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove,els));

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements,direction);
      setElements(layoutedElements);
    },
    [elements]
  );



  const getLayoutedElements = (elements,direction = 'LR') => {
    const isHorizontal = true;
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id,{ width: nodeWidth,height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source,el.target);
      }
    });

    dagre.layout(dagreGraph);

    return elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? 'left' : 'top';
        el.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }

      return el;
    });
  };


  return (
    elements.length ? <div className="layoutflow">
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          connectionLineType="smoothstep"
          edgeTypes={{ custom: CustomEdge }}
          arrowHeadColor="#000"

        />
      </ReactFlowProvider>
    </div> : <></>
  );
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const edgePath = getBezierPath({ sourceX,sourceY,sourcePosition,targetX,targetY,targetPosition });
  const markerEnd = getMarkerEnd(arrowHeadType,markerEndId);

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <text>
        <textPath href={`#${id}`} style={{ fontSize: '12px' }} startOffset="50%" textAnchor="middle">
          {data?.text}
        </textPath>
      </text>
    </>
  );
}

export default LayoutFlow;