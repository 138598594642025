import { CheckCircle } from "@mui/icons-material";
import { Card, CardContent, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { filter, sortBy } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router-dom";
import { axiosInstance } from "../../services/axios";
import ApiOnboarding from "../apiOnboarding";
import DownloadList from "../downloads/DownloadList";
import AddJobSpecificationsForm from "../jobs/AddJobSpecifications/addJobSpecificationsForm";
import ConfigTable from "../jobs/ListConfigs/ConfigTable";
import AddEnvironmentForm from "../management/AddEnvironment/AddEnvironmentForm";
import AddPlatformKeyForm from "../management/AddPlatformKeys/AddPlatformKeyForm";

const QuickStart = ({ history }: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [quickStart, setQuickStart] = useState([] as any);
  const [isFetchingQuickStartOptions, setIsFetchingQuickStartOptions] =
    useState(false);
  const [quickstartApi, setQuickstartApi] = useState<string | undefined>(
    undefined
  );
  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    fetchQuickStartOptions();
    fetchQuickStartApi();
  }, []);

  const fetchQuickStartOptions = async () => {
    setIsFetchingQuickStartOptions(true);
    try {
      let quickStartResponse = await axiosInstance.get("/guides/quickstart");
      setQuickStart(
        sortBy(
          filter(quickStartResponse?.data?.quickstart, { type: "wizard" }),
          "serialNumber"
        )
      );
    } catch (error: any) { }
    setIsFetchingQuickStartOptions(false);
  };

  useEffect(() => {
    let lastStepCompleted = 0;
    for (let index in quickStart) {
      if (
        quickStart[index].status === "completed" &&
        (parseInt(index) === lastStepCompleted ||
          parseInt(index) - 1 === lastStepCompleted)
      ) {
        lastStepCompleted = parseInt(index);
      }
    }
    if (lastStepCompleted > 0) {
      setActiveStep(lastStepCompleted + 1);
    }
  }, [quickStart]);

  const isStepOptional = (step: number) => {
    return quickStart[step]?.optional;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    let newQuickStart = [...quickStart]
    quickStart[activeStep].status = "completed"
    setQuickStart(newQuickStart)

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setShowNextButton(false);
    fetchQuickStartApi();
  };

  const handleBack = () => {
    setShowNextButton(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const fetchQuickStartApi = async () => {
    try {
      const response = await axiosInstance.get(`guides/quickstart/api`);
      setQuickstartApi(response?.data?.apiId);
    } catch (error: any) { }
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  if (isFetchingQuickStartOptions) {
    return (
      <Box sx={{ pt: 10 }}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 5 }}>
      <div>
        <Typography
          sx={{ textAlign: "left", pb: 1 }}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Quick Start
        </Typography>
      </div>

      <Card sx={{ p: 2 }} elevation={12}>
        <CardContent>
          {activeStep !== quickStart.length && (
            <Stepper activeStep={activeStep}>
              {quickStart?.map((item: any, index: number) => {
                const stepProps: { completed?: boolean } = {
                  completed:
                    quickStart[index]?.status === "completed" &&
                    activeStep !== index,
                };
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }

                return (
                  <Step key={item.title} {...stepProps}>
                    <StepLabel {...labelProps} sx={{ fontWeight: 700 }}>
                      {item.title}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            {(quickStart.length - 1 === activeStep ||
              showNextButton ||
              quickStart[activeStep]?.uiKey ===
              "DOWNLOAD_HERMIT_EXECUTABLE") && (
                <Button variant="outlined" onClick={handleNext}>
                  {activeStep === quickStart.length - 1 ? "Finish" : "Next"}
                </Button>
              )}
          </Box>
          {activeStep === quickStart.length ? (
            <Box sx={{ textAlign: "center" }}>
              <CheckCircle sx={{ fontSize: "64px" }} color="success" />
              <Typography sx={{ mt: 2, mb: 1 }} variant="h5">
                Onboarding has been completed
              </Typography>
              <Box sx={{ pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                {/* <Button onClick={handleReset}>Reset</Button> */}
                <Button variant="contained" onClick={() => history.push("/")}>
                  Go to Overview
                </Button>
              </Box>
            </Box>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                <ReactMarkdown>
                  {quickStart[activeStep]?.description}
                </ReactMarkdown>
              </Typography>
              {quickStart[activeStep]?.uiKey === "CREATE_API" && (
                <ApiOnboarding callback={handleNext} apiId={quickstartApi} />
              )}
              {quickStart[activeStep]?.uiKey === "CREATE_ENVIRONMENT" && (
                <AddEnvironmentForm preventRedirect />
              )}
              {quickStart[activeStep]?.uiKey === "CREATE_PLATFORM_KEY" && (
                <AddPlatformKeyForm callback={() => setShowNextButton(true)} />
              )}
              {quickStart[activeStep]?.uiKey === "CREATE_CONFIGURATION" && (
                <AddJobSpecificationsForm
                  preventRedirect
                  callback={handleNext}
                  quickstart
                  apiId={quickstartApi}
                  source="wizard"
                />
              )}
              {quickStart[activeStep]?.uiKey ===
                "DOWNLOAD_HERMIT_EXECUTABLE" && <DownloadList />}
              {quickStart[activeStep]?.uiKey === "RUN_ANALYSIS" && (
                <ConfigTable apiId={quickstartApi} />
              )}
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default withRouter(QuickStart);
