import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { API_SLUGS, STRINGS } from '../../constants';
import { axiosInstance } from '../../services/axios';
import { JobSpecForm } from './JobSpecForm';
import { parseHermitConfig } from '../../utils/hermitConfigUtils';

export const JobSpecContainer = ({ id, source }: any) => {
    const emptyspec = {config:{}, environmentName: STRINGS.EMPTY, apiName: STRINGS.EMPTY};
    const [jobSpec, setJobSpec] = useState(emptyspec);
    const [analyzers, setAnalyzers] = useState([]);
    const [apis, setApis] = useState([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
    const [environments, setEnvironments] = useState([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
    const [hasError, setHasError] = useState(false)

    const wizardSource = ['wizard', 'listJobs'].includes(source);
    
    useEffect(() => {
        if (id) {
            fetchJobSpec(id);
            fetchAnalyzers();
            if (wizardSource) {
                fetchApis();
                fetchEnvironments();
            }
        }
    }, [id])

    if (!id) {
        return (<></>);
    }

    const fetchJobSpec = async (jobId: string) => {
        try {
            const jobResponse = await axiosInstance.get(wizardSource ? `${API_SLUGS.RUN_CONFIGURATIONS}/${jobId}`: `/results/${jobId}/config`);
            setJobSpec(wizardSource
              ? {config:parseHermitConfig(jobResponse.data?.config), environmentName: STRINGS.EMPTY, apiName: STRINGS.EMPTY}
              : jobResponse.data);
        } catch (error: any) {
            console.log(error);
        }
    }
    
    const fetchAnalyzers = async () => {
        try {
          let analyzersResponse = await axiosInstance.get(API_SLUGS.FAULT_ANALYZERS);
          setAnalyzers(analyzersResponse.data?.faultAnalyzers);
        } catch (error: any) {
          console.log(error);
        }
    }

    const fetchApis = async () => {
        try {
          let apisResponse = await axiosInstance.get(API_SLUGS.ADD_API);
          setApis(apisResponse.data?.apis)
        } catch (error: any) {
          console.log(error);
        }
    }

    const fetchEnvironments = async () => {
      try {
        let environmentsResponse = await axiosInstance.get(API_SLUGS.ENVIRONMENTS);
        setEnvironments(environmentsResponse.data?.environments); 
      } catch (error: any) {
        console.log(error);
      }
  }

    const ErrorFallback = (params: any) => {
      params.resetErrorBoundary();
      return (<></>)
    }

      if (jobSpec && Object.keys(jobSpec).length) {
       return (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={() => {
              setHasError(true);
            }}
            onReset={() => {
              setJobSpec(emptyspec);
              setAnalyzers([]);
            }}
            resetKeys={[hasError]}
          >
              {hasError
              ? (<p>Cannot display this configuration format.</p>)
              : (<JobSpecForm
                  hermitConf={jobSpec.config}
                  apiName={jobSpec.apiName}
                  environmentName={jobSpec.environmentName}
                  analyzers={analyzers}
                  apis={apis}
                  environments={environments}
                  hasError={hasError}
                  source={source}
                  cloneLink={source ? id : null}
              />)}
          </ErrorBoundary>
        );
      }

      return null;
    }