import { FC, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, STRINGS, ROUTES } from "../../../constants";
import { axiosInstance } from "../../../services/axios";
import { IRole } from "../../roles/viewRoles/RolesTable";
import { find, isEmpty } from "lodash";

const IdpMappingsForm: FC<RouteComponentProps> = ({ history }) => {
  const [formValues, setFormValues] = useState([
    { idpRole: "", appAssureRole: "" },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingIdpMappings, setIsFetchingIdpMappings] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const [roles, setRoles] = useState([] as IRole[]);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);

  useEffect(() => {
    fetchIdpMappings();
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setIsFetchingRoles(true);
    try {
      let rolesResponse = await axiosInstance.get(API_SLUGS.ROLES);
      setRoles(rolesResponse.data?.data?.roles);
    } catch (error: any) {}
    setIsFetchingRoles(false);
  };

  const fetchIdpMappings = async () => {
    setIsFetchingIdpMappings(true);
    try {
      let idpMappings = await axiosInstance.get(API_SLUGS.IDP_MAPPINGS);
      let newFormValues = [];
      for (let mapping in idpMappings.data?.data?.clientIdpMapping) {
        newFormValues.push({
          idpRole: mapping,
          appAssureRole: idpMappings.data?.data?.clientIdpMapping[mapping],
        });
      }
      setFormValues(newFormValues);
    } catch (error: any) {}
    setIsFetchingIdpMappings(false);
  };

  const handleChange = (
    index: number,
    value: any,
    type: "idpRole" | "appAssureRole"
  ) => {
    let newValues = [...formValues];
    //@ts-ignore
    newValues[index][type] = value;

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let clientIdpMapping: any = {};
      for (let value of formValues) {
        clientIdpMapping[value?.idpRole] = value?.appAssureRole;
      }
      await axiosInstance.put(API_SLUGS.SAVE_IDP_MAPPINGS, {
        clientIdpMapping,
      });
      // updateCurrentUser(formValues)
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) {}
    }
    setIsSubmitting(false);
  };

  const removeRole = (index: number) => {
    let newFormValues = [...formValues];
    newFormValues.splice(index, 1);

    setFormValues(newFormValues);
  };

  if (isFetchingIdpMappings || isFetchingRoles) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                setFormValues([
                  ...formValues,
                  { idpRole: "", appAssureRole: "" },
                ])
              }
            >
              Add
            </Button>
          </Box>
        </Grid>

        {formValues.map((value, index) => (
          <>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                label="IDP Role"
                name="idpRole"
                value={formValues[index].idpRole}
                onChange={(e: any) => {
                  handleChange(index, e.target.value, "idpRole");
                }}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                getOptionLabel={(option): any => option?.name}
                options={roles}
                value={find(roles, { name: formValues[index].appAssureRole })}
                onSelect={(e) => {
                  //@ts-ignore
                  let selectedValue = e.target.value;
                  handleChange(index, selectedValue, "appAssureRole");
                }}
                renderInput={(params): JSX.Element => (
                  <TextField
                    label="App Assure role"
                    name="appAssureRole"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button onClick={() => removeRole(index)}>Remove</Button>
            </Grid>
          </>
        ))}
      </Grid>

      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" sx={{ width: "fit-content" }}>
            <div>{successMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        {successMessage && successMessage.trim() ? null : (
          <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </Box>
    </form>
  );
};

export default withRouter(IdpMappingsForm);
