import { FC, useEffect, useState } from 'react';
import {
    Autocomplete,
    Button,
    Box,
    TextField,
    Typography
} from '@mui/material';
import { axiosInstance } from '../../services/axios';
import { downloadHermitConf, lowercaseKeys } from '../../utils/hermitConfigUtils';
import { API_SLUGS, HELPER_TEXTS, STRINGS } from '../../constants';
import { merge } from 'lodash';

export const CommitConfigForm: FC<any> = ({ hermitConf, additionalSettings, onSubmit, runJob = null, configId = null }: any) => {
    const [formValues, setFormValues] = useState({} as any);
    const [environments, setEnvironments] = useState([{ name: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
    const [templateVars, setTemplateVars] = useState([] as any);
    const fetchGenerators = async (apiId: string) => {
      const generatorsResponse = await axiosInstance.get(`/apis/${apiId}/generators`);
      return generatorsResponse.data?.generators || [];
    }
    const fetchResourceProfiles = async (apiId: string) => {
      const rpResponse = await axiosInstance.get(`/apis/${apiId}/operations/resourceprofiles`);
      return rpResponse.data?.operations || [];
    }

    const findTemplateVars = (conf: any) => {     
        if (conf.targets) {
            const {authorization} = conf.targets[0];
            if (authorization) {  
            const templateVars = new Set(); 
            Object.keys(authorization).forEach((key) => {
                const user = authorization[key];
                Object.keys(user).forEach((userKey) => {
                  if (typeof user[userKey] === "string") {
                    const result = user[userKey].match(new RegExp('{{(.*)}}'));
                    if (result && result.length) {
                        templateVars.add(result[1])
                    }
                  }
                })
            })
        return Array.from(templateVars).map((template: any) => ({
          template, value: ''
        }));
        }}
        return [];
    }

    const updateTemplateVars = (key: string, value: string) => {
      const updated = templateVars.map((it: any) => {
        if (it.template === key) {
          return {template: key, value};
        }
        return it;
      });
      setTemplateVars(updated);
    }

    const finalizeConfig = async (hermitConf: any) => {
      const finalFields: any = {labels: {}, targets: [{}]};
      const hermitConfig = lowercaseKeys(hermitConf);
      delete hermitConf.environmentid;
      const hermitTarget = lowercaseKeys(hermitConfig.targets[0]);
      if (additionalSettings?.generators) {
        const generators = await fetchGenerators(hermitTarget.api);
        if (generators.length) {
            finalFields.analysis = {generators};
        }
      }
      const resourceProfiles = await fetchResourceProfiles(hermitTarget.api);
      if (resourceProfiles.length) {
        finalFields.targets[0].resourceProfiles = resourceProfiles.map(({id, ...profile}: any) => profile);
      }
      if (formValues.environmentId) {
        finalFields.environmentID = formValues.environmentId;       
      } else {
        delete finalFields.environmentID;
      }
      if (configId) {
        finalFields.labels.configurationID = configId;
      }
      return merge(
        hermitConf,
        finalFields
      )
    }

    const fetchEnvironments = async () => {
        const environmentsResponse = await axiosInstance.get(API_SLUGS.ENVIRONMENTS);
        setEnvironments([{  name: '<none>', id: STRINGS.EMPTY }, ...environmentsResponse.data?.environments]);
    }

    const handleChange = (value: any, type: any) => {
      const newValues = { ...formValues,  [type]: value };
      setFormValues(newValues);
    }

    useEffect(() => {
      fetchEnvironments();
      setTemplateVars(findTemplateVars(hermitConf));
      const lconf = lowercaseKeys(hermitConf);
      if (lconf && lconf.environmentid ) {
        handleChange(lconf.environmentid, 'environmentId');
      }
    }, [])
    
    const exportVars = (vars: any) => {
        const exportCommand = vars.map((it: any) => 
          `${it.template.replace(/.env./i, '')}="${it.value}"`).join(' ');
        return `export ${exportCommand}`;
    }

    return (<>
        {
            runJob && templateVars.map((templateKey: any) => {
                return (
                  <TextField
                    sx={{ textAlign: 'left', mt: 2, width: '90%' }}
                    size="medium"
                    name={templateKey.template}
                    key={templateKey.template}
                    label={templateKey.template && templateKey.template.replace(/.env./i, '')}
                    value={templateKey.value || ''}
                    helperText="Template variable value for this analysis execution"
                    onChange={(e: any) => {
                        updateTemplateVars(templateKey.template, e.target.value);
                    }}
                    variant="outlined"
                  />
                )
            })
          }
        <Box>
        <Autocomplete
            options={environments.map((environment: any) => (environment.id))}
            getOptionLabel={option => environments.filter((environment: any) => environment.id === option).map((environment: any) => environment.name).join() }
            sx={{ maxWidth: "90%", textAlign: 'left', mt: 2 }}
            size="medium"
            onChange={(e, value) => {
              handleChange(value, 'environmentId');
            }}
            value={(formValues && formValues.environmentId) || (environments.length && environments[0].id) || ''}
            renderInput={(params): JSX.Element => {
              return <TextField
                name="environmentId"
                variant="outlined"
                label="Environment"
                helperText={!environments.length ? 'No environments defined' : HELPER_TEXTS?.add_job_configuration?.environment}
                {...params}
              />
            }}
          />
        </Box>
        { runJob
        ? (<Box sx={{ mt: 3 }}>
            <Button
                color="primary"
                size="medium"
                variant="outlined"
                onClick={() => {
                    onSubmit();
                }}
              >
                Cancel
            </Button>
            <Button
                sx={{ ml: 2 }}
                color="primary"
                size="medium"
                variant="contained"
                onClick={async () => {
                    runJob(await finalizeConfig(hermitConf), templateVars.length ? exportVars(templateVars) : '');
                    onSubmit();
                }}
              >
                Run job
            </Button>
          </Box>)
        : (<Box sx={{ mt: 3 }}>
          <Typography
              color="textPrimary"
              sx={{ mt: 3, textAlign: "left" }}
              variant="h6"
            >

              Download configuration file
            </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
                color="primary"
                size="medium"
                variant="outlined"
                onClick={() => {
                    onSubmit();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ ml: 2 }}
                color="primary"
                size="medium"
                variant="contained"
                onClick={async () => {
                    downloadHermitConf(await finalizeConfig(hermitConf), 'YAML');
                    onSubmit();
                }}
              >
                YAML
              </Button>

              <Button
                sx={{ ml: 2 }}
                color="primary"
                size="medium"
                variant="contained"
                onClick={async () => {
                    downloadHermitConf(await finalizeConfig(hermitConf), 'JSON')
                    onSubmit();
                }}
              >
                JSON
              </Button>
          </Box>
        </Box>)
        }
    </>)
};
