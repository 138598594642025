import { Button, Card, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../../hooks/http/useFetch";
import { ExternalLink } from "../../../../icons/external-link";
import ModalWrapper from "../../../common/modal/wrapper";
import Loaders from "../../../dashboard/Loaders";
import ActionButton from "../shared/button";
import SequenceStep from "./SequenceStep";

interface IIncidentPreviewPopup extends RouteComponentProps {

  finding: any;
  trigger?: React.ReactNode | undefined;
}
const IncidentPreviewPopup: FC<IIncidentPreviewPopup> = ({
  history,
  finding,
  trigger = undefined,
}) => {


  return (
    <>
      <ModalWrapper
        maxWidth="md"
        title={`Issue #${finding?.incidentNumber}`}
        trigger={
          trigger || (
            <Button variant="outlined">Issue #{finding?.incidentNumber}</Button>
          )
        }
        child={<IncidentContent finding={finding} history={history} />}
      />
    </>
  );
};

const IncidentContent: FC<any> = ({ finding, history }) => {
  const { data: incident } = useFetch(`/incidents/${finding?.incidentId}`);
  const { data: findingDetails, loading: findingDetailsLoading } = useFetch(`/findings/${finding?.id}`);

  if (findingDetailsLoading) return <Loaders />;
  return (
    <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">
          <Link href={`/findings?incidentId=${incident?.id}&findingId=${finding?.id}`} target="_blank">
            <ExternalLink fontSize={"inherit"} />
            More Details
          </Link>
        </Typography>
        <ActionButton
          incidentId={incident?.id}
          buttons="STATE"
          risk={incident?.risk}
          state={incident?.state}
          user={incident?.assigneeId}
          jira={{ url: incident?.jiraUrl, number: incident?.jiraNumber }}
        />
      </Box>

      <Grid xs={12} item sx={{ mr: 2, textAlign: "left" }} container>
        <Grid item xs={12}>
          {findingDetails?.reason ? (
            <Typography
              sx={{ fontSize: "12px", opacity: 0.6 }}
              variant="overline"
            >
              Reason
            </Typography>
          ) : null}
          {(() => {
            try {
              //@ts-ignore
              return <ReactMarkdown>{findingDetails?.reason}</ReactMarkdown>;
            } catch (error) {
              return "";
            }
          })()}
        </Grid>
        <Grid item xs={12}>
          {findingDetails?.remedy ? (
            <Typography
              sx={{ fontSize: "12px", opacity: 0.6 }}
              variant="overline"
            >
              Remedy
            </Typography>
          ) : null}
          {(() => {
            try {
              //@ts-ignore
              return <ReactMarkdown>{findingDetails?.remedy}</ReactMarkdown>;
            } catch (error) {
              return "";
            }
          })()}
        </Grid>
      </Grid>
      {!isEmpty(findingDetails) ? (
        <Card elevation={0}>
          <Typography
            variant="overline"
            sx={{ fontSize: "12px", opacity: 0.6, mr: 1 }}
          >
            Sequence
          </Typography>

          <Typography
            className="hand"
            sx={{ display: "inline", fontSize: "14px" }}
            onClick={() =>
              history.push(`/findings?resultId=${findingDetails?.resultId}`)
            }
          >
            <ExternalLink fontSize="inherit" />{" "}
            {findingDetails?.resultId?.split("-")[0]}
          </Typography>
          {/* <Button size="small" sx={{ ml: 1 }} onClick={handleExport}>
            Export
          </Button> */}

          {findingDetails?.sequence && findingDetails?.sequence?.length
            ? findingDetails?.sequence.map((step: any, index: number) => (
              <SequenceStep
                data={{
                  index,
                  step,
                  isLastStep: findingDetails?.sequence.length - 1 === index,
                }}
              />
            ))
            : null}
        </Card>
      ) : null}
    </Box>
  );
};

export default withRouter(IncidentPreviewPopup);
