import { ExpandMore } from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Chip,
  Grid, Switch, Tooltip, Typography
} from "@mui/material";
import {
  filter, find, findIndex, groupBy, isEmpty, map, orderBy, startCase
} from "lodash";
import { FC, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import { getConfigs } from "../../../configs";
import { axiosInstance } from "../../../services/axios";
import IncidentPreviewPopup from "../../analysis/ViewFindings/incidentComponents/incidentPreviewPopup";
import ActionButton from "../../analysis/ViewFindings/shared/button";
import RepoIncidentsTable from "../../analysis/ViewIncidents/RepoIncidentsTable";
const filters = [
  {
    resource: "Risk",
    apis: [
      {
        key: "high",
        path: "High",
      },
      {
        key: "medium",
        path: "Medium",
      },
      {
        key: "low",
        path: "Low",
      },
    ],
  },
];

const selectedAPIData = {
  resource: "book",
  path: "/blah",
  name: "blah.api",
  method: "post",
  operationId: "123",
};

const APIComponent: FC<any> = ({ type, api, setFilters }) => {
  return (
    <>
      <Box
        sx={{ ml: 2, backgroundColor: "background.default", my: 0.5, p: 0.5 }}
      >
        <Checkbox
          key={api.label}
          onChange={({ target }) => {
            setFilters(api.key, target.checked);
          }}
        />
        <Typography variant="body2" sx={{ display: "inline" }}>
          {api.label}
        </Typography>
      </Box>
    </>
  );
};

const ResourceComponent: FC<any> = ({ name, resource, setFilters }) => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="h6"
          sx={{ backgroundColor: "background.default", my: 0.5, p: 0.5 }}
        >
          {name}
        </Typography>
        {resource?.map((api: any) => (
          <APIComponent api={api} setFilters={setFilters} />
        ))}
      </Box>
    </>
  );
};

const APIData: FC<any> = ({ perAnalyzerRuleTables, operationID }) => {
  return (
    <Box sx={{ py: 2 }}>
      {perAnalyzerRuleTables?.map((tabData: any) => (
        <>
          <Box sx={{ py: 2 }}>
            <Typography
              variant="h6"
              sx={{ width: "33%", flexShrink: 0, display: "inline" }}
            >
              {tabData.tab}
            </Typography>
          </Box>

          <Grid container>
            {tabData.data?.map((row: any) => (
              <Grid xs={6} item sx={{ mb: 2 }}>
                <Box>
                  <Tooltip title={row.decision}>
                    <Chip
                      size="small"
                      className="hand"
                      sx={{ fontSize: "12px" }}
                      label={row.analyzerName}
                      style={{
                        backgroundColor:
                          row.decision?.toLowerCase() === "pass"
                            ? "#10B981"
                            : row.decision?.toLowerCase() === "none"
                              ? "#e46b48"
                              : row.decision?.toLowerCase() === "skip"
                                ? "rgb(117,114,128)"
                                : "#D14343 ",
                        color: "#fff",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ))}
    </Box>
  );
};

const FaultCard: FC<any> = ({ fault }) => {
  return (
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "inline" }}>
        <Chip label={fault?.faultId} />
        <Tooltip title={fault?.findingName}>
          <Typography
            variant="body1"
            sx={{ display: "inline", pl: 2, width: "7em" }}
          >
            {fault?.findingName}
          </Typography>
        </Tooltip>

        {fault?.isNew ? (
          <Chip size="small" color="success" label="New" sx={{ ml: 1 }}></Chip>
        ) : null}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Chip label={fault?.status}></Chip>
        <Tooltip title="Risk">
          <ActionButton
            incidentId={fault?.incidentId}
            buttons="RISK"
            risk={fault?.risk}
            state={fault?.state}
            user={fault?.assigneeId}
          />
        </Tooltip>

        <IncidentPreviewPopup finding={fault} />
      </Box>
    </Box>
  );
};

export const FaultsList: FC<any> = ({ faults, openIncidentsOnly }) => {
  return (
    <Box
      sx={{ backgroundColor: "background.default", borderRadius: "5px", p: 2 }}
    >
      {faults?.map((fault: any) =>
        !openIncidentsOnly || fault.status === "open" ? (
          <FaultCard fault={fault} />
        ) : null
      )}
    </Box>
  );
};

const SelectedAPIComponent: FC<any> = ({
  api,
  selectedAPIData,
  openIncidentsOnly,
  perAnalyzerRuleTables,
  environmentId,
  countsPerOperation,
  faultsSummaryByOperation,
  isExpanded,
  expand,
}) => {
  const [faults, setFaults] = useState<any[] | null>(null);
  const [subAccordionExpanded, setSubAccordionExpanded] = useState<
    string | false
  >("faults");
  const [requestsCounts, setRequestsCounts] = useState<number[] | null>(null);

  useEffect(() => {
    if (isExpanded && isEmpty(faults)) {
      fetchFaults();
    }
  }, [isExpanded]);

  const handleSubAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSubAccordionExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    try {
      const row = find(countsPerOperation, {
        operationId: selectedAPIData.operationID,
      });
      setRequestsCounts([row?.requestsSuccessful, row?.requestsFailed]);
    } catch (error) { }
  }, [selectedAPIData, countsPerOperation]);

  const fetchFaults = async () => {
    try {
      const faultsResponse = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/incidents?apiId=${api.id}&operationId=${selectedAPIData.operationID
        }&environmentId=${environmentId}`
      );

      setFaults(
        filter(faultsResponse?.data?.incidents, {
          operationId: selectedAPIData.operationID,
        })
      );
    } catch (error) { }
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={expand(selectedAPIData.operationID)}
      elevation={12}
      sx={{
        mb: 2,

        borderLeft: "8px solid red",
        borderColor: faultsSummaryByOperation?.[selectedAPIData.operationID]
          ?.high
          ? "error.main"
          : faultsSummaryByOperation?.[selectedAPIData.operationID]?.medium
            ? "warning.main"
            : faultsSummaryByOperation?.[selectedAPIData.operationID]?.low
              ? "info.main"
              : "success.main",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{ py: 3 }}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {selectedAPIData.method && (
              <Chip
                label={
                  <Typography variant="overline">
                    {selectedAPIData.method}
                  </Typography>
                }
                size="small"
                variant="outlined"
                color="success"
                sx={{ display: "inline", mr: 1, p: 0.25 }}
              />
            )}

            <Typography
              variant="h6"
              sx={{
                display: "inline",
              }}
            >
              {selectedAPIData.path}
            </Typography>

            <Typography variant="body2" sx={{ display: "inline", pl: 1 }}>
              {selectedAPIData.operationID}
            </Typography>
          </Box>
          <Box sx={{ pr: 2 }}>
            {faultsSummaryByOperation?.[selectedAPIData.operationID]?.high >
              0 && (
                <Chip
                  label={
                    faultsSummaryByOperation?.[selectedAPIData.operationID]?.high
                  }
                  size="small"
                  color="error"
                  sx={{ display: "inline", mr: 1, p: 0.25 }}
                />
              )}
            {faultsSummaryByOperation?.[selectedAPIData.operationID]?.medium >
              0 && (
                <Chip
                  label={
                    faultsSummaryByOperation?.[selectedAPIData.operationID]
                      ?.medium
                  }
                  size="small"
                  color="warning"
                  sx={{ display: "inline", mr: 1, p: 0.25 }}
                />
              )}
            {faultsSummaryByOperation?.[selectedAPIData.operationID]?.low >
              0 && (
                <Chip
                  label={
                    faultsSummaryByOperation?.[selectedAPIData.operationID]?.low
                  }
                  size="small"
                  color="info"
                  sx={{ display: "inline", mr: 1, p: 0.25 }}
                />
              )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Accordion
          expanded={subAccordionExpanded === "faults"}
          onChange={handleSubAccordionChange("faults")}
          elevation={12}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ py: 3 }}>
            <Typography variant="h6">Incidents</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {subAccordionExpanded === "faults" && (
              <FaultsList
                faults={faults}
                openIncidentsOnly={openIncidentsOnly}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={subAccordionExpanded === "generators"}
          onChange={handleSubAccordionChange("generators")}
          elevation={12}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ py: 3 }}>
            <Typography variant="h6"> Generators</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* {subAccordionExpanded === "generators" && (
              <OperationView
                apiId={api?.id}
                revId={api?.currentRevision?.id}
                defaultOperationId={selectedAPIData.operationID}
                view="ADD"
              />
            )} */}
          </AccordionDetails>
        </Accordion>
        {/* <APIData
          operationID={selectedAPIData.operationID}
          perAnalyzerRuleTables={
            perAnalyzerRuleTables[selectedAPIData.operationID]
          }
        /> */}
      </AccordionDetails>
    </Accordion>
  );
};

const ResourceSection: FC<any> = ({
  operations,
  apiId,
  environmentId,
  api,
  openIncidentsOnly,
  filters,
  perAnalyzerRuleTables,
  countsPerOperation,
  faultsSummaryByOperation,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const isFilterValid = (operationSummary: any, filters: any[]) => {
    if (
      filters.length === 0 ||
      (filter(filters[0].options, { selected: false }).length === 0 &&
        filter(filters[1].options, { selected: false }).length === 0)
    ) {
      return true;
    }
    let riskFilterCheck = false;
    let categoryFilterCheck = false;
    let riskFilterSelectedOptions = filter(filters[0].options, {
      selected: true,
    });
    riskFilterCheck = riskFilterCheck || riskFilterSelectedOptions.length === 0;
    for (let option of riskFilterSelectedOptions) {
      if (operationSummary?.[option.key] > 0) {
        riskFilterCheck = true;
        break;
      }
    }
    let categoryFilterSelectedOptions = filter(filters[1].options, {
      selected: true,
    });

    categoryFilterCheck =
      categoryFilterCheck || categoryFilterSelectedOptions.length === 0;

    for (let option of categoryFilterSelectedOptions) {
      if (operationSummary?.categories?.indexOf(option.key) > -1) {
        categoryFilterCheck = true;
        break;
      }
    }
    return riskFilterCheck && categoryFilterCheck;
  };
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h5">{operations?.[0]?.resource}</Typography>
      {operations?.map(
        (operation: any) =>
          isFilterValid(
            faultsSummaryByOperation?.[operation?.operationID],
            filters
          ) &&
          (!openIncidentsOnly || operation?.open) && (
            <SelectedAPIComponent
              apiId={apiId}
              environmentId={environmentId}
              api={api}
              openIncidentsOnly={openIncidentsOnly}
              selectedAPIData={operation}
              faultsSummaryByOperation={faultsSummaryByOperation}
              isExpanded={operation.operationID === expanded}
              expand={handleChange}
              perAnalyzerRuleTables={perAnalyzerRuleTables}
              countsPerOperation={countsPerOperation}
            />
          )
      )}
    </Box>
  );
};

const ResultNewView: FC<any> = ({ operations, match, location, api }) => {
  const [operationsByResource, setOperationsByResource] = useState<any>({});
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const environmentId = query.get("environmentId");
  const [incidents, setIncidents] = useState<any[] | null>(null);
  const [riskFilters, setRiskFilters] = useState<any[]>([]);
  const [categoryFilters, setCategoryFilters] = useState<any>([]);

  const [openIncidentsOnly, setOpenIncidentsOnly] = useState(true);

  const [faultsSummaryByOperation, setFaultsSummaryByOperation] =
    useState<any>(null);
  useEffect(() => {
    fetchFaults();
  }, [api]);

  const fetchFaults = async () => {
    try {
      const faultsResponse = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/incidents?apiId=${api.id
        }&environmentId=${environmentId}`
      );
      setIncidents(faultsResponse?.data?.incidents);
    } catch (error) { }
  };

  useEffect(() => {
    evaluateOperations();
  }, [incidents, operations]);

  const evaluateOperations = () => {
    if (isEmpty(incidents)) return;
    if (!operations) return;
    let newOperations = [...operations];
    for (let incident of incidents!) {
      let operationIndex = findIndex(newOperations, {
        operationID: incident.operationId,
      });
      if (newOperations[operationIndex]) {
        newOperations[operationIndex][incident.status] = true;
      }
    }

    setOperationsByResource(
      groupBy(
        orderBy(
          newOperations,
          (operation) => operation?.settingsToUse?.resource
        ),
        (operation) => operation?.settingsToUse?.resource
      )
    );
  };

  useEffect(() => {
    generateRiskFilters();
  }, [incidents, openIncidentsOnly]);

  useEffect(() => {
    generateCategoryFilters();
  }, [riskFilters, incidents]);

  const generateRiskFilters = () => {
    if (isEmpty(incidents)) return;

    try {
      let filteredIncidents = [...incidents!];
      if (openIncidentsOnly) {
        filteredIncidents = filter(filteredIncidents, { status: "open" });
      }
      let riskCountMap: any = {
        high: 0,
        medium: 0,
        low: 0,
      };
      let groupedFaults: any = groupBy(filteredIncidents, "operationId");
      let newFaultsSummaryByOperation: any = {};
      for (let operationId in groupedFaults) {
        const faultsGroupedBySeverity: any = groupBy(
          groupedFaults[operationId],
          "risk"
        );
        newFaultsSummaryByOperation[operationId] = {
          total: groupedFaults[operationId].length,
          categories: [],
        };

        for (let risk in riskCountMap) {
          let riskCount = faultsGroupedBySeverity?.[risk]?.length;
          newFaultsSummaryByOperation[operationId][risk] = riskCount || 0;
          riskCountMap[risk] += riskCount || 0;
        }
      }

      let riskFilters: any = [];
      for (let risk in riskCountMap) {
        riskFilters.push({
          key: risk,
          label: `${startCase(risk)} (${riskCountMap[risk]})`,
          count: riskCountMap[risk],
          selected: false,
        });
      }

      setRiskFilters(riskFilters);
      setFaultsSummaryByOperation(newFaultsSummaryByOperation);
    } catch (error) { }
  };

  const generateCategoryFilters = () => {
    if (isEmpty(incidents) || isEmpty(riskFilters)) return;
    try {
      let filteredFaults = [...incidents!];
      for (let riskFilter of riskFilters) {
        if (riskFilter.selected) {
          filteredFaults = filter(filteredFaults, { risk: riskFilter.key });
        }
      }

      if (openIncidentsOnly) {
        filteredFaults = filter(filteredFaults, { status: "open" });
      }
      let categories = map(filteredFaults, "categoryName");
      let categoryCountMap: any = {};
      for (let category of categories) {
        categoryCountMap[category] = 0;
      }

      let groupedFaults: any = groupBy(filteredFaults, "operationId");
      let newFaultsSummaryByOperation: any = { ...faultsSummaryByOperation };
      for (let operationId in groupedFaults) {
        newFaultsSummaryByOperation[operationId].categories = [];
        for (let fault of groupedFaults[operationId]) {
          newFaultsSummaryByOperation[operationId]?.categories?.push(
            fault?.categoryName
          );
          categoryCountMap[fault?.categoryName]++;
        }
      }

      let categoryFilters: any = [];
      for (let category in categoryCountMap) {
        categoryFilters.push({
          key: category,
          label: `${category} (${categoryCountMap[category]})`,
          count: categoryCountMap[category],
          selected: false,
        });
      }

      categoryFilters = orderBy(categoryFilters, "count", "desc");

      setCategoryFilters(categoryFilters);
      setFaultsSummaryByOperation(newFaultsSummaryByOperation);
    } catch (error) { }
  };

  if (isEmpty(incidents) ) return <></>;

  return (
    <Grid container xs={12} sx={{ p: 2 }} columnSpacing={3}>

      {api.assetType !== "api" ?
        <Grid item xs={12}>
          <RepoIncidentsTable defaultApiId={api.id} disableGlobalFilter filters={{
            "category": categoryFilters
          }} />
        </Grid> :
        isEmpty(operationsByResource) ? <></>: <>
          <Grid item xs={12}>

            <Box sx={{ dipslay: "flex", justifyContent: "start" }}>
              <Typography sx={{ dipslay: "inline" }}> Open Issues</Typography>
              <Switch
                defaultChecked
                sx={{ dipslay: "inline" }}
                onChange={({ target }) => {
                  setOpenIncidentsOnly(target.checked);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <ResourceComponent
                name="Risk"
                resource={riskFilters}
                setFilters={(key: string, checked: boolean) => {
                  let newFilters: any = [...riskFilters];
                  for (let index in newFilters) {
                    if (newFilters[index]?.key === key) {
                      newFilters[index].selected = checked;
                    }
                  }
                  setRiskFilters(newFilters);
                }}
              />
              <ResourceComponent
                name="Category"
                resource={categoryFilters}
                setFilters={(key: string, checked: boolean) => {
                  let newFilters: any = [...categoryFilters];
                  for (let index in newFilters) {
                    if (newFilters[index]?.key === key) {
                      newFilters[index].selected = checked;
                    }
                  }
                  setCategoryFilters(newFilters);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>

            <Virtuoso
              style={{ minHeight: "75vh", height: "auto" }}
              totalCount={Object.keys(operationsByResource).length}
              itemContent={(index: any) => {
                return (
                  <ResourceSection
                    api={api}
                    environmentId={environmentId}
                    openIncidentsOnly={openIncidentsOnly}
                    filters={[
                      { name: "Risk", options: riskFilters },
                      { name: "Category", options: categoryFilters },
                    ]}
                    faultsSummaryByOperation={faultsSummaryByOperation}
                    operations={
                      operationsByResource?.[
                      Object.keys(operationsByResource)[index]
                      ]
                    }
                  />
                );
              }}
            />
          </Grid></>
      }
    </Grid>
  );
};

export default withRouter(ResultNewView);
