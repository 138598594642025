import {
  Alert, Box, Chip, ClickAwayListener, Grid, Grow,
  Paper,
  Popper, Typography
} from "@mui/material";
import { isEmpty, map, toInteger } from "lodash";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router-dom";
import { InformationCircleOutlined } from "../../../../icons/information-circle-outlined";
import { base64ToString } from "../../../../utils/base64ToString";
import ActionButton from "../shared/button";

const FaultInfoButton: React.FC<any> = ({ history, fault, findingDetail, incident }) => {
  const cveData = React.useMemo(() => {
    let data: any = {}
    if (!isEmpty(findingDetail?.details?.vulnerability?.cvss)) {
      let severity = findingDetail?.details?.vulnerability?.cvss?.severity
      data.cvss = {
        id: findingDetail?.details?.vulnerability?.cvss?.id,
        score: findingDetail?.details?.vulnerability?.cvss?.v3Score,
        color: severity === "critical"
          ? "critical"
          : severity === "high"
            ? "error"
            : severity === "medium"
              ? "warning"
              : severity === "low"?
               "info"
               : "information"
      }

    }
    if (!isEmpty(findingDetail?.details?.vulnerability?.epss)) {
      let percentile = toInteger(findingDetail?.details?.vulnerability?.epss?.percentile * 100)
      data.epss = {
        probability: (findingDetail?.details?.vulnerability?.epss?.probability * 100).toFixed(2),
        percentile: //Convert to integer
          percentile,
        percentileSuffix: percentile % 10 === 1 ? "st" : percentile % 10 === 2 ? "nd" : percentile % 10 === 3 ? "rd" : "th"
      }
    }
    return data
  }
    , [findingDetail])
  return (
    <>
      <Alert
        variant="standard"
        severity={"info"}
        icon={false}
        sx={{
          backgroundColor: "background.default",
          width: "100%",
          "&.MuiAlert-message": { width: "100%" },
          p: 0,
          my: 2,
        }}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={6} md={6}>
            <Typography
              color="text.secondary"
              variant="overline"
              fontSize={12}
              sx={{ pl: 1 }}
            >
              Risk
            </Typography>
            <br />
            <Box sx={{ pt: 1 }}>
              <ActionButton
                incidentId={incident?.id}
                buttons="RISK"
                state={incident?.state}
                risk={incident?.risk}
                expandedView={false}
                user={incident?.assignee}
                jira={{
                  url: incident?.jiraUrl,
                  number: incident?.jiraNumber,
                }}
              />
            </Box>
          </Grid>


          {!isEmpty(cveData) && <Grid item xs={6} >
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              {!isEmpty(cveData?.cvss) && <Box sx={{mr:1}}>
                <Typography color="text.secondary" variant="overline" fontSize={12}>
                  CVE
                </Typography>
                <Typography
                  color="text.primary"
                  variant="body1"
                  fontSize={14}
                  fontWeight="bold"
                >
                  {cveData?.cvss?.id}
                </Typography>

              </Box>
              }
              {!isEmpty(cveData?.cvss) && <Box>
                <Typography color="text.secondary" variant="overline" fontSize={12}>
                  CVSS (v3)
                </Typography>
                <Chip
                  label={cveData?.cvss?.score}
                  sx={{ width: "100%" }}
                  //@ts-ignore
                  color={
                    cveData?.cvss?.color
                  }
                />
              </Box>
              }
              {!isEmpty(cveData?.epss) && <Box sx={{ ml: 2 }}>
                <Typography color="text.secondary" variant="overline" fontSize={12}>
                  EPSS
                </Typography>
                <Typography
                  color="text.primary"
                  variant="body1"
                  fontSize={14}
                  fontWeight="bold"
                >
                  {cveData?.epss?.probability}% ({cveData?.epss?.percentile}{cveData?.epss?.percentileSuffix})
                </Typography>

              </Box>
              }
            </Box>
          </Grid>
          }
          <Grid item xs={12} sx={{pt:1}}>
            <Typography color="text.secondary" variant="overline" fontSize={12}>
              {fault?.id}
            </Typography>
            {fault?.title && <Typography color="text.primary" variant="h6" sx={{mt: 0,  pt: 0}}>
              {fault?.title} <FaultInfoIcon fault={fault} incident={incident} />
            </Typography>}
            {!isEmpty(fault?.groups) ? (
              <Box sx={{ pt: 1 }}>
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Fault Groups
                </Typography>
                <Typography
                  color="text.primary"
                  variant="body1"
                  fontSize={14}
                >
                  {map(fault?.groups, "name")?.map((group) => (
                    <Chip
                    size="small"
                      style={{
                        marginRight: "1em",
                        
                        background: "#D14343",
                        color: "white",
                      }}
                      label={group}
                    />
                  ))}
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Alert>
    </>
  );
};

const FaultInfoIcon: React.FC<any> = ({ fault, incident }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box className="hand" ref={anchorRef} sx={{ display: "inline-block" }}>
        <InformationCircleOutlined
          className="hand"
          fontSize="medium"
          onClick={handleToggle}
        />
      </Box>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ maxWidth: "60vw" }}
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin: placement === "bottom" ? "right top" : "right",
            }}
          >
            <Paper elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 1 }}>
                  <Grid item xs={12} sx={{ p: 2, pt: 0 }}>
                    <Typography variant="h6" sx={{ pt: 1 }}>
                      {fault?.id}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="overline"
                      fontSize={12}
                    >
                      Severity
                    </Typography>
                    <Box>
                      <Chip
                        size="small"
                        sx={{ fontSize: "12px", mt: 1 }}
                        label={fault?.severity}
                        variant="outlined"
                        color={
                          isEmpty(fault)
                            ? "default"
                            : fault?.severity?.toLowerCase() === "low"
                              ? "info"
                              : fault?.severity?.toLowerCase() === "medium"
                                ? "warning"
                                : "error"
                        }
                      />
                    </Box>
                    <>
                      <Box sx={{ pt: 1 }}>
                        <Typography
                          color="text.secondary"
                          variant="overline"
                          fontSize={12}
                        >
                          Summary
                        </Typography>
                        <Typography color="text.primary" variant="body1">
                          {base64ToString(fault?.summary)}
                        </Typography>
                      </Box>

                      <Box sx={{ pt: 1 }}>
                        <Typography
                          color="text.secondary"
                          variant="overline"
                          fontSize={12}
                        >
                          Information
                        </Typography>
                        <Typography
                          color="text.primary"
                          variant="body1"
                          sx={{ lineHeight: "1.25em" }}
                        >
                          <ReactMarkdown>
                            {base64ToString(fault?.information)}
                          </ReactMarkdown>
                        </Typography>
                      </Box>
                      {!isEmpty(fault?.groups) ? (
                        <Box sx={{ pt: 1 }}>
                          <Typography
                            color="text.secondary"
                            variant="overline"
                            fontSize={12}
                          >
                            Fault Groups
                          </Typography>
                          <Typography
                            color="text.primary"
                            variant="body1"
                            fontSize={14}
                          >
                            {map(fault?.groups, "name")?.map((group) => (
                              <Chip
                              size="small"
                                style={{
                                  marginRight: "1em",

                                  background: "#D14343",
                                  color: "white",
                                }}
                                label={group}
                              />
                            ))}
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  </Grid>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default withRouter(FaultInfoButton);
