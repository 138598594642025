import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Link,
  Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import MaterialReactTable from "material-react-table";
import { FC, UIEvent, useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { NEW_OPTIONS, STATUS_OPTIONS, TOOL_TYPES } from "../../../constants";
import { usePageContext } from "../../../hooks/process/usePageContext";
import { useTableInfinity } from "../../../hooks/process/useTableInfinity";
import { MinusOutlined } from "../../../icons/minus-outlined";
import ActionButton, {
  RISK_STATE_OPTIONS
} from "../ViewFindings/shared/button";
import { ColumnHeaderFilter } from "./IncidentsTable";


const LIMIT = 20;

const RepoIncidentsTable: FC<any> = ({
  history,
  match,
  filters,
  location,
  columnVisibility = {},
  runId = undefined,
  comparisonRunId = undefined,
  disableGlobalFilter = false,
  defaultProjectId = undefined,
  defaultGroupId = undefined,
  defaultApiId = undefined
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const categoryName = query.get("categoryName");

  const [columnHeaderFilter, setColumnHeaderFilter] = useState<any>({});


  const { groups, projects, changeGroup, changeProject, selectedGroup, selectedProject, groupsLoading, projectsLoading } = usePageContext();
  const incidentsBaseUrl = useMemo(() => {
    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return undefined
    }
    let url = `/incidents?limit={limit}&offset={offset}`;
    let groupId = disableGlobalFilter ? defaultGroupId : defaultGroupId || selectedGroup?.id
    let projectId = disableGlobalFilter ? defaultProjectId : defaultProjectId || selectedProject?.id
    let apiId = defaultApiId

    if (groupId) {
      url += `&groupId=${groupId}`;
    }
    if (projectId) {
      url += `&applicationId=${projectId}`;
    }
    if (apiId) {
      url += `&apiId=${apiId}`;
    }

    if (categoryName) {
      url += `&categoryName=${encodeURIComponent(categoryName)}`;
    }

    if (!isEmpty(columnHeaderFilter)) {
      for (let key in columnHeaderFilter) {
        const columnFilterValues = columnHeaderFilter[key];
        for (let value of columnFilterValues) {
          if (value !== null) {
            url += `&${key}=${value}`;
          }
        }
      }
    }




    return url;
  }
    , [selectedGroup, selectedProject, categoryName, groupsLoading, projectsLoading, projects, columnHeaderFilter, defaultApiId, defaultGroupId, defaultProjectId, disableGlobalFilter,])


  const incidentsUrl = useMemo(() => {
    let url = incidentsBaseUrl;
    if (incidentsBaseUrl && runId) {
      url += `&resultId=${runId}`;
    }

    return url
  }
    , [incidentsBaseUrl, runId])

  const comparisionIncidentsUrl = useMemo(() => {
    let url = incidentsBaseUrl;
    if (incidentsBaseUrl && comparisonRunId) {
      url += `&resultId=${comparisonRunId}`;
    }

    return url
  }
    , [incidentsBaseUrl, comparisonRunId])


  const { rows, fetchMoreOnBottomReached, isFetching } = useTableInfinity("incidents",
    incidentsUrl
    ,
    tableContainerRef,
    comparisonRunId ? 999 : LIMIT
  );

  const { rows: comparerRows, fetchMoreOnBottomReached: fetchMoreComparerRows, isFetching: isFetchingComparer } = useTableInfinity("incidents",
    comparisonRunId ? comparisionIncidentsUrl : undefined
    ,
    tableContainerRef,
    999
  );


  const dataToShow = useMemo(() => {
    if (!comparisonRunId) {
      return rows
    }
    const diffRows = []
    for (let row of rows) {
      const comparerRow = comparerRows.find((comparerRow) => comparerRow.id === row.id)
      if (!comparerRow) {
        diffRows.push({
          ...row,
          COMPARISION_TAG: "ADD",
        })
      }
    }
    for (let comparerRow of comparerRows) {
      const row = rows.find((row) => row.id === comparerRow.id)
      if (!row) {
        diffRows.push({
          ...comparerRow,
          COMPARISION_TAG: "REMOVE",
        })
      }
    }

    //order by index
    diffRows.sort((a, b) => b.index - a.index)

    return diffRows
  }
    , [comparerRows, rows, comparisonRunId])




  const handleColumnHeaderFilterChange = (key: string, value: any[] | null) => {
    if (!isEmpty(value)) {
      setColumnHeaderFilter({ ...columnHeaderFilter, [key]: value! });
    } else {
      let newFilter = { ...columnHeaderFilter };
      delete newFilter[key];
      setColumnHeaderFilter({ ...newFilter });
    }
  };

  useEffect(() => {


    fetchMoreOnBottomReached(tableContainerRef.current);

  }, [columnHeaderFilter, fetchMoreOnBottomReached])





  return (
    <Card elevation={0}>

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[
          {
            accessorKey: "findingType",
            header: "Type",
            Header: (
              <ColumnHeaderFilter
                columnKey="findingType"
                name="Type"
                options={TOOL_TYPES}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.findingType}
                size="small"
              />
            ),
            size: 100,

          },
          {
            accessorKey: "id",
            header: "ID",
            Cell: (params: any) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {params.row?.original?.COMPARISION_TAG === "ADD" && <AddOutlined
                  color="success"
                  fontSize="small"


                />}
                {params.row?.original?.COMPARISION_TAG === "REMOVE" && <MinusOutlined color="error"
                  fontSize="small"
                />}
                <Link
                  onClick={() => {
                    history.push(
                      `/findings?incidentId=${params.row?.original?.id}`
                    );
                  }}
                >
                  <Typography>
                    #{params.row?.original?.index}
                  </Typography>
                </Link>
              </Box>
            ),
            size: 100,
          },

          {
            accessorKey: "isNew",

            header: "New",
            Cell: (params: any) => (
              params.row?.original?.isNew ?
                <Chip
                  size="small"
                  variant="filled"
                  color="secondary"
                  label={"NEW"}
                /> : null
            ),
            Header: (
              <ColumnHeaderFilter
                columnKey="isNew"
                name="ALL"
                options={NEW_OPTIONS}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            size: 50,
          },
          {
            accessorKey: "operationId",
            header: "Operation",
            size: 100,




          },
          { accessorKey: "findingName", header: "Name", size: 250 },
          {
            accessorKey: "risk",
            header: "Risk",
            Cell: (params: any) => (
              <ActionButton
                incidentId={params?.row?.original?.id}
                buttons="RISK"
                risk={params?.row?.original?.risk}
                state={params?.row?.original?.state}
                user={params?.row?.original?.assigneeId}
                jira={{
                  url: params?.row?.original?.jiraUrl,
                  number: params?.row?.original?.jiraNumber,
                }}
              />
            ),
            Header: (
              <ColumnHeaderFilter
                columnKey="risk"
                name="Risk"
                options={RISK_STATE_OPTIONS}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            size: 130,
          },

          {
            accessorKey: "status",

            header: "Status",
            Header: (
              <ColumnHeaderFilter
                columnKey="status"
                name="Status"
                options={STATUS_OPTIONS}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },
          {
            accessorKey: "categoryName",

            header: "Category",
            Header: (
              <ColumnHeaderFilter
                columnKey="categoryName"
                name="Category"
                options={filters?.category}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },

          {
            accessorKey: "state",

            header: "State",
            Cell: (params: any) => (
              <ActionButton
                incidentId={params?.row?.original?.id}
                buttons="STATE"
                risk={params?.row?.original?.risk}
                state={params?.row?.original?.state}
                user={params?.row?.original?.assigneeId}
                jira={{
                  url: params?.row?.original?.jiraUrl,
                  number: params?.row?.original?.jiraNumber,
                }}
              />
            ),

            size: 300,
          }
        ]}
        data={dataToShow}
        initialState={{
          columnVisibility: {
            ...columnVisibility
          },
          density: "compact",
          columnPinning: { left: ['id'] },
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader
        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: isFetching || projectsLoading || groupsLoading,
        }}
        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: { maxHeight: "calc(100vh - 270px)" }, //give the table a max height
          onScroll: (
            event: UIEvent<HTMLDivElement> //add an event listener to the table container element
          ) => {


            fetchMoreOnBottomReached(event.target as HTMLDivElement)

          }

        }}
      />
    </Card>
  );
};



export default withRouter(RepoIncidentsTable);
