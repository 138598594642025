import { Add, ArrowLeft, Close, ContentCopy } from "@mui/icons-material";
import { Box, Chip, Container, Paper, Tooltip } from "@mui/material";
import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { API_SLUGS } from "../../constants";
import { usePageContext } from "../../hooks/process/usePageContext";
import { PaperClip } from "../../icons/paper-clip";

import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import AfterAPICreation from "../apiOnboarding/afterApiCreation";
import APIActions from "../apiOnboarding/apiActions";
import AddApiForm from "./AddApi/AddApiForm";
import AddRepositoryForm from "./AddRepository/AddRepositoryForm";

var format = require("string-template");

interface IApiModal {
  apiId: string;
  onClose: () => void;
}

const ApiModal: FC<IApiModal> = ({ apiId, onClose }) => {


  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth={"lg"}>
        <Paper elevation={12} sx={{ p: 3, px: 5 }}>
          <Box>
            <Close className="hand" onClick={onClose} />
          </Box>
          <ApiModalContent apiId={apiId} onClose={onClose} />
        </Paper>
      </Container>
    </Box >
  );
};

export const ApiModalContent: FC<any> = ({ apiId, callback = undefined, onClose = undefined }) => {
  const { selectedGroup } = usePageContext();
  const [api, setApi] = useState({} as any);
  const [uploadingNewRevision, setUploadingNewRevision] = useState(false);
  const [uploadGenerators, setUploadGenerators] = useState(false);
  const { setMessage } = useAlertState((state) => state) as IAlertState;

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    try {
      let apiResponse = await axiosInstance.get(
        format(API_SLUGS.GET_API, {
          //@ts-ignore
          uuid: apiId,
        })
      );
      setApi(apiResponse.data);
    } catch (error: any) { }
  };
  return <Box sx={{}}>
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: api?.assetType === "api" ? "space-between" : "start"
        ,
        padding: "auto",
        pt: 3,
        minHeight: "fit-content",
        maxHeight: "80vh",
        overflow: "scroll",
      }}
    >
      <Box >
        {!isEmpty(api) ? (
          api?.assetType === "api" ?
            <AddApiForm

              defaultFormValues={{
                application: {
                  name: api.applicationName,
                  uuid: api.applicationId,
                },
                api: api.name,
                type: api.type,
              }}
              groupId={selectedGroup?.id}
              updateOnly
              defaultAllowEdit={false}
              updateUUID={api.id}
            /> :
            <AddRepositoryForm

              defaultFormValues={{
                application: {
                  name: api.applicationName,
                  uuid: api.applicationId,
                },
                repository: api.name,

              }}
              groupId={selectedGroup?.id}
              updateOnly
              defaultAllowEdit={false}
              updateUUID={api.id}
            />
        ) : null}

        {!isEmpty(api) && <Tooltip title="Click to copy"><Box className="hand" onClick={() => {
          copy(api?.id);
          setMessage({
            title: "Asset ID is copied to the clipboard",
            type: "success",
          });
        }} >
          <pre><ContentCopy fontSize="inherit" />
            {api?.id} </pre>
        </Box>
        </Tooltip>}
      </Box>

      <Box sx={{ py: 3, mt: 3, display: "inline" }}>
        {!isEmpty(api) &&
          (!uploadingNewRevision ? (
            <>

              {!uploadGenerators && api?.assetType === "api" && (
                <Chip
                  variant="outlined"
                  size="small"
                  
                  icon={<Add />}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setUploadGenerators(true);
                  }}
                  label="Add Generators via Postman"
                ></Chip>
              )}
              {uploadGenerators && api?.assetType === "api" && (
                <Chip
                  variant="outlined"
                  size="small"
                  
                  icon={<ArrowLeft />}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setUploadGenerators(false);
                  }}
                  label="Back to Resources"
                ></Chip>
              )}

              {api?.assetType === "api" && <Chip
                
                variant="outlined"
                size="small"
                label="Upload New Definition"
                sx={{ mr: 1 }}
                onClick={() => setUploadingNewRevision(true)}
              />}
              <APIActions
                row={api}
                enabledActions={
                  api?.assetType === "api"
                    ? ["REVISIONS", "DEFINITION"]
                    : []}
              />
            </>
          ) : (
            <>
              {" "}
              <Chip
                color="error"
                variant="outlined"
                size="small"
                label="Cancel"
                sx={{ mr: 1 }}
                onClick={() => setUploadingNewRevision(false)}
              />
            </>
          ))}
      </Box>
    </Box>
    <br />

    {!isEmpty(api) && api?.assetType === "api" && (
      <AfterAPICreation
        apiId={api.id}
        uploadGenerators={uploadGenerators}
        callback={onClose || callback}
        uploadNewRevision={uploadingNewRevision}
        onRevisionUpload={() => setUploadingNewRevision(false)}
      />
    )}
  </Box>
}

export default ApiModal;
