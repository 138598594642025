import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid, TextField,
  Typography
} from "@mui/material";
import { filter, isEmpty } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, HELPER_TEXTS, STRINGS } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
import { IAppState, useAppState } from "../../../store/appState";
var format = require("string-template");



interface IError {
  error: boolean;
  message: string;
}

interface IAddApplicationFormError {
  application?: IError;
}

interface IAddApplicationFormValues {
  group: any;
  application: any;
}

interface IApplication {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean;
  defaultFormValues?: any;
  groupId?: string;
  defaultAllowEdit?: boolean;
  defaultAllowDelete?: boolean;
  preventRedirect?: boolean;
  callback?: (data: any) => void;
  updateUUID?: string;
}

const AddApplicationForm: FC<IAddForm> = ({
  history,
  match,
  updateOnly = false,
  defaultFormValues,
  groupId = undefined,
  defaultAllowEdit = false,
  defaultAllowDelete = false,
  preventRedirect = false,
  callback = undefined,
  updateUUID,
}) => {


  const clearFormValues = { group: null, application: "" };
  const { context } = useAppState((state) => state) as IAppState;
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const query = new URLSearchParams(history.location.search);
  const groupIdByQuery = useMemo(() => query.get("groupId") || groupId, [query, groupId]);

  const [formValues, setFormValues] = useState({
    ...clearFormValues,
    ...defaultFormValues,
  } as IAddApplicationFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    application: {}
  } as IAddApplicationFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);

  const [allowEdit, setAllowEdit] = useState(defaultAllowEdit);
  const [showDeleteForm, setShowDeleteForm] = useState(defaultAllowDelete);
  const [allowDelete, setAllowDelete] = useState(false);
  const [clickToCreateProject, setClickToCreateProject] = useState("");
  const [clickToCreateGroup, setClickToCreateGroup] = useState("");
  const { data: groupsData, fetchData: fetchGroups } = useFetch("/group");
  const groups = useMemo(() => {
    const availableGroups = groupsData?.groups || [];

    return availableGroups;
  }, [groupsData]);





  useEffect(() => {
    if (groupIdByQuery) {
      const group = groups.find((group: any) => group.id === groupIdByQuery);
      if (group) {
        setFormValues({ ...formValues, group })
      }
    }
  }, [groupIdByQuery, groups])

  const handleChange = async (
    value: any,
    valueType: "group" | "application"
  ) => {
    let newValues = { ...formValues, [valueType]: value };

    if (valueType === "group") {
      newValues.application = null
    }

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (updateOnly) {



      if (formValues.application && formValues.application.trim() !== "") {
        await updateApplication();
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }

    } else {



      if (formValues.application && formValues.application.trim() !== "") {
        await addApplication();
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }

    }
  };

  const handleDelete = async () => {

    await axiosInstance.delete(
      format(API_SLUGS.UPDATE_APPLICATION, { id: updateUUID })
    );
    if (!preventRedirect) {
      history.push(`/projects`);
    }
    setMessage({ title: "Application has been deleted", type: "success" });


    if (callback) {
      callback({});
    }
  };

  const addGroup = async (name: string, reroute: boolean = false) => {
    try {
      let groupResponse = await axiosInstance.post(API_SLUGS.ADD_GROUP, {
        name: name,
      });
      await fetchGroups();

      handleChange(groupResponse.data, "group");
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  const addApplication = async () => {
    setIsSubmitting(true);
    try {
      let applicationResponse = await axiosInstance.post(API_SLUGS.ADD_APPLICATION, {
        groupId: formValues.group?.id,
        name: formValues.application
      });
      if (!preventRedirect) {
        history.push(`/projects`);
      }
      setMessage({ title: "Application has been added", type: "success" });
      if (callback) {
        callback(applicationResponse?.data);
      }
      clearForm();
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
    setIsSubmitting(false);
  };


  const updateApplication = async () => {
    setIsSubmitting(true);
    try {
      let data: any = {
        name: formValues.application
      };

      await axiosInstance.patch(
        format(API_SLUGS.UPDATE_APPLICATION, { id: updateUUID }),
        data
      );
      window.location.reload();
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
    setIsSubmitting(false);
  };

  const clearForm = () => {
    setFormValues(clearFormValues);
  };



  return (
    <Box sx={{ textAlign: "left" }}>
      {!allowEdit &&
        updateOnly &&
        !showDeleteForm && <Box>
          <Typography variant="overline" color="neutral.400">

            <span className="hand" onClick={() => history.push(`/projects`)}>
              {formValues.application}
            </span>

          </Typography>
          <Typography variant="h4">
            {formValues.application}{" "}
            <ButtonGroup sx={{ ml: 2 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAllowEdit(true)}
              >
                Edit
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setShowDeleteForm(true)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Typography>
        </Box>
      }



      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Group
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Autocomplete
              sx={{ maxWidth: "50%", textAlign: "left" }}
              size="medium"
              disabled={updateOnly}
              getOptionLabel={(option): any => option?.name}
              options={groups}
              value={formValues.group}
              disableClearable

              onChange={(e, value) => {
                if (!isEmpty(value) && "id" in value) {
                  handleChange(value, "group");

                }
              }}
              clearOnBlur
              filterOptions={(options, params) => {
                const filtered = filter(options, function (item) {
                  return item.name.indexOf(params.inputValue) > -1;
                });
                if (params.inputValue !== "" && filtered.length === 0) {
                  setClickToCreateGroup(params.inputValue);
                } else {
                  setClickToCreateGroup("");
                }

                return filtered;
              }}
              renderInput={(params): JSX.Element => {
                return (
                  <TextField
                    autoComplete="off"
                    name="group"
                    helperText={HELPER_TEXTS?.add_application?.group}
                    variant="outlined"
                    {...params}
                  />
                );
              }}
            />
            {clickToCreateGroup?.trim() !== "" && (
              <Button
                size="small"
                sx={{
                  height: "fit-content",
                  ml: 1,
                  mt: 1.5,
                  mb: 1.5,
                  p: 1,
                }}
                onClick={async () => {
                  await addGroup(clickToCreateGroup);
                  setClickToCreateGroup("");
                }}
              >
                Create Group "{clickToCreateGroup}"
              </Button>
            )}
          </Box>



        </Grid>


        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Project
          </Typography>
          <TextField
            autoComplete="off"
            sx={{ maxWidth: "50%", textAlign: "left" }}
            size="medium"
            fullWidth
            name="Application"
            helperText={HELPER_TEXTS?.add_application?.application}
            defaultValue={formValues.application}
            onChange={(e: any) => {
              handleChange(e.target.value, "application");
            }}
            required
            variant="outlined"
          />
        </Grid>



      </Grid>

      {errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        {successMessage && successMessage.trim() ? null : (
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
        {updateOnly ? (
          <Button
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={() => {
              if (callback) {
                callback({});
              }
              setAllowEdit(false);
            }}
          >
            Cancel
          </Button>
        ) : null}
      </Box>


      {updateOnly &&
        showDeleteForm && <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              sx={{ mb: 1, textAlign: "left" }}
              variant="h6"
            >
              Enter application name to
              delete the application
            </Typography>

            <TextField
              autoComplete="off"
              sx={{ maxWidth: "50%", textAlign: "left" }}
              size="medium"
              fullWidth
              name="Application"

              placeholder={formValues.application}
              onChange={(e: any) => {
                setAllowDelete(e.target.value === formValues.application);
              }}
              required
              variant="outlined"
            />

          </Grid>
          <Grid item xs={12}>
            <Button
              color="error"
              size="medium"
              variant="contained"
              disabled={!allowDelete}
              onClick={handleDelete}
              sx={{ mr: 1 }}
            >
              Delete
            </Button>
            <Button
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => {
                if (callback) {
                  callback({});
                }
                setTimeout(() => {
                  setShowDeleteForm(false)
                }, 100);

              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

      }



    </Box>
  );
};

export default withRouter(AddApplicationForm);
