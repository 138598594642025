import {
  Alert,
  Button, CircularProgress, Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import usePermissionChecker from "../../hooks/process/usePermissionChecker";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import { PermissionTargets, PermissionTypes } from "../../store/userState";
import APISettings from "../analysis/ViewFindings/runComponents/ApiSettings";
import PermissionBlocker from "../common/permissionBlocker";
import ConfigTable from "../jobs/ListConfigs/ConfigTable";
import DefinitionForm from "./definitionForm";
import ReactMarkdown from "react-markdown";

interface IAfterAPICreation extends RouteComponentProps {
  apiId?: string;
  uploadNewRevision?: boolean;
  uploadGenerators?: boolean;
  onRevisionUpload?: () => void;
  callback?: () => void;
}
const AfterAPICreation = ({
  history,
  callback,
  apiId,
  uploadNewRevision = false,
  uploadGenerators = false,
  onRevisionUpload = undefined,
}: IAfterAPICreation) => {
  const [revisions, setRevisions] = useState<any>(null);
  const [isFetchingRevisions, setIsFetchingRevisions] = useState(false);
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [view, setView] = useState<
    "loading" | "definition" | "settings" | "config"
  >("loading");
  const { hasPermission: hasCreateConfigPermission } = usePermissionChecker(
    PermissionTargets.configuration,
    PermissionTypes.read
  );

  const fetchRevisions = useCallback(async () => {
    setIsFetchingRevisions(true);
    try {
      let revisionsResponse = await axiosInstance.get(
        `/apis/${apiId}/revisions`
      );
      setRevisions(revisionsResponse?.data?.revisions);
    } catch (error: any) { }
    setIsFetchingRevisions(false);
  }, [apiId]);

  useEffect(() => {
    if (apiId) {
      fetchRevisions();
    }
  }, [apiId]);

  useEffect(() => {
    if (isFetchingRevisions) {
      setView("loading");
    } else if (
      apiId &&
      ((isEmpty(revisions) && !isFetchingRevisions) || uploadNewRevision)
    ) {
      setView("definition");
    } else if (apiId && !isEmpty(revisions) && !uploadNewRevision) {
      if (onboardingComplete) {
        setView("config");
      } else {
        setView("settings");
      }
    }
  }, [
    isFetchingRevisions,
    apiId,
    revisions,
    uploadNewRevision,
    onboardingComplete,
  ]);

  return (
    <Box sx={{ textAlign: "left" }}>
      {view === "loading" && <CircularProgress disableShrink />}
      {view === "definition" && (
        <Box sx={{ pt: 2, mb: 2 }}>
          {uploadNewRevision && (
            <>
              <Typography variant="h5">Upload New Definition</Typography>
              <Typography variant="body1" sx={{ pb: 2 }}>
                Overrides configured for the API will be preserved.
              </Typography>
            </>
          )}
          <DefinitionForm
            apiId={apiId}
            callback={(data) => {
              setRevisions([{ id: data.revisionId }, ...revisions]);
              if (uploadNewRevision && onRevisionUpload) {
                onRevisionUpload();
                setMessage({
                  title: "API spec has been uploaded",
                  type: "success",
                });
              }
            }}
          />
        </Box>
      )}

      {view === "settings" && (
        <APISettings apiId={apiId} revId={revisions[0]?.id} uploadGenerators={uploadGenerators} />
      )}
      {view === "settings" && callback && !uploadGenerators && (
        <Button
          variant="contained"
          onClick={() => {

            callback();
            setOnboardingComplete(true);
          }}
          sx={{ mt: 2 }}
        >
          Looks Good
        </Button>
      )}
      {view === "config" && (
        <>
          <Alert color="success">
            <Typography variant="h6"> New API has been onboarded.</Typography>

          </Alert>
          <Box sx={{ pt: 2 }}>
            {hasCreateConfigPermission ? (
              <Box>
                <ReactMarkdown>
                  {`Kindly copy the run command specified in the configuration and execute it in the command line.

To manually execute the analysis, use the following command:
\n\`$ ./sift run --key <platform-key> --config-id <configuration-id>\`
                  `}
                </ReactMarkdown>      

                <ConfigTable apiId={apiId} />

                <Button variant="outlined" sx={{ mr: 2, mt: 2 }}
                onClick={() => {
                  history.push("/projects");
                }}
                >
                  Go to Projects</Button>

              </Box>
            ) : (
              <PermissionBlocker featureName="You can now create a configuration for it." />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AfterAPICreation);
